import styled from "styled-components"

export const LogoImage = styled.img`
  width: 280px;
  cursor: "pointer";

  @media (max-width: 480px) {
    width: 200px;
  }
`
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  /* margin-top: -20px; */
  margin-bottom: 10px;
`
export const HeaderContainer = styled.div`
  margin-left: 8%;
  margin-right: 8%;
  margin-bottom: -20px;
  @media (max-width: 1200px) {
    #header {
      margin-left: 10%;
      margin-right: 10%;
    }
  }

  @media (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (max-width: 480px) {
    margin-left: 0;
    margin-right: 0;
  }
`

export const ErrorPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
`

export const MessageContainer = styled.div`
  margin-top: 40px;
`

export const ErrorMessage = styled.h2`
  color: #ff4d4f;
  margin-bottom: 20px;
`

export const RedirectButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`

export const ResponseContainer = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: center;
  height: 100%;
  @media (max-width: 480px) {
    justify-content: space-between;
  }
`
