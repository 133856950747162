import { ArrowLeft } from "react-bootstrap-icons"
import { CloseButton } from "react-bootstrap"

export const ShippingHeader = ({
  isModified,
  setIsModified,
  isPayMode,
  handleCloseModal,
}) => {
  return (
    <div>
      <div style={{ position: "absolute", left: "15px", cursor: "pointer" }}>
        {isModified && (
          <ArrowLeft
            style={{
              width: "20px",
              height: "20px",
              strokeWidth: "10",
              textShadow: "5px 5px 0px black",
            }}
            onClick={() => setIsModified(false)}
          />
        )}
      </div>
      {isPayMode && (
        <CloseButton
          onClick={handleCloseModal}
          style={{ position: "absolute", right: "15px", cursor: "pointer" }}
        />
      )}
      <span
        style={{
          fontSize: "20px",
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          margin: "10px 0px",
        }}
      >
        {isModified ? "배송지 추가" : "배송 주소록"}
      </span>
    </div>
  )
}
