import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "../../../../firebase"
import imageCompression from "browser-image-compression"
import { client } from "../../../API/api"
import ImageUploader from "./ImageUploader"
import { CloseButton } from "react-bootstrap"

// styled-components 동일

const FormWrapper = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  min-height: 700px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h1`
  text-align: center;
  color: #333;
`

const ProductInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
`

const ProductImage = styled.img`
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  margin-right: 20px;
`

const ProductName = styled.p`
  color: #666;
`

const FormGroup = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
`

const Label = styled.label`
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-weight: bold;
`

const StarRating = styled.div`
  display: flex;
  justify-content: center;
`

const Star = styled.span`
  cursor: pointer;
  font-size: 2em;
  color: ${({ selected }) => (selected ? "#FFD700" : "#ccc")};
`

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`

const PreviewWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
`

const PreviewImage = styled.img`
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:first-of-type {
    background-color: #6c757d;
  }

  &:first-of-type:hover {
    background-color: #5a6268;
  }
`

export default function ReviewForm() {
  const [text, setText] = useState("")
  const [rating, setRating] = useState(0)
  const [files, setFiles] = useState([])
  const [filePreviews, setFilePreviews] = useState([])
  const userId = localStorage.getItem("userId")
  const navi = useNavigate()
  const location = useLocation()
  const isModify = location.pathname.includes("modify")
  const [photoUrl, setPhotoUrl] = useState("")
  const [name, setName] = useState("")
  const handleRating = (rate) => setRating(rate)
  const { productId, orderItemId, reviewId } = useParams()

  console.log("photoUrl", photoUrl, "name", name)
  useEffect(() => {
    const fetchedProduct = async () => {
      try {
        // 상품 정보 가져오기
        const response = await client.get(
          `/Product/getProductById?id=${productId}`
        )
        setPhotoUrl(response.data.photoUrl)
        setName(response.data.name)
      } catch (error) {
        console.error("Failed to fetch product data:", error)
      }
    }
    fetchedProduct()

    if (isModify) {
      const fetchedReview = async () => {
        try {
          // 수정할 리뷰 정보 가져오기
          const response = await client.get(
            `/Review/getReview?reviewId=${reviewId}`
          )
          console.log("test", response)
          setRating(response.data.rating)
          setText(response.data.content)
          setFilePreviews(response.data.photoList)
          setPhotoUrl(response.data.productPhotoUrl)
          setName(response.data.productName)
        } catch (error) {
          console.error("Failed to fetch review data:", error)
        }
      }
      fetchedReview()
    }
  }, [productId, reviewId])

  const handleSubmit = async (event) => {
    event.preventDefault()

    const deleteImage = async (fileUrl) => {}

    const uploadImage = async (file) => {
      if (!file) return ""

      const storageRef = ref(storage, `reviews/${file.name}`)
      try {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
          fileType: "image/webp",
        }
        const compressedFile = await imageCompression(file, options)
        const newFileName =
          file.name.split(".").slice(0, -1).join(".") + ".webp"
        const webpFile = new File([compressedFile], newFileName, {
          type: "image/webp",
        })

        await uploadBytes(storageRef, webpFile)
        const url = await getDownloadURL(storageRef)
        return url
      } catch (error) {
        console.error("Image upload failed:", error)
        throw error
      }
    }

    // 기존 이미지와 새로 업로드된 이미지 비교
    const existingImages = filePreviews // 기존에 있는 이미지 URL 목록
    const newFiles = files.filter((file) => typeof file !== "string") // 새로 추가된 파일(blob)
    const retainedImages = files.filter((file) => typeof file === "string") // 그대로 유지할 URL

    // 기존 이미지 중에서 삭제할 이미지 찾아서 삭제
    const imagesToDelete = existingImages.filter(
      (existingUrl) => !retainedImages.includes(existingUrl)
    )

    await Promise.all(
      imagesToDelete.map((fileUrl) => deleteImage(fileUrl))
    ).catch((error) => console.error("Failed to delete images:", error))

    // 새 이미지 업로드
    const uploadedImageUrls = await Promise.all(
      newFiles.map((file) => uploadImage(file))
    ).catch((error) => {
      console.error("Failed to upload images:", error)
      alert("이미지 업로드에 실패했습니다.")
      return []
    })

    // 기존 유지할 이미지와 새로 업로드된 이미지 합치기
    const updatedPhotoList = [...retainedImages, ...uploadedImageUrls]

    // 서버로 전송할 데이터
    const postData = {
      userId,
      orderItemId,
      rating,
      title: "string",
      text,
      photoList: updatedPhotoList, // 업데이트된 이미지 목록
    }
    console.log(postData)
    if (isModify) {
      try {
        await client.put(`/Review/updateReview?id=${reviewId}`, postData)
        alert("리뷰가 성공적으로 수정되었습니다.")
        navi(-1)
      } catch (error) {
        console.error("리뷰 수정에 실패했습니다.")
        alert(error.response.data.message)
      }
    } else {
      try {
        await client.post("/Review/createReview", postData)
        alert("리뷰가 성공적으로 추가되었습니다!")
        navi(-1)
      } catch (error) {
        alert(`Error: ${error.response.data.message}`)
        console.error("리뷰 추가에 실패했습니다.", error.response.data.message)
      }
    }
  }

  const handleBack = () => {
    if (window.confirm("리뷰 작성을 취소하시겠습니까?")) {
      navi(-1)
    }
  }

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Title>리뷰쓰기</Title>
      <ProductInfo>
        <ProductImage src={photoUrl} alt={name} />
        <ProductName>{name}</ProductName>
      </ProductInfo>
      <FormGroup>
        <Label>상품은 만족하셨나요?</Label>
        <StarRating>
          {[1, 2, 3, 4, 5].map((star) => (
            <Star
              key={star}
              onClick={() => handleRating(star)}
              selected={rating >= star}
            >
              ★
            </Star>
          ))}
        </StarRating>
        <div
          style={{ display: "flex", justifyContent: "center", color: "gray" }}
        >
          선택하세요
        </div>
      </FormGroup>
      <FormGroup>
        <Label>어떤 점이 좋았나요?</Label>
        <TextArea
          placeholder="최소 10자 이상 입력해주세요."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <ImageUploader
          files={files}
          setFiles={setFiles}
          setFilePreviews={setFilePreviews}
          size={filePreviews.length}
        />
        <PreviewWrapper>
          {filePreviews.map((preview, index) => (
            <div>
              <PreviewImage
                key={index}
                src={preview}
                alt={`preview ${index}`}
              />
              <div
                style={{
                  verticalAlign: "top",
                  marginLeft: "-24px",
                  display: "inline-block",
                  backgroundColor: "black",
                  opacity: 0.8,
                  width: "24px",
                  height: "24px",
                }}
              >
                <CloseButton variant="white" size="sm" style={{ opacity: 1 }} />
              </div>
            </div>
          ))}
        </PreviewWrapper>
      </FormGroup>
      <ButtonGroup>
        <Button type="button" onClick={() => handleBack()}>
          취소
        </Button>
        <Button type="submit">등록</Button>
      </ButtonGroup>
    </FormWrapper>
  )
}
