import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
  Button,
  ButtonGroup,
  Container,
  Form,
  Pagination,
} from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/locale"
import { Input } from "reactstrap"
import MyReviewCard from "./MyReviewCard"
import { client } from "../../../API/api"
import { format, parseISO } from "date-fns"

const OrderInquiryWrapper = styled(Container)`
  margin-top: 20px;
  min-height: 100vh;
`

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: center;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
`

const OrdersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const formatDate = (dateString) => {
  const date = parseISO(dateString)
  return format(date, "yyyy-MM-dd")
}
const TitleFont = styled.h2`
  font-weight: 700;
`
const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`

const MyReview = ({ isAdmin }) => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 3)) // 3개월 전으로 설정
  )
  const [endDate, setEndDate] = useState(new Date())
  const [data, setData] = useState([])
  const [filteredReview, setFilteredReview] = useState([])
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  // const userId = useRecoilValue(UId);
  const userId = localStorage.getItem("userId")
  const [selectedRange, setSelectedRange] = useState(3) // 3개월이 기본값
  const [searchText, setSearchText] = useState("")
  useEffect(() => {
    const fetchDataAsync = async () => {
      if (isAdmin === true) {
        try {
          const response = await client.get("/Review/getAllReview")
          setData(response.data)
          setTotalPages(response.data.totalPages || 1) // response.data.totalPages를 사용해 총 페이지 수 설정
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          const response = await client.get(
            `/Review/getAllReviewByUserId?page=${page}&size=10&userId=${userId}`
          )
          setData(response.data.list)
          setTotalPages(response.data.totalPages || 1) // response.data.totalPages 사용
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchDataAsync()
  }, [page, userId])
  useEffect(() => filterReview(), [data, startDate, endDate])

  const filterReview = () => {
    if (data) {
      const trimmedSearchText = searchText.trim().toLowerCase() // searchText의 공백을 제거하고 소문자로 변환

      const filtered = data.filter((review) => {
        const reviewDate = new Date(review.date)
        const text = review.productName.toLowerCase()

        // 날짜 필터 조건
        const isWithinDateRange =
          reviewDate >= startDate && reviewDate <= endDate

        // 검색 텍스트 조건
        const matchesSearchText =
          trimmedSearchText === "" || text.includes(trimmedSearchText)

        // 두 조건을 모두 만족하는 리뷰만 반환
        return isWithinDateRange && matchesSearchText
      })

      setFilteredReview(filtered)
    } else {
      setFilteredReview([])
    }
  }

  const handleDateRangeClick = (months) => {
    setSelectedRange(months) // 버튼 선택 상태 설정
    setEndDate(new Date())
    setStartDate(new Date(new Date().setMonth(new Date().getMonth() - months)))
  }

  const handlePageChange = (pageNumber) => setPage(pageNumber - 1)
  console.log(searchText)
  console.log(filteredReview)
  return (
    <OrderInquiryWrapper>
      <TitleFont>리뷰</TitleFont>
      <FilterWrapper>
        <Form>
          <ButtonRow>
            <ButtonGroup>
              <Button
                variant={selectedRange === 3 ? "primary" : "outline-primary"}
                onClick={() => handleDateRangeClick(3)}
              >
                3개월
              </Button>
              <Button
                variant={selectedRange === 6 ? "primary" : "outline-primary"}
                onClick={() => handleDateRangeClick(6)}
              >
                6개월
              </Button>
              <Button
                variant={selectedRange === 12 ? "primary" : "outline-primary"}
                onClick={() => handleDateRangeClick(12)}
              >
                1년
              </Button>
            </ButtonGroup>
            <DateWrapper>
              <DatePicker
                locale={ko}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy년 MM월"
                showMonthYearPicker
                className="form-control"
              />

              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                {"~"}
              </div>
              <DatePicker
                locale={ko}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy년 MM월"
                showMonthYearPicker
                className="form-control"
              />
            </DateWrapper>
            <InputWrapper>
              <Input
                placeholder="리뷰 정보를 입력하세요"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputWrapper>
            <Button className="w-100" onClick={filterReview}>
              검색
            </Button>
          </ButtonRow>
        </Form>
      </FilterWrapper>
      <OrdersGrid>
        {filteredReview.length > 0 ? (
          filteredReview.map((review) => (
            <MyReviewCard
              isAdmin={isAdmin}
              key={review.id}
              review={{ ...review, date: formatDate(review.date) }}
            />
          ))
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            후기가 없습니다.
          </div>
        )}
      </OrdersGrid>
      {filteredReview.length > 0 && (
        <PaginationWrapper>
          <Pagination>
            <Pagination.Prev
              onClick={() => setPage(page > 0 ? page - 1 : 0)}
              disabled={page === 0} // 첫 페이지에서는 비활성화
            />
            {[...Array(totalPages)].map((_, idx) => (
              <Pagination.Item
                key={idx}
                active={idx === page}
                onClick={() => handlePageChange(idx + 1)}
              >
                {idx + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => setPage(page < totalPages - 1 ? page + 1 : page)}
              disabled={page === totalPages - 1} // 마지막 페이지에서는 비활성화
            />
          </Pagination>
        </PaginationWrapper>
      )}
    </OrderInquiryWrapper>
  )
}

export default MyReview
