import React from "react"
import {
  CloseButton,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
  Row,
} from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { ThreeDotsVertical } from "react-bootstrap-icons"
import axios from "axios"
import logo from "./Img/원정푸드.jpg"
import { useRecoilState, useRecoilValue } from "recoil"
import { login, serverUrl } from "../../App"

const StyledName = styled.h5`
  font-size: 24px;
`

const StyledEmail = styled.p`
  font-size: 16px;
`

const StyledImage = styled(Image)`
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50%;
  align-content: center;
`

// Styled NavDropdown to hide the dropdown arrow
const StyledNavDropdown = styled(NavDropdown)`
  .dropdown-toggle::after {
    display: none; /* Hides the dropdown caret */
  }
`

export default function HeaderSideMenu({
  selectCate,
  setSelectCate,
  categoryMenu,
  naviLinks,
  showOffcanvas,
  setShowOffcanvas,
}) {
  const navi = useNavigate()
  const [isLogin, setIsLogin] = useRecoilState(login)
  // const nickname = useRecoilValue(UNickName);
  // const profileUrl = useRecoilValue(UProfileUrl);
  const nickname = localStorage.getItem("nickname")
  const profileUrl = localStorage.getItem("profileUrl")
  const baseUrl = useRecoilValue(serverUrl)
  const refreshToken = localStorage.getItem("refreshToken")
  const handleNavClick = (link, categoryName) => {
    navi(link)
    setSelectCate(categoryName)
    setShowOffcanvas(false) // Close the Offcanvas menu
  }
  const handleLogout = async () => {
    try {
      await axios.post(`${baseUrl}/api/v1/auth/logout`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      setIsLogin(false)
      localStorage.clear()
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Navbar key={false} expand={false} className="navbar-custom">
      <Container fluid>
        <Navbar.Toggle
          aria-controls="offcanvas-navbar"
          onClick={() => setShowOffcanvas(true)} // Open the Offcanvas menu
        />
        <Navbar.Offcanvas
          style={{ width: "350px" }}
          id="offcanvas-navbar"
          placement="start"
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)} // Close the Offcanvas menu
        >
          <Container
            style={{
              marginBottom: "10px",
              marginLeft: "0px",
              marginRight: "-20px",
              position: "relative", // Make the container position relative
            }}
          >
            <CloseButton
              style={{ position: "absolute", right: "-35px", top: "0px" }}
              onClick={() => setShowOffcanvas(false)} // Close the Offcanvas menu
            />
            <Row>
              <Col>
                {isLogin ? (
                  <>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <StyledImage src={profileUrl} alt="profile" />
                      <div style={{ display: "flex", marginLeft: "auto" }}>
                        <StyledNavDropdown
                          title={
                            <ThreeDotsVertical style={{ fontSize: "24px" }} />
                          }
                          id="basic-nav-dropdown"
                          align="end" // Align the dropdown to the right
                        >
                          <StyledNavDropdown.Item
                            onClick={() =>
                              handleNavClick("/MyPage/Orders", "주문조회")
                            }
                          >
                            주문조회
                          </StyledNavDropdown.Item>
                          <StyledNavDropdown.Item
                            onClick={() =>
                              handleNavClick("/MyPage/Cart", "장바구니")
                            }
                          >
                            장바구니
                          </StyledNavDropdown.Item>
                          <StyledNavDropdown.Item
                            onClick={() =>
                              handleNavClick("/MyPage/Reviews", "리뷰 관리")
                            }
                          >
                            리뷰 관리
                          </StyledNavDropdown.Item>
                          <StyledNavDropdown.Item
                            onClick={() =>
                              handleNavClick("/MyPage/Qna", "문의내역")
                            }
                          >
                            문의내역
                          </StyledNavDropdown.Item>
                          <StyledNavDropdown.Item
                            onClick={() =>
                              handleNavClick("/MyPage/Userinfo", "회원정보")
                            }
                          >
                            회원정보
                          </StyledNavDropdown.Item>
                          <StyledNavDropdown.Item
                            onClick={() =>
                              // 임시
                              handleLogout()
                            }
                          >
                            로그아웃
                          </StyledNavDropdown.Item>
                        </StyledNavDropdown>
                      </div>
                    </div>
                    <StyledName style={{ alignContent: "center" }}>
                      {nickname}
                    </StyledName>
                  </>
                ) : (
                  <>
                    <div
                      style={{ display: "flex", cursor: "pointer" }}
                      onClick={() => handleNavClick("/Login", "로그인")}
                    >
                      <div
                        style={{
                          display: "flex",
                          color: "gray",
                          marginLeft: "10px",
                          marginTop: "10px",
                        }}
                      >
                        로그인이 필요합니다.
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          marginLeft: "auto",
                          marginRight: "10px",
                        }}
                      >
                        {" "}
                        로그인
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Container>

          <Offcanvas.Body>
            <Nav className="flex-column" activeKey={selectCate}>
              {categoryMenu.map((item) => (
                <Nav.Link
                  key={item.label}
                  eventKey={item.categoryName}
                  onClick={() =>
                    handleNavClick(`/Category/${item.id}`, item.categoryName)
                  }
                  style={{
                    color:
                      selectCate === item.categoryName
                        ? item.fontColor
                        : "inherit",
                    backgroundColor:
                      selectCate === item.categoryName
                        ? "#f0f0f0"
                        : "transparent",
                  }}
                >
                  {item.categoryName}
                </Nav.Link>
              ))}
              {naviLinks.map((item, idx) =>
                item.label === "Company" ? (
                  <Nav.Item key={idx}>
                    <NavDropdown
                      title="회사 소개"
                      className="nav-link-company"
                      key={item.label}
                      eventkey={item.label}
                      style={{
                        // paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <NavDropdown.Item
                        onClick={() =>
                          handleNavClick("/Company/Intro", "회사 소개")
                        }
                      >
                        회사 소개
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() =>
                          handleNavClick("/Company/Cert", "회사 소개")
                        }
                      >
                        인증 목록
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        onClick={() =>
                          handleNavClick("/Company/Quality", "회사 소개")
                        }
                      >
                        품질 관리
                      </NavDropdown.Item>
                    </NavDropdown>
                  </Nav.Item>
                ) : (
                  <Nav.Link
                    key={item.label}
                    eventKey={item.text}
                    onClick={() => handleNavClick(item.link, item.text)}
                  >
                    {item.text}
                  </Nav.Link>
                )
              )}
            </Nav>
          </Offcanvas.Body>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={logo} alt="원정푸드" style={{ width: "70%" }} />
          </div>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}
