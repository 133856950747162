import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import ChatBoxForm from "./ChatBoxForm"
import { client } from "../../API/api"
import QnaListForm from "./QnaListForm"

const StyledContainer = styled(Container)`
  /* margin-top: 20px; */
  height: 80vh; /* Set the height of the container */
`

const FullHeightRow = styled(Row)`
  height: 100%;
`

const BackButton = styled(Button)`
  @media (min-width: 480px) {
    display: ${(props) =>
      props.show ? "block" : "none"}; /* Show based on state */
  }
`

const MobileOnlyContainer = styled.div`
  height: 770px;
  @media (max-width: 480px) {
    display: ${(props) =>
      props.show ? "block" : "none"}; /* Show based on state */
  }

  @media (min-width: 481px) {
    display: block; /* Always show on larger screens */
  }
`
const CustomCol = styled(Col)`
  @media (max-width: 480px) {
    display: ${(props) =>
      props.show ? "block" : "none"}; /* Show based on state */
  }
`

function QnaForm({ isAdmin }) {
  const [selectedQnaId, setSelectedQnaId] = useState(null)
  const [qnaData, setQnaData] = useState()
  const [showChatBox, setShowChatBox] = useState(false) // State for mobile view
  // const userId = useRecoilValue(UId);
  const userId = localStorage.getItem("userId")

  useEffect(() => {
    if (isAdmin === false || isAdmin === undefined) {
      const fetchQnaData = async () => {
        try {
          const response = await client.get(
            `/Qna/getAllQnaByUserId?page=0&size=10&userId=${userId}`
          )
          setQnaData(response.data)
        } catch (error) {
          console.error(error)
        }
      }
      fetchQnaData()
    } else if (isAdmin === true) {
      const fetchQnaData = async () => {
        try {
          const response = await client.get(`/Qna/getAllQna?page=0&size=10`)
          setQnaData(response.data)
        } catch (error) {
          console.error(error)
        }
      }
      fetchQnaData()
    }
  }, [userId])

  const handleQnaSelect = (qnaId) => {
    setSelectedQnaId(qnaId)
    setShowChatBox(true) // Show ChatBox when Qna is selected on mobile
  }

  const handleBackButtonClick = () => {
    setShowChatBox(false) // Go back to MyQnaList on mobile
  }

  return (
    <StyledContainer>
      <BackButton
        onClick={handleBackButtonClick}
        className="mb-3"
        style={{ display: showChatBox ? "" : "none" }}
      >
        뒤로가기
      </BackButton>
      <FullHeightRow>
        <CustomCol md={5} show={!showChatBox}>
          <MobileOnlyContainer show={!showChatBox}>
            <QnaListForm
              qnaData={qnaData}
              setSelectedQnaId={handleQnaSelect}
              isAdmin={isAdmin}
            />
          </MobileOnlyContainer>
        </CustomCol>
        <CustomCol md={7} show={showChatBox}>
          <MobileOnlyContainer show={showChatBox}>
            <ChatBoxForm selectedQnaId={selectedQnaId} isAdmin={isAdmin} />
          </MobileOnlyContainer>
        </CustomCol>
      </FullHeightRow>
    </StyledContainer>
  )
}

export default QnaForm
