import React, { useEffect, useState } from "react"
import { Table } from "react-bootstrap"
import * as S from "../DashBoard/Admin.style"

export default function DataTable({ data }) {
  const [keys, setKeys] = useState([])
  //데이터의 키 값들을 전부 받아오기
  useEffect(() => {
    if (data.length > 0) {
      setKeys(Object.keys(data[0]))
    }
  }, [data])
  return (
    <S.CardCustom>
      <Table striped responsive>
        <thead>
          <tr>
            {/* 데이터 테이블의 제목 부분에 keys의 값들을 설정 */}
            {keys.map((key, index) => (
              <th key={index}>{key}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((entry, index) => (
            <tr key={index}>
              {/* keys의 값들을 설정 */}
              {keys.map((key, index) => (
                <td key={index}>{entry[key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </S.CardCustom>
  )
}
