import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Card, Col, Container, Row } from "react-bootstrap"
import { client } from "../../API/api"
import StarRating from "./StarRating"
import { useNavigate } from "react-router-dom"

// Styled Components
const RecentlyViewedContainer = styled(Container)`
  margin-top: 20px;
`

const ProductCard = styled(Card)`
  width: 172px;
  margin: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`

const ProductImage = styled(Card.Img)`
  height: 170px;
  object-fit: cover;
`

const ProductName = styled(Card.Title)`
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  overflow: hidden;
  min-height: 40px; /* 제목 영역 높이를 고정하여 카드 높이를 통일 */
`

const ProductPrice = styled(Card.Text)`
  font-size: 14px;
  color: #555;
  text-align: center;
  margin-bottom: 10px;
`

const RatingReviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* 평점과 리뷰 수 사이의 간격 */
`

const RelativeProducts = ({ id }) => {
  const [relative, setRelative] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(`/Product/randomProduct?id=${id}`)
        setRelative(response.data)
        console.log("relative", response.data)
      } catch (error) {
        console.log(error.response.data.message)
      }
    }
    fetchData()
  }, [id])

  return (
    <RecentlyViewedContainer>
      <h5>연관상품</h5>
      <Row>
        {relative.map((product) => (
          <Col key={product.id}>
            <ProductCard onClick={() => navigate(`/item/${product.id}`)}>
              <ProductImage
                variant="top"
                src={product.photoUrl}
                alt={product.name}
              />
              <Card.Body>
                <ProductName>{product.name}</ProductName>
                {/* price에 , 추가 */}
                <ProductPrice>
                  {parseInt(product.price).toLocaleString()}원
                </ProductPrice>
                <RatingReviewContainer>
                  <StarRating rating={parseFloat(product.rating)} />
                  <span>({product.reviewCount})</span>
                </RatingReviewContainer>
              </Card.Body>
            </ProductCard>
          </Col>
        ))}
      </Row>
    </RecentlyViewedContainer>
  )
}

export default RelativeProducts
