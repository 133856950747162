import React from "react"
import { Button } from "react-bootstrap"

const SubmitButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    style={{
      display: "relative",
      float: "right",
      marginTop: "20px",
      marginBottom: "10px",
    }}
  >
    상품 추가
  </Button>
)

export default SubmitButton
