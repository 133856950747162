import React, { useEffect, useState } from "react"
import { client } from "../../../API/api"

export default function DetailInfo({ item }) {
  const [detailImg, setDetailImg] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const response = await client.get(
        `/Product/findDetailUrlByProductId?productId=${item.id}`
      )
      setDetailImg(response.data)
    }
    fetchData()
  }, [item])
  console.log(detailImg)
  return (
    <div>
      {detailImg.map((item, index) => (
        <div>
          <img width="60%" src={item.url} alt="Detail" />
        </div>
      ))}
    </div>
  )
}
