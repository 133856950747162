import styled from "styled-components"
import {
  Nav,
  NavDropdown,
  Button,
  Form,
  Navbar,
  Offcanvas,
  Container,
  InputGroup,
  Modal,
} from "react-bootstrap"

export const CategoryContainer = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 480px) {
    margin-left: 0;
  }
`

export const navLink = styled(Nav.Link)`
  font-size: 16px;
`

export const NavDropdownComponent = styled(NavDropdown)`
  font-size: 15px;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 992px) {
    font-size: 13px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 576px) {
    font-size: 11px;
  }
  @media (max-width: 480px) {
    font-size: 10px;
  }
  @media (max-width: 320px) {
    font-size: 10px;
  }
`
export const SearchBtn = styled(Button)`
  display: none;
  background: none;
  border: none;
  display: hidden;
  img {
    width: 20px;
    height: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 576px) {
    display: none;
  }

  @media (max-width: 480px) {
    display: inline-block;
  }
`

export const navBarCustom = styled.div`
  align-items: center;
  .nav-link {
    font-size: 20px;
  }
  .nav-dropdown {
    font-size: 15px;
  }
  .nav-dropdown {
    font-size: 14px;
  }
  @media (max-width: 992px) {
    .nav-dropdown {
      font-size: 13px;
    }
    @media (max-width: 768px) {
      .nav-dropdown {
        font-size: 12px;
      }
    }
    @media (max-width: 576px) {
      .nav-dropdown {
        font-size: 11px;
      }
      .navbar-toggler {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        line-height: 1;
      }

      @media (max-width: 480px) {
        .nav-dropdown {
          font-size: 15px;
        }
        width: auto;
      }
    }
  }
`

export const DesktopInputGroup = styled(InputGroup)`
  width: 15%;
  background: none;
  border: none;
  .search-modal {
    display: none;
  }

  @media (max-width: 768px) {
    display: none;
  }

  @media (max-width: 576px) {
    display: none;
  }
  @media (max-width: 480px) {
    display: none;
  }
`
