import "./CSS/NaverLogin.css"

const NaverLogin = ({ handleOAuthLogin }) => (
  <>
    <div className="naverLoginContainer">
      <button
        onClick={() => handleOAuthLogin("naver")}
        className="naverLoginBtn"
      >
        <div alt="navericon" className="naverIcon" />
        <span className="naverLoginTitle">네이버 로그인</span>
      </button>
    </div>
  </>
)
export default NaverLogin
