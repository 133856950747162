// firebase.js
import { initializeApp } from "firebase/app"
import { getStorage } from "firebase/storage"

// Firebase 구성
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
}

// Firebase 앱 초기화
export const app = initializeApp(firebaseConfig)

// Firebase Storage 인스턴스 가져오기
export const storage = getStorage(app)
