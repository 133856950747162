import { Button, Card, Form, InputGroup, Table } from "react-bootstrap"
import { Plus, Trash3Fill } from "react-bootstrap-icons"
import React, { useState } from "react"
import { client } from "../../../../API/api"
import ModalComponent from "../../../../Common/ModalComponent"

export const GroupCard = ({ groups, setSelected, selected, handleUpdate }) => {
  const [newGroup, setNewGroup] = useState("")
  const [show, setShow] = useState(false)
  const [groupToDelete, setGroupToDelete] = useState(null) // 삭제할 그룹을 저장

  const handleSubmit = async (e) => {
    try {
      await client.post("ProductGroup/createGroup", { name: newGroup })
      handleUpdate()
    } catch (error) {
      alert(error.response.data.message)
    }
  }

  const confirmDelete = async () => {
    try {
      if (groupToDelete) {
        await client.delete(
          `ProductGroup/deleteGroup?groupId=${groupToDelete.groupId}`
        )
        handleUpdate()
        setSelected("")
      }
    } catch (error) {
      alert(error.response.data.message)
    } finally {
      setShow(false)
      setGroupToDelete(null) // 삭제 후 초기화
    }
  }

  const handleDelete = (event, group) => {
    event.stopPropagation() // 이벤트 전파 막기
    setGroupToDelete(group) // 삭제할 그룹 설정
    setShow(true) // 모달 표시
  }

  return (
    <>
      <Card
        className="col-md-4"
        style={{ marginRight: "10px", paddingTop: "10px" }}
      >
        <h2 className="text-xl font-semibold mb-2">그룹</h2>
        <div style={{ height: "500px", overflow: "auto" }}>
          <Table bordered hover>
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
              }}
            >
              <tr>
                <th>그룹명</th>
                <th>작업</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr
                  key={group.groupId}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      selected === group.groupId ? "#e9ecef" : "", // 선택된 그룹에 대한 배경색
                  }}
                  onClick={() => setSelected(group)}
                >
                  <td>{group.groupName}</td>
                  <td>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      onClick={(e) => handleDelete(e, group)} // 이벤트 전파 막기
                    >
                      <Trash3Fill className="h-4 w-4" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <InputGroup className="mb-3">
          <Form.Control
            placeholder="새 그룹명"
            value={newGroup}
            onChange={(e) => setNewGroup(e.target.value)}
          />
          <Button variant="primary" onClick={handleSubmit}>
            <Plus className="h-4 w-4 mr-2" />
            그룹 추가
          </Button>
        </InputGroup>
      </Card>
      <ModalComponent
        show={show}
        title={"삭제"}
        body={
          <>
            {groupToDelete
              ? `${groupToDelete.groupName} 그룹을 정말 삭제하시겠습니까?`
              : "삭제할 그룹이 선택되지 않았습니다."}
          </>
        }
        handleClose={() => setShow(false)}
        onConfirm={confirmDelete} // 삭제 확정 시 호출될 함수
      />
    </>
  )
}
