import kakaologin from "../img/kakao_login_button.png"

const KakaoLogin = ({ handleOAuthLogin }) => (
  <>
    <img
      src={kakaologin}
      alt="kakao"
      type="button"
      onClick={() => handleOAuthLogin("kakao")}
      style={{ width: "268px" }}
    />
  </>
)

export default KakaoLogin
