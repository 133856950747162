import React from "react"

export const DashboardBodyContainer = ({ title, children }) => {
  return (
    <>
      <div style={{ borderBottom: "2px solid #cbc8c1", marginTop: "20px" }}>
        {title}
      </div>
      <div>{children}</div>
    </>
  )
}

export const DashboardItem = ({ text }) => {
  return (
    <>
      <li>{text}</li>
    </>
  )
}
