import React from "react"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { Input } from "reactstrap"

const ProductForm = ({
  productName,
  setProductName,
  price,
  setPrice,
  categoryName,
  setCategoryName,
  categoryId,
  setCategoryId,
  categoryList,
}) => (
  <div>
    <p style={{ marginTop: "20px", marginBottom: "10px" }}>제품 이름</p>
    <Input
      value={productName}
      onChange={(e) => setProductName(e.target.value)}
    />
    <p style={{ marginTop: "20px", marginBottom: "10px" }}>가격</p>
    <Input
      type="number"
      value={price}
      onChange={(e) => setPrice(e.target.value)}
    />
    <p style={{ marginTop: "20px", marginBottom: "10px" }}>카테고리</p>
    <DropdownButton
      variant="outline-secondary"
      id="dropdown-basic-button"
      title={categoryName}
    >
      {categoryList.map((item) => (
        <Dropdown.Item
          onClick={() => {
            setCategoryName(item.categoryName)
            setCategoryId(item.id)
          }}
          key={item.id}
        >
          {item.categoryName}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </div>
)

export default ProductForm
