import styled from "styled-components"
import { Card } from "react-bootstrap"

export const CardCustom = styled(Card)`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

export const Container = styled.div`
  padding: 20px;
`

export const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`
