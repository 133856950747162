import { Button, Card, InputGroup, Table } from "react-bootstrap"
import { Plus } from "react-bootstrap-icons"
import React, { useState } from "react"
import { client } from "../../../../API/api"
import { GroupProductCardItem } from "./GroupProductCardItem"
import ModalComponent from "../../../../Common/ModalComponent"
import ShowItem from "../../ShowItem"

export const GroupProductCard = ({ products, handleUpdate, selected }) => {
  const [show, setShow] = useState(false)
  const groupedProducts = products
    ? Object.values(products).map((product) => product.productId)
    : []

  const [newGroupItems, setNewGroupItems] = useState([])
  //삭제버튼
  const handleDelete = async (optionId) => {
    try {
      await client.delete(`ProductOption/deleteOption?optionId=${optionId}`)

      // 상태를 변경해 서버에서 다시 데이터를 가져오게 함
      handleUpdate()
    } catch (error) {
      alert(error.response.data.message)
    }
  }

  console.log(selected)
  console.log(newGroupItems)
  const handleConfirm = async () => {
    try {
      // newGroupItems 배열의 각 항목에 대해 POST 요청
      for (let itemId of newGroupItems) {
        await client.put(
          `Product/updateGroup?groupId=${selected.groupId}&productId=${itemId}`
        ) // productId 대신 필요한 값을 설정
        await client.post(`ProductOption/createOption`, { productId: itemId })
      }

      handleUpdate()
      setShow(false)
      alert("그룹 추가 완료.")
      console.log("모든 항목에 대해 POST 요청이 완료되었습니다.")
    } catch (error) {
      console.error("POST 요청 중 오류 발생:", error)
      alert(error.response.data.message)
    }
  }

  return (
    <>
      <Card
        className="col-md-8"
        style={{ paddingTop: "10px", marginRight: "-10px" }}
      >
        <div>
          <h2 className="text-xl font-semibold mb-2">
            {selected && selected.groupName
              ? `${selected.groupName} 그룹 상품`
              : ""}
            그룹 상품
          </h2>
          <div style={{ height: "500px", overflowY: "auto" }}>
            <Table bordered hover>
              <thead
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1,
                }}
              >
                <tr>
                  <th>옵션 표시명</th>
                  <th>상품명</th>
                  <th>작업</th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((product) => (
                    <GroupProductCardItem
                      product={product}
                      handleUpdate={handleUpdate}
                      key={product.optionId}
                      groupId={selected.groupId}
                      handleDelete={handleDelete}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100px",
                          textAlign: "center",
                        }}
                      >
                        그룹화된 상품이 없습니다.
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          {selected !== null && (
            <InputGroup className="mb-3">
              <Button
                variant="primary"
                onClick={() => {
                  setShow(true)
                }}
              >
                <Plus className="h-4 w-4 mr-2" />
                상품 추가
              </Button>
            </InputGroup>
          )}
        </div>
      </Card>
      <ModalComponent
        title={"그룹화 추가"}
        body={
          <ShowItem
            mode={true}
            groupedProducts={groupedProducts}
            newGroupItems={newGroupItems}
            setNewGroupItems={setNewGroupItems}
          />
        }
        show={show}
        handleClose={() => {
          setShow(false)
          setNewGroupItems([])
        }}
        onConfirm={handleConfirm}
      />
    </>
  )
}
