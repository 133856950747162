import React from "react"
import {
  StyledTable,
  TableContainer,
  TableCustomButton,
  TableHead,
  TableHeader,
  TableRow,
} from "./AdminOrderTable.style"

export function AdminOrderTable() {
  return (
    <TableContainer>
      <TableHeader>
        <p>주문 건수:</p>
        <div style={{ display: "flex", marginLeft: "auto" }}>
          <TableCustomButton size="sm">엑셀 받기</TableCustomButton>
          <TableCustomButton size="sm">송장번호 엑셀 업로드</TableCustomButton>
          <TableCustomButton size="sm">송장 내용 엑셀 받기</TableCustomButton>
        </div>
      </TableHeader>
      <StyledTable>
        <TableHead>
          <tr>
            <th>주문 상태</th>
            <th>순번</th>
            <th>판매처</th>
            <th>채널</th>
            <th>결제위치</th>
            <th>주문번호</th>
            <th>주문 상세번호</th>
            <th>주문일자</th>
            <th>결제일자</th>
            <th>배송 완료일</th>
            <th>구매확정일</th>
            <th>상품명</th>
            <th>옵션명</th>
          </tr>
        </TableHead>
        <tbody>
          {/* 예시 데이터 */}
          <TableRow>
            <td>배송중</td>
            <td>1</td>
            <td>판매처1</td>
            <td>채널1</td>
            <td>결제위치1</td>
            <td>주문번호1</td>
            <td>상세번호1</td>
            <td>2024-08-01</td>
            <td>2024-08-02</td>
            <td>2024-08-05</td>
            <td>2024-08-10</td>
            <td>상품명1</td>
            <td>옵션명1</td>
          </TableRow>
          <TableRow>
            <td>주문완료</td>
            <td>2</td>
            <td>판매처2</td>
            <td>채널2</td>
            <td>결제위치2</td>
            <td>주문번호2</td>
            <td>상세번호2</td>
            <td>2024-08-03</td>
            <td>2024-08-04</td>
            <td>2024-08-06</td>
            <td>2024-08-11</td>
            <td>상품명2</td>
            <td>옵션명2</td>
          </TableRow>
          {/* 여기에 추가 데이터가 들어갑니다 */}
        </tbody>
      </StyledTable>
    </TableContainer>
  )
}
