import React from "react"
import { Dropdown } from "react-bootstrap"

export default function YearDropdown({ year, setYear, startYear }) {
  // 현재 연도 가져오기
  const currentYear = new Date().getFullYear()

  // 현재 연도부터 시작 연도까지의 배열 생성
  const years = []
  for (let i = currentYear; i >= startYear; i--) {
    years.push(i)
  }

  return (
    <div>
      <Dropdown style={{ textAlign: "start" }}>
        <Dropdown.Toggle variant="dropdown-basic-button" id="dropdown-basic">
          {year}년
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {years.map((item, index) => (
            <Dropdown.Item
              key={index}
              href={`#/action-${index}`}
              onClick={() => setYear(item)}
            >
              {item}년
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
