import React from "react"
import styled from "styled-components"

// 기본 별점 컴포넌트 스타일
const StarRatingWrapper = styled.div`
  display: inline-block;
`

// 배경 별점 스타일
const BackStars = styled.div`
  display: flex;
  color: lightgray;
  position: relative;
  font-size: ${(props) => props.size || "14px"};

  @media (max-width: 450px) {
    font-size: ${(props) => (props.size ? "1rem" : "14px")};
  }

  &::before {
    content: "★★★★★";
  }
`

// 앞쪽 별점 스타일
const FrontStars = styled.div`
  display: flex;
  color: #fb2200;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;

  &::before {
    content: "★★★★★";
  }
`

// StarRating 컴포넌트
const StarRating = ({ rating, mode, size }) => {
  const ratingPercentage = Math.round((rating / 5) * 100) // 별점을 퍼센티지로 변환

  return (
    <StarRatingWrapper>
      <BackStars mode={mode} size={size}>
        <FrontStars style={{ width: `${ratingPercentage}%`, fontSize: size }} />
      </BackStars>
    </StarRatingWrapper>
  )
}

export default StarRating
