import React, { useEffect, useState } from "react"

import { Button, Input } from "reactstrap"
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from "firebase/storage"
import imageCompression from "browser-image-compression"
import { storage } from "../../../firebase"
import { client } from "../../API/api"

export default function AnnouncementPopUp() {
  const [image, setImage] = useState(null)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [defaultPhoto, setDefaultPhoto] = useState([])

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await client.get("/Popup/findAllPopup")
        setDefaultPhoto(response.data)
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
    getData()
  }, [])

  // 이미지 변경 핸들러
  const handleImageChange = async (e) => {
    const file = e.target.files[0]

    if (file && file.name) {
      const fileExtension = file.name.split(".").pop().toLowerCase()

      if (fileExtension === "webp") {
        setImage(file)
        setPreviewUrl(URL.createObjectURL(file))
      } else {
        try {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/webp",
          }
          const compressedFile = await imageCompression(file, options)
          const newFileName =
            file.name.split(".").slice(0, -1).join(".") + ".webp"
          const webpFile = new File([compressedFile], newFileName, {
            type: "image/webp",
          })

          setImage(webpFile)
          setPreviewUrl(URL.createObjectURL(webpFile))
        } catch (error) {
          console.error("Image compression failed:", error)
        }
      }
    } else {
      console.error("No file selected or file name is undefined.")
    }
  }

  // 이미지 업로드 핸들러
  const handleUpload = async () => {
    if (!image) {
      alert("이미지를 선택해주세요.")
      return
    }

    setUploading(true)

    const storageRef = ref(
      storage,
      `announcements/${encodeURIComponent(image.name)}`
    )

    try {
      // Firebase에 이미지 업로드
      await uploadBytes(storageRef, image)
      const downloadURL = await getDownloadURL(storageRef)

      // 서버에 이미지 URL 저장
      await client.post("/Popup/createPopup", { photoUrl: downloadURL })
      alert("공지사항이 업로드되었습니다!")
    } catch (error) {
      console.error("Upload failed:", error)
      alert("업로드에 실패했습니다.")
    } finally {
      setUploading(false)
    }
  }
  // url로 firebase 경로 찾아서 삭제
  const extractPath = (url) => {
    const regex = /\/o\/(.*?)\?/
    const match = url.match(regex)
    if (match && match[1]) {
      return decodeURIComponent(match[1])
    }
    return null
  }
  // 이미지 삭제 핸들러
  const handleDelete = async () => {
    if (!defaultPhoto.length || !defaultPhoto[0].photoUrl) {
      alert("삭제할 이미지가 없습니다.")
      return
    }

    setUploading(true)

    try {
      const storage = getStorage()
      console.log(defaultPhoto[0].photoUrl)
      const photoPath = extractPath(defaultPhoto[0].photoUrl)
      const imageRef = ref(storage, photoPath)
      await deleteObject(imageRef)
      await client.delete(`/Popup/deletePopup?id=${defaultPhoto[0].id}`)
      // window.location.reload();
      alert("팝업이 성공적으로 삭제되었습니다.")
      // Firebase에서 이미지 삭제
    } catch (error) {
      alert("오류발생")
      console.error("Delete failed:", error)
    }
  }

  return (
    <div className="container">
      <h2>공지사항 팝업 관리</h2>
      <p style={{ marginTop: "20px", marginBottom: "10px" }}>공지사항 이미지</p>

      <Input
        type="file"
        onChange={handleImageChange}
        style={{ marginBottom: "10px" }}
      />
      {defaultPhoto.length > 0 && defaultPhoto[0].photoUrl ? (
        <div>
          <img src={defaultPhoto[0].photoUrl} alt="기존 공지사항" />
          <Button
            onClick={handleDelete}
            disabled={uploading}
            style={{ display: "block", marginTop: "10px" }}
          >
            {uploading ? "삭제 중..." : "이미지 삭제"}
          </Button>
        </div>
      ) : (
        <p>기존 공지사항 이미지가 없습니다.</p>
      )}
      {previewUrl && <img src={previewUrl} alt="Uploaded" width="100%" />}

      {/*<CKEditorComponent />*/}
      <Button
        onClick={handleUpload}
        disabled={uploading}
        style={{ display: "block", marginTop: "20px" }}
      >
        {uploading ? "업로드 중..." : "업로드"}
      </Button>
    </div>
  )
}
