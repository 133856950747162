import React, { useEffect, useState } from "react"
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import {
  ChatDotsFill,
  GeoAltFill,
  PersonFill,
  PhoneFill,
  Search,
} from "react-bootstrap-icons"
import DaumPostcode from "react-daum-postcode"
import { client } from "../../../../API/api"

export const EditAddShippingAddress = ({
  item,
  setIsModified,
  handleUpdate,
}) => {
  const [address, setAddress] = useState("")
  const [alias, setAlias] = useState("")
  const [customerName, setCustomerName] = useState("")
  const [detailAddress, setDetailAddress] = useState("")
  const [isDefault, setIsDefault] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState("")
  const [postCode, setPostCode] = useState("")
  const [showPostcode, setShowPostcode] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (item) {
      setAddress(item.address || "")
      setAlias(item.alias || "")
      setCustomerName(item.customerName || "")
      setDetailAddress(item.detailAddress || "")
      setIsDefault(item.isDefault || false)
      setPhoneNumber(formatPhoneNumber(item.phoneNumber || "")) // 초기값을 포맷팅된 형식으로
      setPostCode(item.postCode || "")
      setEditMode(true)
    }
  }, [item])

  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "")
    const match = cleaned.match(/^(\d{0,3})(\d{0,4})(\d{0,4})$/)

    if (match) {
      return [match[1], match[2], match[3]].filter((group) => group).join("-")
    }
    return value
  }

  const handlePhoneNumberChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value)
    setPhoneNumber(formattedPhoneNumber)
  }

  const handleAddressComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ""

    if (data.addressType === "R") {
      if (data.bname !== "") extraAddress += data.bname
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : ""
    }

    setAddress(fullAddress)
    setPostCode(data.zonecode)
    setShowPostcode(false)
  }

  const handleSubmit = () => {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "") // '-' 제거한 숫자만의 값

    const data = {
      memberId: localStorage.getItem("userId"),
      customerName,
      alias,
      phoneNumber: cleanedPhoneNumber, // 서버로 전송할 때는 숫자만
      address,
      detailAddress,
      postCode,
      isDefault,
    }

    const fetchData = async () => {
      try {
        if (editMode) {
          await client.put(`/Address/updateAddress?id=${item.id}`, data)
          if (isDefault) {
            await client.put(`/Address/updateAddressDefault?id=${item.id}`)
          }
        } else {
          await client.post(`/Address/createAddress`, data)
        }
        setIsModified(false)
        handleUpdate()
      } catch (error) {
        alert(error.response.data.message)
      }
    }
    fetchData()
  }

  return (
    <>
      <Form style={{ marginTop: "10px" }}>
        <Form.Group className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <ChatDotsFill />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="별명"
              value={alias}
              onChange={(e) => setAlias(e.target.value)}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <PersonFill />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="받는사람"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <GeoAltFill />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="주소"
              value={address}
              onClick={() => setShowPostcode(true)}
              readOnly
            />
            <InputGroup.Text>
              <Search />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <GeoAltFill />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="상세주소"
              value={detailAddress}
              onChange={(e) => setDetailAddress(e.target.value)}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <InputGroup>
            <InputGroup.Text>
              <PhoneFill />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder="휴대폰 번호"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              maxLength={13}
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Check
            type="checkbox"
            label="기본 배송지로 선택"
            checked={isDefault}
            onChange={(e) => setIsDefault(e.target.checked)}
          />
        </Form.Group>

        <Button
          variant="primary"
          style={{ width: "100%" }}
          onClick={handleSubmit}
        >
          저장
        </Button>
      </Form>

      <Modal show={showPostcode} onHide={() => setShowPostcode(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>주소 검색</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DaumPostcode
            style={{ height: "600px" }}
            onComplete={handleAddressComplete}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
