import React from "react"
import styled from "styled-components"
import { Images } from "react-bootstrap-icons"

const ImageUploader = ({ files, setFiles, setFilePreviews, size }) => {
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files)

    // 사진을 최대 5개로 제한
    if (files.length + selectedFiles.length > 5) {
      alert("최대 5개의 이미지만 업로드할 수 있습니다.")
      return
    }

    setFiles((prevFiles) => [...prevFiles, ...selectedFiles])

    const newPreviews = selectedFiles.map((file) => URL.createObjectURL(file))
    setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews])
  }

  return (
    <div>
      <FileLabel htmlFor="file-upload">
        <Images style={{ marginRight: "10px" }} />
        사진 추가 (최대 {size}/5개){" "}
      </FileLabel>
      <FileInput
        id="file-upload"
        type="file"
        multiple
        onChange={handleFileChange}
      />
    </div>
  )
}

export default ImageUploader

const FileLabel = styled.label`
  display: block;
  padding: 10px;
  border-radius: 5px;
  border: 1px dashed #ccc;
  text-align: center;
  cursor: pointer;
  color: #007bff;
`

const FileInput = styled.input`
  display: none;
`
