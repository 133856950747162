import React, { useState } from "react"
import up from "./Img/up.png"
import "../../CSS/Remote/Remote.css"
import RemoteCart from "./RemoteCart"
import styled from "styled-components"
import { useRecoilState } from "recoil"
import { shoppingCart } from "../../App"

const RemoteCartWrapper = styled.div`
  position: relative;
`

const CartCount = styled.div`
  position: absolute;
  top: -0%; /* 부모의 세로 중앙에 위치 */
  left: 50%; /* 부모의 가로 중앙에 위치 */
  transform: translate(50%, -50%) translateX(50px); /* 중앙에서 100px 오른쪽으로 이동 */
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
`
export default function Remote() {
  const [list, setList] = useRecoilState(shoppingCart)
  const [slideUp, setSlideUp] = useState(false)
  const totalQuantity = list.reduce((total, item) => total + item.quantity, 0)
  const handleSlide = () => {
    setSlideUp(!slideUp) // 상태 토글
  }
  return (
    <div>
      <div
        className={`remote-button ${slideUp ? "slide-top" : "slide-down"}`}
        onClick={handleSlide}
      >
        {/* 이미지로 되어있음 */}
        <img
          src={up}
          className={`remote-img ${
            slideUp ? "rotate-center" : "rotate-center-reverse"
          }`}
          alt="up"
        />
      </div>
      <div
        className={`remote-container ${slideUp ? "slide-top" : "slide-down"}`}
      >
        {slideUp ? (
          <div className="remote-cart">
            <RemoteCart
              list={list}
              setList={setList}
              totalQuantity={totalQuantity}
            />
          </div>
        ) : (
          <RemoteCartWrapper>
            <p className="remote-text" onClick={() => handleSlide()}>
              <strong>간편 장바구니</strong>
            </p>
            {list.length !== 0 ? <CartCount>{totalQuantity}</CartCount> : <></>}
          </RemoteCartWrapper>
        )}
      </div>
    </div>
  )
}
