import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import { client } from "../../../API/api"
import { Form } from "react-bootstrap"

const FormWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h1`
  text-align: center;
  color: #333;
`

const FormGroup = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`

const BasicData = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f8f9fa;
  font-size: 16px;
  color: #333;
`

const TextArea = styled(Form.Control).attrs({ as: "textarea" })`
  width: 100%;
  height: 200px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`

const EmailInput = styled.input`
  width: 45%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`

const AtSymbol = styled.span`
  margin: 0 10px;
  font-size: 18px;
`

const Select = styled.select`
  width: 45%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`

const Checkbox = styled.input`
  margin-right: 10px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:first-of-type {
    background-color: #28a745;
  }

  &:first-of-type:hover {
    background-color: #218838;
  }
`

const Notice = styled.div`
  margin-top: 20px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
`

const NoticeTitle = styled.p`
  font-weight: bold;
`

const NoticeList = styled.ul`
  padding-left: 20px;
`

const NoticeItem = styled.li`
  margin-bottom: 5px;
  word-wrap: break-word;
  white-space: normal;
`

export default function QnaForm() {
  const [inquiryType, setInquiryType] = useState("")
  const [content, setContent] = useState("")
  const [isPrivate, setIsPrivate] = useState(false)
  const [data, setData] = useState([])
  const { id } = useParams("id")
  const navi = useNavigate()
  // const nickname = useRecoilValue(UNickName);
  // const userid = useRecoilValue(UId);

  const nickname = localStorage.getItem("nickname")
  const userid = localStorage.getItem("userId")

  console.log("userId", localStorage.getItem("userId"))
  console.log(id)
  console.log("content", content)

  const handleBack = () => {
    if (window.confirm("취소하시겠습니까?")) {
      navi(-1)
    }
  }

  const postData = async () => {
    const data = {
      userId: userid, // Replace with the actual userId
      productId: id, // Replace with the actual productId
      title: content, // Replace with the actual title
      content: "string",
      photoUrl: "http://noImage", // Replace with the actual photoUrl
      privateFlag: isPrivate, //비밀글
    }

    client
      .post("/Qna/createQna", data)
      .then((res) => {
        console.log(res)
        alert("QnA가 성공적으로 추가되었습니다!")
        navi(-1)
      })
      .catch((error) => {
        console.log(error)
        alert("QnA 추가에 실패 하였습니다.")
      })
  }

  const handleSubmit = async (event) => postData()

  //   데이터 받아오기
  useEffect(() => {
    const fetchAndSetData = async () => {
      const response = await client.get(`/Product/getProductById?id=${id}`)
      setData(response.data)
    }
    fetchAndSetData()
  }, [id]) // page가 변경될 때마다 데이터를 가져오도록 설정

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Title>상품 문의하기</Title>
      <FormGroup>
        <Label>닉네임</Label>
        <BasicData>{nickname}</BasicData>
      </FormGroup>
      <FormGroup>
        <Label>상품</Label>
        <BasicData>{data.name}</BasicData>
      </FormGroup>
      <FormGroup>
        <Label>내용</Label>
        <TextArea
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
        <br />
        <div
          onClick={() => setIsPrivate(!isPrivate)}
          style={{ cursor: "pointer", display: "inline-block" }}
        >
          <input type={"checkbox"} checked={isPrivate} /> 비밀글
        </div>
      </FormGroup>

      <ButtonGroup>
        <Button type="button" onClick={() => handleBack()}>
          취소
        </Button>
        <Button onClick={() => handleSubmit()}>등록</Button>
      </ButtonGroup>
      <Notice>
        <NoticeTitle>문의 시 유의사항</NoticeTitle>
        <NoticeList>
          {/*<NoticeItem>개인정보가 포함된 글은 비밀글로 등록됩니다.</NoticeItem>*/}
          {/*<NoticeItem>*/}
          {/*  배송, 반품/취소, 교환/변경, 기타 문의는 비밀글로 등록됩니다.*/}
          {/*</NoticeItem>*/}
          <NoticeItem>
            부적절한 게시물 등록 시 ID의 제한 및 게시물이 삭제될 수 있습니다.
          </NoticeItem>
          <NoticeItem>
            상품에 대한 단순 불만이나 판매자에게 불리한 내용이라도 이유로는
            삭제되지 않습니다.
          </NoticeItem>
        </NoticeList>
      </Notice>
    </FormWrapper>
  )
}
