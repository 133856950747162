import styled from "styled-components"

export const CustomerServiceContainer = styled.div`
  display: flex;
  gap: 1.5%;
  padding: 1.5%;
  height: 300px;
  @media (max-width: 480px) {
    height: 200px;
  }
`
export const CustomerServiceBox = styled.div`
  width: 50%;
  background-color: #498ddd;
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: 100%;
  .customer-service-text {
    font-size: 3vw;
    color: white;
  }

  .customer-service-image {
    width: 20%;
  }
  @media (max-width: 480px) {
    height: 200px;
    .customer-service-image {
      width: 20%;
    }
  }
`

export const Box = styled.div`
  text-align: center;
  background-color: #eff5fb;
  padding-top: 2%;
  padding-bottom: 3%;
  margin-bottom: 5%;
`

export const ImageSlideWrapper = styled(Box)`
  padding: 0;
  background-color: white;
`

export const NaverInstaContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    height: 200px;
  }
`
export const InstagramBox = styled.div`
  width: 100%;
  height: 49%;
  margin-bottom: 1%;
  background-color: #498ddd;
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  background: radial-gradient(
      circle farthest-corner at 32% 106%,
      #ffe17d 0%,
      #ffcd69 10%,
      #fa9137 28%,
      #eb4141 42%,
      transparent 82%
    ),
    linear-gradient(135deg, #234bd7 12%, #c33cbe 58%);

  .instagram-image {
    width: 30%;
    margin-right: -5%;
  }

  .instagram-text {
    font-size: 2.5vw;
    color: white;
    vertical-align: middle;
  }
  @media (max-width: 480px) {
    height: 100px;
    .instagram-image {
      width: 20%;
    }
  }
`
export const NaverBox = styled.div`
  vertical-align: middle;
  width: 100%;
  height: 50%;
  background-color: #00c73c;
  padding: 3%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .naver-image {
    width: 20%;
  }

  .naver-text {
    vertical-align: middle;
    font-size: 2.5vw;
    color: white;
  }
  @media (max-width: 480px) {
    height: 100px;
    .naver-image {
      width: 20%;
    }
  }
`
