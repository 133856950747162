import axios from "axios"
import { v4 as uuidv4 } from "uuid"

const baseURL = process.env.REACT_APP_SERVER_URL
// Axios instance with credentials
const axiosInstance = axios.create({ withCredentials: true })

// Function to prepare the order
export async function prepareOrder(cartItems, userId) {
  const orderId = uuidv4() // Generate a UUID for the order
  try {
    const items = cartItems.map((item) => ({
      productId: item.id,
      quantity: item.quantity,
    }))
    const response = await axiosInstance.post(
      `${baseURL}/Order/prepare`,
      {
        orderId, // Pass the generated order ID
        userId,
        items,
      },
      { withCredentials: true }
    )

    console.log(response.data)
    return { ...response.data, orderId } // Return response with orderId
  } catch (error) {
    console.error("Error preparing order:", error)
    alert(error.message) // Notify the user of an error
    throw error
  }
}

// Function to complete the order (submit user info)
export async function completeOrder(tempOrder, userInfo) {
  try {
    // Complete order request to the server
    const response = await axiosInstance.post(
      `${baseURL}/Order/complete`,
      {
        customerName: userInfo.customerName,
        address: userInfo.address,
        detailAddress: userInfo.detailAddress,
        postCode: userInfo.postCode,
        phoneNumber: userInfo.phoneNumber,
        orderId: tempOrder.orderId, // Pass the generated order ID
      },
      { withCredentials: true }
    )

    console.log(response.data)
    return response.data
  } catch (error) {
    console.error("Error completing order:", error)
    throw error
  }
}

// Function to handle payment processing
export async function handlePayment(orderInfo, cartItems, payMethod) {
  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  )

  console.log("Calculated totalPrice:", totalPrice) // Log for debugging

  const productIdList = cartItems.map((item) => item.id)

  window.IMP.init("imp34828217")
  const merchantUid = uuidv4() // Generate a unique merchant_uid

  return new Promise((resolve, reject) =>
    window.IMP.request_pay(
      {
        pg: "kakaopay",
        pay_method: payMethod,
        merchant_uid: merchantUid, // This value must be unique
        name: "원정푸드 Payment", // Payment name (e.g., cart payment)
        amount: totalPrice, // Pass calculated totalPrice
        buyer_name: orderInfo.customerName,
        buyer_tel: orderInfo.phoneNumber,
        buyer_postcode: orderInfo.postCode,
        buyer_addr: `${orderInfo.address} ${orderInfo.detailAddress}`,
      },
      (rsp) => {
        if (rsp.success) {
          axios
            .post(`${baseURL}/Payment/${rsp.imp_uid}`, {
              memberId: localStorage.getItem("userId"), // Member ID received from the server
              orderId: orderInfo.orderId, // Order ID received from the server
              price: totalPrice,
              // Pass calculated totalPrice
              productIdList, // Pass generated productIdList from cartItems
            })
            .then((res) => resolve(res.data))
            .catch((error) => {
              console.error("Error in payment processing:", error)
              reject("Error in payment processing.")
            })
        } else {
          reject(`Payment failed: ${rsp.error_msg}`)
        }
      }
    )
  )
}
