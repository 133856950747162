import React, { useEffect, useRef } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import back from "../Img/companyIntro.png"
import t1 from "./eventTmp1.jpg"
import t2 from "./eventTmp2.jpg"
import t3 from "./eventTmp3.jpg"
import t4 from "./eventTmp4.jpg"
import t5 from "./eventTmp5.jpg"
import { useRecoilState } from "recoil"
import { imageSlidePositionState } from "../../../../App"
import "./CSS/ImageSlide.css"
import styled from "styled-components"

const CustomDots = styled.div`
  width: 100%;
  position: absolute;
  bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 480px) {
    bottom: 0px;
  }
`

const ImageSlide = () => {
  const imageSlideRef = useRef(null)
  const [, setImageSlidePosition] = useRecoilState(imageSlidePositionState)
  useEffect(() => {
    if (imageSlideRef.current) {
      setImageSlidePosition(imageSlideRef.current.getBoundingClientRect())
    }
  }, [setImageSlidePosition])

  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    pauseOnHover: true,
    accessibility: true,
    appendDots: (dots) => (
      <CustomDots>
        <ul> {dots} </ul>
      </CustomDots>
    ),
    dotsClass: "dots_custom",
  }
  return (
    <div ref={imageSlideRef} className="imageSlide">
      <Slider {...setting}>
        <div>
          <img src={back} alt="회사" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={t1} alt="이미지1" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={t2} alt="이미지2" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={t3} alt="이미지3" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={t4} alt="이미지4" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={t5} alt="이미지5" style={{ width: "100%" }} />
        </div>
      </Slider>
    </div>
  )
}

export default ImageSlide
