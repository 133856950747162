import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { category } from "../../../../../App"
import ProductForm from "../ProductForm"
import ImageUploadSection from "../ImageUploadSection"
import { client } from "../../../../API/api"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"
import { storage } from "../../../../../firebase"
import { Button } from "react-bootstrap"

const EditItem = ({ productId, handleEditClose }) => {
  const [categoryName, setCategoryName] = useState("카테고리를 선택하세요")
  const [productName, setProductName] = useState("")
  const [price, setPrice] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [thumbnail, setThumbnail] = useState(null) // Blob or URL
  const [thumbnailUrl, setThumbnailUrl] = useState("") // Original URL for deletion
  const [detailImages, setDetailImages] = useState([]) // Blob or URL array with id
  const [originalDetailUrls, setOriginalDetailUrls] = useState([]) // Original URLs for deletion
  const [deleteImageIds, setDeleteImageIds] = useState([]) // 삭제할 이미지의 id 보관
  const categoryList = useRecoilValue(category)

  const findCategoryName = (categoryId) => {
    const category = categoryList.find((category) => category.id === categoryId)
    return category ? category.categoryName : "Category not found"
  }

  console.log(productId)
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await client.get(
          `/Product/getProductById?id=${productId}`
        )
        const product = response.data
        setProductName(product.name)
        setPrice(product.price)
        setCategoryId(product.categoryId)
        setCategoryName(findCategoryName(product.categoryId))

        // Set the original thumbnail URL
        setThumbnailUrl(product.photoUrl)
        setThumbnail(product.photoUrl) // Set thumbnail as URL

        // Fetch detail image URLs and store {id, url} structure
        const fetchDetailUrl = await client.get(
          `/Product/findDetailUrlByProductId?productId=${product.id}`
        )
        const fetchDetailList = fetchDetailUrl.data // { id, url } 형태의 배열
        setOriginalDetailUrls(fetchDetailList) // Store original detail images
        setDetailImages(fetchDetailList) // Set detail images with id and url
      } catch (error) {
        console.error("Error fetching product details:", error)
      }
    }

    fetchProductDetails()
  }, [productId])

  const handleUpload = async (image) => {
    if (image) {
      const storageRef = ref(storage, `/${categoryName}/${image.name}`)
      try {
        await uploadBytes(storageRef, image)
        const url = await getDownloadURL(storageRef)
        console.log("Uploaded image URL:", url)
        return url
      } catch (error) {
        console.error("Upload failed:", error)
        throw error
      }
    }
    return ""
  }

  const handleImagesUpload = async (images) => {
    const urls = []
    for (const image of images) {
      console.log("Image type:", image.constructor.name) // 디버깅용
      if (image instanceof File) {
        // File 객체인지 확인
        const storageRef = ref(storage, `${categoryName}/${image.name}`)
        try {
          await uploadBytes(storageRef, image)
          const url = await getDownloadURL(storageRef)
          urls.push(url) // id가 없는 경우 추가
          console.log("성공")
        } catch (error) {
          console.error("Image upload failed:", error)
        }
      } else if (image.url) {
        urls.push(image.url) // URL이 포함된 기존 이미지
      }
    }
    return urls
  }

  const deleteImageFromServer = async (id) => {
    try {
      await client.delete(`/Product/deleteDetailUrl?id=${id}`)
      console.log(`Deleted image with id from server: ${id}`)
    } catch (error) {
      console.error("Server image deletion failed:", error)
    }
  }

  const extractPath = (url) => {
    const regex = /\/o\/(.*?)\?/
    const match = url.match(regex)
    return match && match[1] ? decodeURIComponent(match[1]) : null
  }

  const deleteImage = async (id, url) => {
    const photoPath = extractPath(url)
    if (photoPath) {
      const storageRef = ref(storage, photoPath)
      try {
        await deleteObject(storageRef)
        if (id) {
          await deleteImageFromServer(id)
        }
      } catch (error) {
        console.error("Image deletion failed:", error)
      }
    } else {
      console.error("Invalid image URL, unable to delete:", url)
    }
  }

  const deleteUploadedImages = async () => {
    // 썸네일 삭제
    if (thumbnailUrl && thumbnailUrl !== thumbnail) {
      await deleteImage(null, thumbnailUrl)
    }

    // 삭제할 상세 이미지를 처리
    const deletedImages = originalDetailUrls.filter(
      (original) => !detailImages.some((image) => image.url === original.url)
    )

    for (const { id, url } of deletedImages) {
      await deleteImage(id, url)
    }
  }

  const handleDeleteDetailImage = (id, url) => {
    setDeleteImageIds((prevIds) => [...prevIds, id])
    setDetailImages(detailImages.filter((image) => image.id !== id))
  }

  const deleteSelectedImages = async () => {
    for (const id of deleteImageIds) {
      await deleteImageFromServer(id)
    }
    setDeleteImageIds([])
  }

  const updateProduct = async (
    productName,
    price,
    categoryId,
    thumbnailUrl,
    detailImages
  ) => {
    try {
      console.log("testdetailImages", detailImages)
      await client.put(`/Product/updateProductInfo?id=${productId}`, {
        name: productName,
        price: parseFloat(price),
        categoryId,
        photoUrl: thumbnailUrl,
        detailUrlList: detailImages,
      })
      await deleteSelectedImages()
      alert("상품이 성공적으로 수정되었습니다!")
    } catch (error) {
      alert("상품 수정에 실패하였습니다.")
      await deleteUploadedImages()
    }
  }

  const handleSubmit = async () => {
    if (!checkValid()) {
      alert(`정보를 입력해주세요 
    price:${price}
    productName:${productName.trim()}
    categoryId:${categoryId.trim()}
    thumbnail:${thumbnail}`)
    } else {
      try {
        // 1. 새 썸네일 이미지가 있으면 업로드
        let newThumbnailUrl = thumbnail
        if (thumbnail instanceof File) {
          newThumbnailUrl = await handleUpload(thumbnail)
        }

        // 2. 새 상세 이미지들 업로드
        console.log(detailImages)
        const newDetailImages = await handleImagesUpload(detailImages)
        console.log("newDetailImages", newDetailImages)

        // 3. 상품 업데이트(이미지 URL 포함)
        await updateProduct(
          productName,
          price,
          categoryId,
          newThumbnailUrl,
          newDetailImages
        )

        // 4. 기존 이미지 삭제 (업데이트 후 삭제 처리)
        await deleteUploadedImages()

        alert("상품이 성공적으로 수정되었습니다!")
      } catch (error) {
        console.error("Error during product update:", error)
        alert("상품 수정 중 오류가 발생했습니다.")
      }
    }
    window.location.reload()
  }

  const checkValid = () =>
    price > 0 &&
    productName.trim() !== "" &&
    categoryId.trim() !== "" &&
    thumbnail !== null &&
    thumbnail !== ""

  return (
    <div style={{ minWidth: "50%" }}>
      <ProductForm
        productName={productName}
        setProductName={setProductName}
        price={price}
        setPrice={setPrice}
        categoryName={categoryName}
        setCategoryName={setCategoryName}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        categoryList={categoryList}
      />
      <ImageUploadSection
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        detailImages={detailImages}
        setDetailImages={setDetailImages}
        onDeleteDetailImage={handleDeleteDetailImage}
      />
      <hr style={{ marginLeft: "-16px", marginRight: "-16px" }} />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="secondary"
          style={{ marginRight: "10px" }} // style로 marginRight 적용
          onClick={handleEditClose}
        >
          취소
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          수정 완료
        </Button>
      </div>
    </div>
  )
}

export default EditItem
