import React from "react"
import { useNavigate } from "react-router-dom"
import Slider from "react-slick"
import { useRecoilState, useRecoilValue } from "recoil"
import { firstReview, itemId } from "../../../../App"
import text from "../Img/text.png"
import StarRating from "../../Item/StarRating"
import styled from "styled-components"

const SliderContainer = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  .slick-dots {
    bottom: -30px;
  }

  @media (max-width: 480px) {
    .slick-slide {
      display: flex;
      justify-content: center;
    }
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #a4aaae; /* 배경색을 추가하여 이미지가 작을 경우 배경 확장 */
`

const Image = styled.img`
  width: 250px;
  max-height: 100%;
  object-fit: contain; /* 이미지의 비율을 유지하면서 부모 컨테이너에 맞추기 */
  background-color: #a4aaae; /* 이미지보다 작을 경우 배경 확장 */
`

const ReviewSlide = () => {
  const navi = useNavigate()
  const list = useRecoilValue(firstReview)
  const [selectItem, setSelectItem] = useRecoilState(itemId)
  console.log("listeee", list)
  // 수정된 truncateText 함수
  const truncateText = (name, maxLength) => {
    if (!name) {
      return "" // name이 undefined 또는 null일 경우 빈 문자열 반환
    }
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`
    }
    return name
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: list.length > 4,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    nextArrow: <></>,
    prevArrow: <></>,
    responsive: [
      {
        breakpoint: 1190,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  }

  console.log("list", list)

  return (
    <SliderContainer>
      <h2>
        <strong>실시간 상품 후기</strong>
      </h2>
      {list.length === 1 ? (
        <div
          key={list[0].id}
          onClick={() => {
            navi(`/item/${list[0].productId}`)
            setSelectItem(list[0])
          }}
        >
          <div
            style={{
              margin: "10px",
              background: "white",
              height: "500px",
            }}
          >
            <ImageContainer>
              <Image
                src={
                  Array.isArray(list[0].photoList) &&
                  list[0].photoList.length > 0
                    ? list[0].photoList[0] // 배열의 첫 번째 이미지 사용
                    : list[0].productPhotoUrl // photoList가 없으면 기본 상품 이미지 사용
                }
                alt="사진"
                className="item-img"
                style={{ cursor: "pointer" }}
              />
            </ImageContainer>
            <div style={{ padding: "4%", cursor: "pointer" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                {truncateText(list[0].productName, 10)}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "-1.5%",
                }}
              >
                <StarRating rating={list[0].rating} mode={true} />
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  padding: "0% 1.5% 1.5% 1.5%",
                }}
              >
                <img src={text} alt="asd" style={{ width: "10%" }} />
              </div>
              <div style={{ textAlign: "justify" }}>
                <p className="firstReview_Text">
                  {truncateText(list[0].text, 43)}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Slider {...settings}>
          {list.map((item) => (
            <div
              key={item.id}
              onClick={() => {
                navi(`/item/${item.productId}`)
                setSelectItem(item)
              }}
              style={{ cursor: "pointer" }}
            >
              <div
                style={{ margin: "10px", background: "white", height: "500px" }}
              >
                <ImageContainer>
                  <Image
                    // 리뷰 이미지가 배열인 경우 첫 번째 이미지만 표시
                    src={
                      Array.isArray(item.photoList) && item.photoList.length > 0
                        ? item.photoList[0] // 배열의 첫 번째 이미지 사용
                        : item.productPhotoUrl // photoList가 없으면 기본 상품 이미지 사용
                    }
                    alt="사진"
                    className="item-img"
                    style={{ cursor: "pointer" }}
                  />
                </ImageContainer>
                <div style={{ padding: "4%", cursor: "pointer" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                    }}
                  >
                    {truncateText(item.productName, 10)}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "-1.5%",
                    }}
                  >
                    <StarRating rating={item.rating} mode={true} />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      padding: "0% 1.5% 1.5% 1.5%",
                    }}
                  >
                    <img src={text} alt="asd" style={{ width: "10%" }} />
                  </div>
                  <div style={{ textAlign: "justify" }}>
                    <p
                      className="firstReview_Text"
                      style={{
                        wordWrap: "break-word", // 긴 단어가 있으면 강제로 줄바꿈
                        whiteSpace: "normal", // 텍스트를 한 줄로만 보여주는 걸 방지 (기본값은 줄바꿈 허용)
                        wordBreak: "break-word", // 단어가 길 때 줄바꿈
                      }}
                    >
                      {item.content}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </SliderContainer>
  )
}

export default ReviewSlide
