import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, ButtonGroup, Container, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ko } from "date-fns/locale"
import { Input } from "reactstrap"
import { client } from "../../../API/api"
import PaginationComponent from "../../../Common/PaginationComponent"
import MyOrderList from "./MyOrderList"

const OrderInquiryWrapper = styled(Container)`
  margin-top: 20px;
`

const FilterWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 480px) {
    align-items: flex-start;
  }
`

const DatePickersWrapper = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker-wrapper {
    width: 100%;
  }
`

const OrdersGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 20px;
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: stretch;
  }
`

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 10px;
`

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`
const TitleFont = styled.h2`
  font-weight: 700;
`

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const MyOrderPage = () => {
  const [startDate, setStartDate] = useState(
    new Date(new Date().setMonth(new Date().getMonth() - 1))
  )
  const [endDate, setEndDate] = useState(new Date())
  const [filteredOrders, setFilteredOrders] = useState([])
  const [page, setPage] = useState(0)
  const [data, setData] = useState(null) // 초기값을 null로 설정

  useEffect(() => filterOrders(), [startDate, endDate])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(
          `/Order/findOrderByMemberId?page=${page}&size=5&userId=${localStorage.getItem("userId")}`
        )
        setData(response.data) // data를 설정
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    fetchData() // fetchData 함수 호출
  }, [page]) // 의존성 배열에 page 추가

  const filterOrders = () => {
    if (!data || !data.list) {
      return // data 또는 data.list가 없을 경우 안전하게 반환
    }

    const filtered = data.list.filter((order) => {
      const orderDate = new Date(order.orderDate) // orderDate를 문자열에서 Date 객체로 변환
      return orderDate >= startDate && orderDate <= endDate
    })

    setFilteredOrders(filtered) // 필터링된 결과를 설정
  }

  const handleDateRangeClick = (months) => {
    setEndDate(new Date())
    setStartDate(new Date(new Date().setMonth(new Date().getMonth() - months)))
  }

  return (
    <OrderInquiryWrapper>
      <TitleFont>주문 조회</TitleFont>
      <FilterWrapper>
        <Form>
          <ButtonRow>
            <ButtonGroup>
              <Button
                variant="outline-primary"
                onClick={() => handleDateRangeClick(3)}
              >
                3개월
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => handleDateRangeClick(6)}
              >
                6개월
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => handleDateRangeClick(12)}
              >
                1년
              </Button>
            </ButtonGroup>
            <DateWrapper>
              <DatePicker
                showIcon
                locale={ko}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy년 MM월"
                showMonthYearPicker
                className="form-control"
              />
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                {"~"}
              </div>

              <DatePicker
                showIcon
                locale={ko}
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                dateFormat="yyyy년 MM월"
                showMonthYearPicker
                className="form-control"
              />
            </DateWrapper>
            <InputWrapper>
              <Input placeholder="주문 상품의 정보를 입력하세요" />
            </InputWrapper>
            <Button className="w-100" onClick={filterOrders}>
              검색
            </Button>
          </ButtonRow>
        </Form>
      </FilterWrapper>
      <OrdersGrid>
        {data && data.list && data.list.length > 0 ? (
          data.list.map((orders, index) => <MyOrderList orders={orders} />)
        ) : (
          <div>주문 내역이 없습니다.</div>
        )}
      </OrdersGrid>
      <PaginationWrapper>
        {data && data.totalPages > 1 && (
          <PaginationComponent
            currentPage={page}
            totalPages={data.totalPages}
            onPageChange={setPage}
          />
        )}
      </PaginationWrapper>
    </OrderInquiryWrapper>
  )
}

export default MyOrderPage
