import React from "react"
import { Nav, NavDropdown } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { mobile } from "../../../App"
import { useRecoilValue } from "recoil"

// 스크롤바 스타일 추가
const ScrollContainer = styled.div`
  @media (max-width: 480px) {
    overflow-x: auto; /* x축 스크롤바 항상 표시 */
    -webkit-overflow-scrolling: touch;
    padding-bottom: 5px; /* 스크롤 바를 더 위로 이동 */
    white-space: nowrap; /* 콘텐츠가 한 줄로 유지되도록 설정 */
    padding-left: 5px;
    padding-right: 5px;
    z-index: 100;
    /* 스크롤바 스타일 */
    &::-webkit-scrollbar {
      height: 4px; /* 스크롤바의 높이를 더 키움 */
    }

    &::-webkit-scrollbar-track {
      background: transparent; /* 스크롤바의 배경 */
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3); /* 스크롤바의 색상 */
      border-radius: 10px; /* 스크롤바의 둥근 모서리 */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.5); /* 스크롤바의 색상 (hover 시) */
    }
  }
`

const ScrollableNav = styled(Nav)`
  @media (max-width: 480px) {
    display: inline-flex; /* 내용이 스크롤 영역을 넘어가도록 설정 */
    flex-wrap: nowrap; /* 내용이 한 줄로 유지되도록 설정 */
  }
`

export default function HeaderMenu({
  selectCate,
  categoryMenu,
  naviLinks,
  setSelectCate,
  handleNavClick,
}) {
  const navi = useNavigate()

  // 현재 화면 너비가 480px 이하인지 확인
  const isMobile = useRecoilValue(mobile)
  console.log("categoryMenu", categoryMenu)
  console.log("categoryName", selectCate)
  return (
    <ScrollContainer className="navbar-custom" style={{ alignItems: "center" }}>
      <ScrollableNav variant="underline" activeKey={selectCate}>
        {categoryMenu.length !== 0 &&
          categoryMenu.map((item) => (
            <Nav.Link
              key={item.id}
              eventKey={item.categoryName}
              onClick={() => {
                navi(`/Category/${item.id}`)
                setSelectCate(item.categoryName) // 선택된 카테고리 이름 저장
              }}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {item.categoryName}
            </Nav.Link>
          ))}
        {naviLinks.map((item, idx) =>
          item.label === "Company" ? (
            isMobile ? (
              // 모바일에서는 Dropdown 메뉴 없이 바로 이동
              <Nav.Link
                key={idx}
                eventKey="회사 소개"
                onClick={() => {
                  navi("/Company/Intro")
                  setSelectCate("회사 소개")
                }}
                style={{
                  paddingLeft: "5px",
                  paddingRight: "5px",
                }}
              >
                회사 소개
              </Nav.Link>
            ) : (
              // 데스크톱에서는 Dropdown 메뉴 표시
              <Nav.Item
                key={idx}
                style={{
                  zIndex: "10",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <NavDropdown
                  title="회사 소개"
                  className="nav-link-company"
                  key={item.id}
                  eventKey="회사 소개"
                >
                  <NavDropdown.Item
                    eventKey="회사 소개"
                    style={{
                      color:
                        selectCate === item.categoryName
                          ? item.fontColor
                          : "inherit",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      handleNavClick("/Company/Intro", "회사 소개")
                    }
                  >
                    회사 소개
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="인증 목록"
                    style={{
                      color:
                        selectCate === item.categoryName
                          ? item.fontColor
                          : "inherit",
                      backgroundColor: "transparent",
                    }}
                    onClick={() => handleNavClick("/Company/Cert", "회사 소개")}
                  >
                    인증 목록
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    eventKey="품질 관리"
                    style={{
                      color:
                        selectCate === item.categoryName
                          ? item.fontColor
                          : "inherit",
                      backgroundColor: "transparent",
                    }}
                    onClick={() =>
                      handleNavClick("/Company/Quality", "회사 소개")
                    }
                  >
                    품질 관리
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav.Item>
            )
          ) : (
            <Nav.Link
              key={item.label}
              eventKey={item.text}
              onClick={() => handleNavClick(item.link, item.text)}
              style={{
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              {item.text}
            </Nav.Link>
          )
        )}
      </ScrollableNav>
    </ScrollContainer>
  )
}
