import React from "react"
import { Button, Card } from "react-bootstrap"
import styled from "styled-components"
import MyOrderCard from "./MyOrderCard"
import { useNavigate } from "react-router-dom"

const OrderCardWrapper = styled(Card)`
  border: 1px solid #e0e0e0;
  padding: 24px 24px 16px 24px;
  margin-bottom: 16px;
`

const OrderHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OrderDate = styled(Card.Title)`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: pre-line;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`

const NoOrdersMessage = styled.div`
  text-align: center;
  font-size: 16px;
  color: #757575;
  margin-top: 20px;
`

const MyOrderList = ({ orders }) => {
  const navi = useNavigate()
  const formatOrderDate = (dateString) => {
    const date = new Date(dateString)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}.${month}.${day}`
  }

  if (!orders || orders.length === 0) {
    return <NoOrdersMessage>현재 주문이 없습니다.</NoOrdersMessage>
  }

  return (
    <OrderCardWrapper>
      <OrderHeader>
        <OrderDate>{formatOrderDate(orders.orderDate)} 주문</OrderDate>
        <Button
          variant="link"
          onClick={() => navi(`/MyPage/Orders/Detail`, { state: { orders } })}
        >
          주문 상세보기
        </Button>
      </OrderHeader>
      {orders.orderItems.map((orderItem, i) => (
        <MyOrderCard key={i} order={orders} orderItem={orderItem} />
      ))}
    </OrderCardWrapper>
  )
}

export default MyOrderList
