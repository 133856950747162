import { Button, Card } from "react-bootstrap"
import { client } from "../../../../API/api"

export const ShippingCard = ({
  item,
  index,
  handleEditAdd,
  handleUpdate,
  setRegion,
  handleCloseModal,
  setIsDefaultDeliverChecked,
}) => {
  const handleDelete = () => {
    const fetchData = async () => {
      try {
        await client.delete(`/Address/deleteAddress?id=${item.id}`)
        handleUpdate(false)
      } catch (error) {
        alert(error.response.data.message)
      }
    }
    fetchData()
    handleUpdate()
  }

  const handleSelectRegion = () => {
    setRegion(item)
    setIsDefaultDeliverChecked(item.isDefault)
    handleCloseModal()
  }

  // 번호 포맷 함수
  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "" // phoneNumber가 없을 경우 빈 문자열 반환
    }
    return phoneNumber
      .replace(/[^0-9]/g, "")
      .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
  }

  return (
    <Card style={{ marginTop: "10px", padding: "15px" }}>
      <div>
        <span
          style={{ marginRight: "5px", fontWeight: "700", fontSize: "1rem" }}
        >
          {item.alias}
        </span>
        {item.isDefault && (
          <span
            style={{
              border: "solid 1px gray",
              fontSize: "0.7rem",
              borderRadius: "10px",
              padding: "1px 5px",
            }}
          >
            기본배송지
          </span>
        )}
        <Button
          variant={"link"}
          style={{
            paddingTop: "0px",
            right: "10px",
            position: "absolute",
            display: "inline-block",
          }}
          onClick={handleDelete}
        >
          삭제
        </Button>
      </div>
      <div
        style={{
          marginBottom: "5px",
          fontWeight: "700",
          fontSize: "1rem",
        }}
      >
        {item.customerName}
      </div>

      <div
        style={{
          wordWrap: "break-word", // Add this to ensure long text wraps
          whiteSpace: "normal",
        }}
      >
        {item.address}, {item.detailAddress}
      </div>
      <div>{formatPhoneNumber(item.phoneNumber)}</div>
      <div style={{ marginTop: "10px", display: "flex" }}>
        <Button
          size={"sm"}
          variant={"outline-primary"}
          onClick={() => {
            handleEditAdd("edit", index)
          }}
        >
          수정
        </Button>
        <Button
          style={{ marginLeft: "auto", display: "flex" }}
          size={"sm"}
          onClick={handleSelectRegion}
        >
          배송지 선택
        </Button>
      </div>
    </Card>
  )
}
