import React from "react"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Spinner } from "react-bootstrap"

// 전체 페이지를 회색 배경으로 덮기 위한 스타일
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.7); /* 반투명 회색 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999; /* 로딩 화면이 모든 콘텐츠 위에 표시되도록 */
`

const LoadingScreen = styled.div`
  text-align: center;
`

const StyledSpinner = styled(Spinner)`
  color: #007bff; /* 파란색 스피너 */
`

function LoadingPage() {
  return (
    <Overlay>
      <LoadingScreen>
        <StyledSpinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </StyledSpinner>
      </LoadingScreen>
    </Overlay>
  )
}

export default LoadingPage
