import React from "react"
import LoginTest from "./LoginTest"
import styled from "styled-components"

const LoginBox = styled.div`
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 1000px;
  margin: 0 auto;
  height: 60vh;
  margin-bottom: 15px;

  @media (max-width: 480px) {
    box-shadow: none;
    background-color: white;
  }
`
const LoginForm = () => (
  <div>
    <LoginBox>
      <h2
        style={{
          fontSize: "30px",
          color: "#333",
          marginBottom: "50px",
          marginTop: "50px",
        }}
      >
        로그인
      </h2>

      <LoginTest />
    </LoginBox>
  </div>
)

export default LoginForm
