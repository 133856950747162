import React, { useEffect, useState } from "react"
import imageCompression from "browser-image-compression"
import { Button, Input, Spinner } from "reactstrap"
import { CaretDownFill, CaretUpFill, XLg } from "react-bootstrap-icons"

const DetailImagesAddComponent = ({ images, setImages, imageUrls, mode }) => {
  const [previewUrls, setPreviewUrls] = useState(imageUrls || []) // 서버에서 가져온 URL을 기본값으로 설정
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    // 서버에서 이미지가 없으면 Blob으로 처리
    if (images.length > 0) {
      const urls = images.map((image) =>
        image instanceof File ? URL.createObjectURL(image) : image.url
      )
      console.log("urls", urls)
      setPreviewUrls(urls)

      // cleanup 함수로 Blob 해제
      return () =>
        urls.forEach((url) => {
          if (typeof url === "string" && url.startsWith("blob:")) {
            URL.revokeObjectURL(url)
          }
        })
    }
  }, [images])

  const handleImageChange = async (e) => {
    const files = Array.from(e.target.files)

    if (files.length > 0) {
      setLoading(true)

      try {
        const compressedFiles = await Promise.all(
          files.map(async (file) => {
            const fileExtension = file.name.split(".").pop().toLowerCase()
            if (fileExtension === "webp") {
              return file
            } else {
              const options = {
                maxSizeMB: 2,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
                fileType: "image/webp",
              }
              const compressedFile = await imageCompression(file, options)

              const newFileName =
                file.name.split(".").slice(0, -1).join(".") + ".webp"
              return new File([compressedFile], newFileName, {
                type: "image/webp",
              })
            }
          })
        )

        const newPreviewUrls = compressedFiles.map((file) =>
          URL.createObjectURL(file)
        )

        setImages((prevImages) => [...prevImages, ...compressedFiles])
        setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls])
      } catch (error) {
        console.error("Image compression failed:", error)
      } finally {
        setLoading(false)
      }
    } else {
      console.error("No files selected or file name is undefined.")
    }
  }

  const handleImageDelete = (index) => {
    setImages((prevImages) => {
      const updatedImages = [...prevImages]
      updatedImages.splice(index, 1)
      return updatedImages
    })
    setPreviewUrls((prevUrls) => {
      const updatedUrls = [...prevUrls]
      updatedUrls.splice(index, 1)
      return updatedUrls
    })
  }

  const handleMoveUp = (index) => {
    if (index === 0) return
    setImages((prevImages) => {
      const updatedImages = [...prevImages]
      const [movedImage] = updatedImages.splice(index, 1)
      updatedImages.splice(index - 1, 0, movedImage)
      return updatedImages
    })
    setPreviewUrls((prevUrls) => {
      const updatedUrls = [...prevUrls]
      const [movedUrl] = updatedUrls.splice(index, 1)
      updatedUrls.splice(index - 1, 0, movedUrl)
      return updatedUrls
    })
  }

  const handleMoveDown = (index) => {
    if (index === previewUrls.length - 1) return
    setImages((prevImages) => {
      const updatedImages = [...prevImages]
      const [movedImage] = updatedImages.splice(index, 1)
      updatedImages.splice(index + 1, 0, movedImage)
      return updatedImages
    })
    setPreviewUrls((prevUrls) => {
      const updatedUrls = [...prevUrls]
      const [movedUrl] = updatedUrls.splice(index, 1)
      updatedUrls.splice(index + 1, 0, movedUrl)
      return updatedUrls
    })
  }

  return (
    <div>
      <Input
        type="file"
        onChange={handleImageChange}
        multiple
        disabled={loading}
      />
      {loading ? (
        <Spinner color="primary" />
      ) : (
        <div style={{ overflowX: "auto" }}>
          {previewUrls.map((url, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                marginTop: "10px",
              }}
            >
              <img
                src={url}
                width="200px"
                alt={`Preview ${index}`}
                style={{ marginBottom: "10px", marginRight: "10px" }}
              />
              <div>
                {index !== 0 && (
                  <Button
                    onClick={() => handleMoveUp(index)}
                    style={{ marginRight: "5px", display: "flex" }}
                  >
                    <CaretUpFill />
                  </Button>
                )}

                <Button
                  onClick={() => handleImageDelete(index)}
                  color="danger"
                  style={{
                    marginRight: "5px",
                    display: "flex",
                    marginTop: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <XLg />
                </Button>
                {index !== previewUrls.length - 1 && (
                  <Button
                    onClick={() => handleMoveDown(index)}
                    style={{ marginRight: "5px", display: "flex" }}
                  >
                    <CaretDownFill />
                  </Button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default DetailImagesAddComponent
