import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import ShowItem from "./ShowItem"
import AddItem from "./AddItem/AddItem"
import { ManageProductGroup } from "./AddItem/ManageProductGroup/ManageProductGroup"

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`

const TabButton = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  color: ${({ active }) => (active ? "#fff" : "#555")};
  background-color: ${({ active }) =>
    active ? "#007bff" : "transparent"}; /* none을 transparent로 수정 */
  font-weight: ${({ active }) => (active ? "bold" : "normal")};

  &:hover {
    color: ${({ active }) => (active ? "#fff" : "#007bff")};
  }
`

const ContentContainer = styled.div`
  padding: 20px;
`
function ManageItemMenu() {
  const location = useLocation()
  const navigate = useNavigate()
  const activeTab = location.pathname.split("/").pop() || "ShowItem"

  const handleTabChange = (tab) => navigate(`/Admin/ManageItemMenu/${tab}`)

  const renderContent = () => {
    if (activeTab === "ShowItem") {
      return <ShowItem />
    } else if (activeTab === "AddItem") {
      return <AddItem />
    } else if (activeTab === "QualityManage") {
      return <ManageProductGroup />
    }
  }
  return (
    <div className={"container"} style={{ maxWidth: "70%", margin: "0 auto" }}>
      <TabContainer>
        <TabButton
          active={activeTab === "ShowItem"}
          onClick={() => handleTabChange("ShowItem")}
        >
          상품 관리
        </TabButton>
        <TabButton
          active={activeTab === "AddItem"}
          onClick={() => handleTabChange("AddItem")}
        >
          상품 추가
        </TabButton>
        <TabButton
          active={activeTab === "QualityManage"}
          onClick={() => handleTabChange("QualityManage")}
        >
          그룹관리
        </TabButton>
      </TabContainer>
      <ContentContainer>{renderContent()}</ContentContainer>
      {/*<div style={{ flex: "0 0 25%" }}>*/} {/*  /!*<AddItem />*!/*/}{" "}
      {/*</div>*/}
    </div>
  )
}

export default ManageItemMenu
