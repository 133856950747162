import React from "react"
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts"

const data = [
  {
    name: "1월",
    생산: 40,
    불량: 24,
  },
  {
    name: "2월",
    생산: 30,
    불량: 13,
  },
  {
    name: "3월",
    생산: 20,
    불량: 9,
  },
  {
    name: "4월",
    생산: 27,
    불량: 3,
  },
  {
    name: "5월",
    생산: 89,
    불량: 48,
  },
  {
    name: "6월",
    생산: 69,
    불량: 38,
  },
  {
    name: "7월",
    생산: 49,
    불량: 4,
  },
  {
    name: "8월",
    생산: 49,
    불량: 3,
  },
  {
    name: "9월",
    생산: 50,
    불량: 4,
  },
  {
    name: "10월",
    생산: 30,
    불량: 4,
  },
  {
    name: "11월",
    생산: 34,
    불량: 3,
  },
  {
    name: "12월",
    생산: 19,
    불량: 23,
  },
]

const BarChartComponent = () => (
  <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
    <BarChart
      data={data}
      width={730}
      height={350}
      margin={{
        top: 5,
        right: 10,
        left: 10,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar
        dataKey="생산"
        fill="#8884d8"
        activeBar={<Rectangle fill="pink" stroke="blue" />}
      />
      <Bar
        dataKey="불량"
        fill="red"
        activeBar={<Rectangle fill="gold" stroke="purple" />}
      />
    </BarChart>
  </ResponsiveContainer>
)

export default BarChartComponent
