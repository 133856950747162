import React, { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import styled from "styled-components"

export default function Withdraw() {
  const [showModal, setShowModal] = useState(false)

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)
  const handleWithdraw = () => {
    // Handle the withdrawal logic here
    alert("회원 탈퇴가 완료되었습니다.")
    setShowModal(false)
  }

  return (
    <Container>
      <Heading>회원 탈퇴</Heading>
      <Text>
        가입된 회원정보가 모두 삭제됩니다. 작성하신 게시물은 삭제되지 않습니다.
        <br />
        회원 탈퇴를 진행하시겠습니까?
      </Text>
      <WithdrawButton size="lg" onClick={handleShow}>
        회원탈퇴
      </WithdrawButton>

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>회원 탈퇴 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          정말로 탈퇴하시겠습니까? 이 작업은 되돌릴 수 없습니다.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            취소
          </Button>
          <Button variant="danger" onClick={handleWithdraw}>
            탈퇴
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

// Styled-components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const Heading = styled.h2`
  font-weight: 700;
  margin-bottom: 20px;
`

const Text = styled.p`
  white-space: pre-wrap;
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.5;
`

const WithdrawButton = styled(Button)`
  background-color: #dc3545;
  border-color: #dc3545;
  padding: 10px 20px;
`
