import { Card } from "react-bootstrap"
import { ShippingAddress } from "./ShippingAddress"

export const ShippingAddressManage = () => {
  return (
    <Card
      style={{
        width: "510px",
        padding: "0px",
        maxHeight: "750px",
      }}
      className="container"
    >
      <ShippingAddress />
    </Card>
  )
}
