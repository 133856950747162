import React, { useState } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"

const Container = styled.div`
  margin: 5%;
  border: 1px solid #ccc;
  padding: 2%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`

const StyledTable = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;

  th {
    width: 20%;
    text-align: left;
    vertical-align: middle;
  }

  td {
    width: 80%;
  }
`

const InputField = styled(Form.Control)`
  width: 100%;
  margin-bottom: 10px;
`

const TextArea = styled(Form.Control)`
  width: 100%;
  height: 200px;
  margin-bottom: 10px;
`

const PhoneField = styled.div`
  display: flex;
  align-items: center;

  select,
  input {
    margin-right: 5px;
    flex: 1;
  }
`

const FileInput = styled(Form)`
  margin-bottom: 10px;
`

function InquiryForm() {
  const [formData, setFormData] = useState({
    name: "", // 계정에서 가져올 것.
    number: "",
    title: "",
    content: "",
    phonePrefix: "010", // 휴대폰 앞자리를 별도로 관리
    phone1: "",
    phone2: "",
    email: "",
    emailAccess: true,
    file: null,
  })

  const handleChange = (e) => {
    const { id, value, files } = e.target
    if (id === "file") {
      const file = files[0]
      if (file && !["image/png", "image/jpeg"].includes(file.type)) {
        alert("PNG 또는 JPEG 이미지만 첨부 가능합니다.")
        e.target.value = "" // 파일 입력 필드 초기화
      } else {
        setFormData({ ...formData, file })
      }
    } else if (id.startsWith("phone")) {
      const newFormData = { ...formData, [id]: value }
      newFormData.phone =
        newFormData.phonePrefix + newFormData.phone1 + newFormData.phone2
      setFormData(newFormData)
    } else {
      setFormData({ ...formData, [id]: value })
    }
  }

  const handleSelectChange = (e) => {
    const { value } = e.target
    setFormData({
      ...formData,
      phonePrefix: value,
      phone: value + formData.phone1 + formData.phone2,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(formData) // 실제론 이게 axios.
  }

  return (
    <Container>
      <h4>1:1 문의 작성하기</h4>
      <hr />
      <Form onSubmit={handleSubmit}>
        <StyledTable>
          <tbody>
            <tr>
              <th>이름</th>
              <td>
                <InputField
                  id="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="이건 계정에서 가져올 것."
                  disabled
                />
              </td>
            </tr>
            <tr>
              <th>주문번호</th>
              <td>
                <InputField
                  id="number"
                  type="number"
                  value={formData.number}
                  onChange={handleChange}
                  placeholder="적지 않으면 상품 문의가 아닌것으로 간주합니다."
                />
              </td>
            </tr>
            <tr>
              <th>제목</th>
              <td>
                <InputField
                  id="title"
                  type="text"
                  required
                  value={formData.title}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td>
                <TextArea
                  id="content"
                  as="textarea"
                  required
                  value={formData.content}
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              <th>휴대폰</th>
              <td>
                <PhoneField>
                  <Form.Control
                    as="select"
                    onChange={handleSelectChange}
                    value={formData.phonePrefix}
                  >
                    <option value="010">010</option>
                    <option value="011">011</option>
                    <option value="016">016</option>
                    <option value="017">017</option>
                    <option value="018">018</option>
                    <option value="019">019</option>
                    <option value="0130">0130</option>
                    <option value="0505">0505</option>
                  </Form.Control>
                  <span>-</span>
                  <Form.Control
                    id="phone1"
                    type="tel"
                    required
                    maxLength="4"
                    value={formData.phone1}
                    onChange={handleChange}
                  />
                  <span>-</span>
                  <Form.Control
                    id="phone2"
                    type="tel"
                    required
                    maxLength="4"
                    value={formData.phone2}
                    onChange={handleChange}
                  />
                </PhoneField>
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                <Form.Group as={Row} className="align-items-center">
                  <Col>
                    <InputField
                      type="email"
                      id="email"
                      required
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Check
                      type="radio"
                      name="emailAccess"
                      label="받음"
                      checked={formData.emailAccess === true}
                      onChange={() =>
                        setFormData({ ...formData, emailAccess: true })
                      }
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Check
                      type="radio"
                      name="emailAccess"
                      label="받지 않음"
                      checked={formData.emailAccess === false}
                      onChange={() =>
                        setFormData({ ...formData, emailAccess: false })
                      }
                    />
                  </Col>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <th>파일 첨부</th>
              <td>
                <FileInput
                  type="file"
                  id="file"
                  accept="image/png, image/jpeg"
                  onChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </StyledTable>
        <Button type="submit" variant="primary" block>
          제출
        </Button>
      </Form>
    </Container>
  )
}

export default InquiryForm
