import React, { useState } from "react"
import BarChartComponent from "./Analysis/BarChartComponent"
import DataTable from "./Analysis/DataTable"
import StandardDropdown from "./DropDown/StandardDropdown"
import YearDropdown from "./DropDown/YearDropdown"
import MonthDropdown from "./DropDown/MonthDropdown"

const data = [
  {
    name: "1월",
    생산: 40,
    불량: 24,
  },
  {
    name: "2월",
    생산: 30,
    불량: 13,
  },
  {
    name: "3월",
    생산: 20,
    불량: 9,
  },
  {
    name: "4월",
    생산: 27,
    불량: 3,
  },
  {
    name: "5월",
    생산: 89,
    불량: 48,
  },
  {
    name: "6월",
    생산: 69,
    불량: 38,
  },
  {
    name: "7월",
    생산: 49,
    불량: 4,
  },
  {
    name: "8월",
    생산: 49,
    불량: 3,
  },
  {
    name: "9월",
    생산: 50,
    불량: 4,
  },
  {
    name: "10월",
    생산: 30,
    불량: 4,
  },
  {
    name: "11월",
    생산: 34,
    불량: 3,
  },
  {
    name: "12월",
    생산: 19,
    불량: 23,
  },
]

export default function Productiondefective() {
  const today = new Date()
  const [year, setYear] = useState(today.getFullYear())
  const [standardData, setStandardData] = useState("월")
  const startYear = 2020
  const [month, setMonth] = useState(today.getMonth())
  return (
    <div className="container">
      <div style={{ textAlign: "center" }}>
        <h2>생산/ 불량 개수</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            verticalAlign: "center",
            alignItems: "center",
          }}
        >
          <span>기준:</span>
          <StandardDropdown
            standardData={standardData}
            setStandardData={setStandardData}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center", // 이 줄을 추가
          }}
        >
          <div style={{ width: "800px", height: "500px" }}>
            <BarChartComponent />
          </div>

          <div>
            <div
              style={{
                width: "700px",
                display: "flex",
                verticalAlign: "center",
                alignItems: "center",
              }}
            >
              <YearDropdown
                year={year}
                setYear={setYear}
                startYear={startYear}
              />
              {standardData === "일" && (
                <MonthDropdown month={month} setMonth={setMonth} />
              )}
            </div>
            <DataTable data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}
