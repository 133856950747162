import styled from "styled-components"
import { Button, Dropdown, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"

// 필터 섹션 컨테이너
export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`

// 레이블
export const FilterLabel = styled.label`
  font-weight: bold;
  margin-right: 10px;
`

// 체크박스 레이아웃
export const FormCheckRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`

// 필터 열
export const FilterRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  > * {
    margin-right: 10px;
  }
`

// 드롭다운 버튼
export const DropdownButton = styled(Dropdown.Toggle)`
  background-color: #007bff;
  border: none;

  &:hover {
    background-color: #0056b3;
  }
`

// 액션 버튼
export const ActionButton = styled(Button)`
  background-color: #28a745;
  border: none;
  margin-right: 5px;

  &:hover {
    background-color: #218838;
  }
`

// DatePicker 스타일링
export const StyledDatePicker = styled(DatePicker)`
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
`

export const PayMethodCheckbox = styled(Form.Check)`
  margin-left: 10px;
`
