import React from "react"
import { AdminOrderFilter } from "./AdminOrderFilter"
import { AdminOrderTable } from "./AdminOrderTable"

export function AdminOrder() {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2>관리자 대시보드</h2>
      </div>
      <AdminOrderFilter />
      <AdminOrderTable />
    </div>
  )
}
