import React, { useState } from "react"
import Intro from "./CompanyIntro"
import CertAndQuality from "./CertAndQuality"

import "./CSS/Company.css"
import { useNavigate } from "react-router-dom"
import Nav from "react-bootstrap/Nav"
import styled from "styled-components"
import { CompanyCert } from "."

const CompanyContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  @media (max-width: 480px) {
    width: 100%;
  }
`

const Company = ({ mode }) => {
  const [detailState, setDetailState] = useState(true)
  const navi = useNavigate()

  const menu = [
    {
      key: "Intro",
      title: "회사 소개",
      component: <Intro />,
      isThere: mode === 1 ? true : false,
      link: "/Company/Intro",
    },
    {
      key: "Cert",
      title: "인증 목록",
      component: <CompanyCert mode={"cert"} />,
      // <CertAndQuality mode={'Cert'} />,
      isThere: mode === 2 ? true : false,
      link: "/Company/Cert",
    },
    {
      key: "Quality",
      title: "품질 관리",
      component: <CertAndQuality mode={"quan"} />,
      isThere: mode === 3 ? true : false,
      link: "/Company/Quality",
    },
  ]
  const IntroDetailMenu = [
    {
      key: "Mualchi",
      title: "멸치명가 멸치",

      isThere: mode === 5 ? true : false,
    },
    {
      key: "GanJang",
      title: "삼천포 어간장",

      isThere: mode === 6 ? true : false,
    },
    {
      key: "AcJut",
      title: "명치명가 眞액젓",
      isThere: mode === 7 ? true : false,
    },
    {
      key: "Dasi",
      title: "멸치명가 다시팩",
      isThere: mode === 8 ? true : false,
    },
    {
      key: "Etc",
      title: "멸치명가 기타건어물",
      isThere: mode === 9 ? true : false,
    },
    {
      key: "GiftSet",
      title: "원정푸드 선물세트",
      isThere: mode === 10 ? true : false,
    },
  ]

  const pathname = window.location.pathname // '/Company/Intro'
  const lastSegment = pathname.split("/").pop() // 'Intro'

  return (
    <CompanyContainer>
      <div style={{ width: "100%" }}>
        {/* 상단 메뉴들 ex)회사 소개 / 인증 목록/ 품질 관리 */}
        <Nav
          fill
          variant="underline"
          style={{
            width: "100%",
            borderBottom: "1px solid black",
            marginBottom: "10px",
            justifyContent: "center",
          }}
        >
          {menu.map((item) => (
            <Nav.Item key={item.key}>
              <Nav.Link
                eventKey={item.key}
                active={lastSegment === item.key}
                onClick={() => {
                  navi(item.link)
                  setDetailState(item.key === "Intro")
                }}
              >
                <p
                  style={{ color: "black", fontSize: "15px", fontWeight: 700 }}
                >
                  {item.title}
                </p>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div style={{ width: "100%" }}>
        {/* 세부 메뉴- 멸치명가 멸치/ 삼천포 어간장... */}
        {lastSegment === "Intro" ? (
          <Nav
            fill
            style={{
              width: "100%",
              marginBottom: "10px",
              justifyContent: "center",
              fontSize: "13px",
            }}
          >
            {IntroDetailMenu.map((item, idx) => (
              <Nav.Item key={item.key}>
                <Nav.Link
                  onClick={() =>
                    navi("/Company/Intro", { state: { scrollTo: idx } })
                  }
                >
                  <p style={{ color: "black" }}>{item.title}</p>
                </Nav.Link>
              </Nav.Item>
            ))}
            <br />
          </Nav>
        ) : null}
        {/* 하단 아이템 본문 */}
        {menu.filter((item) => item.isThere).map((item) => item.component)}
      </div>
    </CompanyContainer>
  )
}

export default Company
