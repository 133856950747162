import React from "react"
import Nav from "react-bootstrap/Nav"
import styled from "styled-components"

const FooterContainer = styled.footer`
  margin-left: 4%;
  margin-right: 4%;
  margin-bottom: 70px;

  @media (max-width: 1200px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  @media (max-width: 768px) {
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (max-width: 480px) {
    margin-left: 2%;
    margin-right: 2%;
  }
`

const Footer = () => {
  const footerBottomStyle = {
    textAlign: "center",
    fontSize: "10px",
  }

  return (
    <div>
      <FooterContainer id={"bottom-section"}>
        <Nav className="justify-content-center">
          {/* id:11 */}
          <Nav.Item>
            <Nav.Link style={{ color: "black", fontSize: "14px" }}>
              개인정보 취급 방침
            </Nav.Link>
          </Nav.Item>
          {/* id:12 */}
          <Nav.Item>
            <Nav.Link style={{ color: "black", fontSize: "14px" }}>
              이용약관
            </Nav.Link>
          </Nav.Item>
          {/* id:13 */}
          <Nav.Item>
            <Nav.Link style={{ color: "black", fontSize: "14px" }}>
              FAQ
            </Nav.Link>
          </Nav.Item>
          {/* id:14 */}
          <Nav.Item>
            <Nav.Link style={{ color: "black", fontSize: "14px" }}>
              컨설팅 및 입점안내
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div id="footerBottom" style={footerBottomStyle}>
          <div>
            <div>
              <p style={{ fontSize: "24px" }}>고객센터 055-833-0337</p>
              <span>
                (월 ~ 금 : 10 ~ 17시 / 토, 일요일, 공휴일 : 휴무 / 점심시간 :
                11시 30분 ~ 13시)
              </span>
            </div>
            <div>
              <address>
                <p>
                  법인명 : (사)원정푸드 주식회사 / 대표자 : 정연실
                  <br />
                  사업자 등록 번호 : 5078116749 | 통신판매업 번호 :
                  2022-경남사천-0257
                  <br />
                  주소 : 경상남도 사천시 청송길 34-56 (우 : 52563)
                  <br />
                  문의 : khsminam89@naver.com
                </p>
                <p>copyright(C) by wonjungFood. All rights reserved.</p>
              </address>
            </div>
          </div>
        </div>
      </FooterContainer>
    </div>
  )
}

export default Footer
