import React, { useEffect } from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"

export default function MainPopup({ setShowMainPop, popupUrl }) {
  useEffect(() => {
    const homeVisited = localStorage.getItem("HOME_VISITED")
    if (homeVisited && new Date() < new Date(homeVisited)) {
      setShowMainPop(false)
    }
  }, [setShowMainPop])

  const closeModal = () => setShowMainPop(false)

  const closeTodayModal = () => {
    const expires = new Date()
    expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000) // 24시간 후 설정
    localStorage.setItem("HOME_VISITED", expires.toISOString()) // ISO 문자열로 저장
    closeModal()
  }

  return (
    <StyledModal show onHide={closeModal} centered>
      <ImgStyle>
        <img src={popupUrl} alt="공지" />
        <CloseStyle>
          <Close onClick={closeTodayModal}>오늘 하루 열지 않기</Close>
          <Close onClick={closeModal}>닫기</Close>
        </CloseStyle>
      </ImgStyle>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  .modal-content {
    border: none;
    padding: 0;
    margin: 0;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const ImgStyle = styled.div`
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`

const CloseStyle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #282828;
  padding: 15px;
  border-radius: 0 0 15px 15px;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
`

const Close = styled.span`
  cursor: pointer;
`
