import { Button } from "react-bootstrap"
import { PencilFill, Trash3Fill } from "react-bootstrap-icons"
import React, { useState } from "react"
import { client } from "../../../../API/api"
import ModalComponent from "../../../../Common/ModalComponent"

export const GroupProductCardItem = ({
  product,
  handleUpdate, // 삭제 후 리스트 갱신을 위한 함수
  groupId,
}) => {
  const [newName, setNewName] = useState(product.optionName)
  const [EditMode, setEditMode] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false) // 모달 상태 관리
  const [optionToDelete, setOptionToDelete] = useState(null) // 삭제할 항목 저장

  console.log("그룹아이디:", groupId, "프로덕트 id:", product.productId)
  const confirmDelete = async () => {
    try {
      if (optionToDelete) {
        await client.delete(
          `ProductOption/deleteOption?optionId=${optionToDelete.optionId}`
        )
        await client.put(
          `Product/updateGroup?groupId=${groupId}&productId=${product.productId}`
        )
        handleUpdate() // 삭제 후 리스트 갱신
      }
    } catch (error) {
      alert(error.response.data.message)
    } finally {
      setShowDeleteModal(false)
      setOptionToDelete(null) // 초기화
    }
  }

  const handleDelete = () => {
    setOptionToDelete(product) // 삭제할 항목 설정
    setShowDeleteModal(true) // 모달 열기
  }

  const handleEditName = async () => {
    try {
      await client.put(
        `ProductOption/updateOption?optionId=${product.optionId}`,
        { optionName: newName }
      )
      setEditMode(false)
      handleUpdate()
    } catch (error) {
      // error.response가 있는지 확인하고, 없을 경우 일반적인 에러 메시지를 표시
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message)
      } else {
        console.log(error)
        alert("이름 수정 중 오류가 발생했습니다. 다시 시도해주세요.")
      }
    }
  }

  return (
    <>
      <tr key={product.id}>
        <td
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {EditMode ? (
            <>
              <input
                type={"text"}
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    // 엔터키가 눌렸을 때 실행할 로직
                    handleEditName()
                  }
                }}
              />
              <div>
                <Button
                  size={"sm"}
                  style={{ marginRight: "10px" }}
                  onClick={handleEditName}
                >
                  {" "}
                  확인
                </Button>
                <Button
                  size={"sm"}
                  variant={"outline-secondary"}
                  onClick={() => {
                    setEditMode(false)
                    setNewName(product.optionName)
                  }}
                >
                  취소
                </Button>
              </div>
            </>
          ) : (
            <>
              <span>{product.optionName}</span>
              <Button
                variant="outline-dark"
                size="sm"
                onClick={() => setEditMode(true)}
              >
                <PencilFill />
              </Button>
            </>
          )}
        </td>

        <td>
          <span>{product.productName}</span>
        </td>

        <td>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => handleDelete(product.optionId)}
          >
            <Trash3Fill className="h-4 w-4" />
          </Button>
        </td>
      </tr>

      {/* 모달 컴포넌트 */}
      <ModalComponent
        show={showDeleteModal}
        title={"삭제"}
        body={
          <>
            {optionToDelete
              ? `${optionToDelete.optionName} 항목을 정말 삭제하시겠습니까?`
              : "삭제할 항목이 없습니다."}
          </>
        }
        handleClose={() => setShowDeleteModal(false)}
        onConfirm={confirmDelete} // 삭제 확정 시 호출될 함수
      />
    </>
  )
}
