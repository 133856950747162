import React from "react"
import styled from "styled-components"
import { useRecoilValue } from "recoil"
import { category } from "../../../App"
import { useNavigate } from "react-router-dom"
import 건어물 from "./Img/건어물.jpg"
import 다시팩 from "./Img/다시팩.jpg"
import 멸치 from "./Img/멸치.jpg"
import 선물세트 from "./Img/선물세트.jpg"
import 액젓 from "./Img/액젓.jpg"
import 어간장 from "./Img/어간장.jpg"

// 이미지 파일과 카테고리 이름 매핑
const categoryImages = {
  건어물,
  다시팩,
  멸치,
  선물세트,
  액젓,
  어간장,
}

// Styled components
const CategoryContainer = styled.div`
  background-color: white;
  border-radius: 2%;
  margin: 2%;
  padding: 2%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1%;
  overflow-x: auto;

  @media (max-width: 480px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
  }
`

const CategoryItem = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Includes padding and border in element's total width and height */
  margin-bottom: 10px;
`

const FirstCategoryImage = styled.div`
  border: 1px solid #eee;
  border-radius: 10%;
  align-items: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 10px;
  width: 100px; /* Added width */
  height: 100px; /* Added height */
  overflow: hidden; /* To ensure image fits within the bounds */
  display: flex;
  justify-content: center;
  align-items: center;
`

const CategoryName = styled.span`
  font-size: 13px;
  font-weight: bold;
  display: block;
`

// Component
const FirstCategory = () => {
  const data = useRecoilValue(category)
  const navi = useNavigate()
  console.log(data)
  return (
    <CategoryContainer>
      {data.map((item) => (
        <CategoryItem
          key={item.id}
          onClick={() => navi(`/category/${item.id}`)}
        >
          <FirstCategoryImage>
            <img
              src={categoryImages[item.categoryName]}
              alt={item.categoryName}
              width={"100%"}
              height={"100%"}
            />
          </FirstCategoryImage>
          <CategoryName>{item.categoryName}</CategoryName>
        </CategoryItem>
      ))}
    </CategoryContainer>
  )
}

export default FirstCategory
