import React from "react"
import { useRecoilState } from "recoil"
import { errorState, loadingState } from "./App"
import { useNavigate } from "react-router-dom"
import LoadingPage from "./Component/LoadingPage"
import ErrorPage from "./Component/ErrorPage"

const ErrorBoundary = ({ children }) => {
  const navigate = useNavigate()
  const [error] = useRecoilState(errorState)
  const [loading] = useRecoilState(loadingState)

  if (loading) {
    return <LoadingPage />
  }

  if (error) {
    return <ErrorPage />
  }

  return <>{children}</>
}

export default ErrorBoundary
