import React from "react"
import * as S from "./AdminOrderFilter.style"
import { Dropdown } from "react-bootstrap"
import "react-datepicker/dist/react-datepicker.css"

export function AdminOrderFilter() {
  return (
    <S.FilterSection>
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <S.FormCheckRow>
          <S.FilterLabel>결제 방법</S.FilterLabel>
          <S.PayMethodCheckbox type="checkbox" label="전체" />
          <S.PayMethodCheckbox type="checkbox" label="카드결제" />
          <S.PayMethodCheckbox type="checkbox" label="가상계좌" />
          <S.PayMethodCheckbox type="checkbox" label="카카오페이" />
          <S.PayMethodCheckbox type="checkbox" label="토스" />
        </S.FormCheckRow>
      </div>

      <S.FilterRow>
        <S.FilterLabel>기간</S.FilterLabel>
        주문일:
        <S.StyledDatePicker placeholderText="시작일" />
        <S.StyledDatePicker placeholderText="종료일" />
        <S.ActionButton>오늘</S.ActionButton>
        <S.ActionButton>당월</S.ActionButton>
        <S.ActionButton>1년</S.ActionButton>
        <S.ActionButton>전체</S.ActionButton>
      </S.FilterRow>

      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
      >
        <S.FilterLabel>배송 주문 상태</S.FilterLabel>
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            배송 주문 상태
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#/completed">배송완료</Dropdown.Item>
            <Dropdown.Item href="#/in-progress">배송중</Dropdown.Item>
            <Dropdown.Item href="#/preparing">배송준비중</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <S.ActionButton style={{ marginLeft: "auto" }}>검색</S.ActionButton>
    </S.FilterSection>
  )
}
