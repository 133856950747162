import React, { useState } from "react"
import styled from "styled-components"
import QnaForm from "../ConBox/Common/QnaForm"
import ReviewManage from "./QnaReviewManage/ReviewManage"

export default function QnaReviewManage() {
  const [activeTab, setActiveTab] = useState("QnA")

  const renderContent = () => {
    if (activeTab === "QnA") {
      return <QnaForm isAdmin={true} />
    } else if (activeTab === "Review") {
      return <ReviewManage />
    }
  }

  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <TabContainer>
        <TabButton
          active={activeTab === "QnA"}
          onClick={() => setActiveTab("QnA")}
        >
          Q&A 관리
        </TabButton>
        <TabButton
          active={activeTab === "Review"}
          onClick={() => setActiveTab("Review")}
        >
          리뷰 관리
        </TabButton>
      </TabContainer>
      <ContentContainer>{renderContent()}</ContentContainer>
    </div>
  )
}

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`

const TabButton = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  color: ${({ active }) => (active ? "#fff" : "#555")};
  background-color: ${({ active }) => (active ? "#007bff" : "none")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};

  &:hover {
    color: ${({ active }) => (active ? "#fff" : "#007bff")};
  }
`

const ContentContainer = styled.div`
  padding: 20px;
`
