import React, { useEffect, useRef, useState } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styled from "styled-components"
import { useRecoilValue } from "recoil"
import { adminPage } from "../../../App"
import { client } from "../../API/api"
import { CloseButton } from "react-bootstrap"
import { deleteObject, getStorage, ref } from "firebase/storage"
import "./CertAndQuality.css"

const Image = styled.img`
  max-height: 280px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  outline: none;
  border: ${(props) =>
    props.isSelected
      ? "5px solid #007bff"
      : "none"}; /* 선택된 경우 파란색 테두리 */
  box-shadow: ${(props) =>
    props.isSelected
      ? "0 0 10px rgba(0, 123, 255, 0.5)"
      : "none"}; /* 선택된 경우 그림자 효과 */
  transition:
    border 0.3s ease,
    box-shadow 0.3s ease;
`

const CustomDots = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const DeleteButtonStyled = styled(CloseButton)`
  display: flex;
  z-index: 100; /* 이미지 위에 보이도록 설정 */
`

const ArrowButton = styled.div`
  position: absolute; /* 슬라이더 위로 이동 */
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 50px;
  height: 50px;
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    background: darkgray;
  }
`

const Counter = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 10;
`

const SampleNextArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick} style={{ right: "10px" }}>
    &gt;
  </ArrowButton>
)

const SamplePrevArrow = ({ onClick }) => (
  <ArrowButton onClick={onClick} style={{ left: "10px" }}>
    &lt;
  </ArrowButton>
)

const CertAndQuality = ({ mode }) => {
  const isAdminPage = useRecoilValue(adminPage)
  const [certList, setCertList] = useState([])
  const [selectedCertIndex, setSelectedCertIndex] = useState(0)
  const sliderRef = useRef(null)

  const extractPath = (url) => {
    const regex = /\/o\/(.*?)\?/
    const match = url.match(regex)
    if (match && match[1]) {
      return decodeURIComponent(match[1])
    }
    return null
  }

  const deleteItem = async (certId, photoUrl) => {
    try {
      const storage = getStorage()
      const photoPath = extractPath(photoUrl)
      const imageRef = ref(storage, photoPath)
      await deleteObject(imageRef)
      const response = await client.delete(`Cert/deleteCert?certId=${certId}`)
      alert(response.data)
    } catch (error) {
      console.error("Error deleting certificate:", error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(`Cert/findCertAll?mode=${mode}`)
        if (response.data) {
          setCertList(response.data)
          setSelectedCertIndex(0)
        } else {
          setCertList([])
          setSelectedCertIndex(null)
        }
      } catch (error) {
        console.error("Error fetching certificates:", error)
        setCertList([])
        setSelectedCertIndex(null)
      }
    }
    fetchData()
    setSelectedCertIndex(0)
  }, [mode])

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(5, certList.length),
    autoplay: false,
    centerPadding: "60px",
    slidesToScroll: 1,
    nextArrow: (
      <SampleNextArrow onClick={() => sliderRef.current.slickNext()} />
    ),
    prevArrow: (
      <SamplePrevArrow onClick={() => sliderRef.current.slickPrev()} />
    ),
    appendDots: (dots) => (
      <CustomDots>
        <ul> {dots} </ul>
      </CustomDots>
    ),
    dotsClass: "dots_custom",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div style={{ padding: "2%" }}>
      <div style={{ position: "relative" }} id="certSlide">
        <Counter>
          {selectedCertIndex + 1} / {certList.length}
        </Counter>
        <Slider {...settings} ref={sliderRef} id={"certqual_List"}>
          {certList.length > 0 &&
            certList.map((item, idx) => (
              <div
                key={idx}
                style={{
                  position: "relative",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  src={item.photoUrl}
                  alt="증명서 미리보기"
                  onClick={() => setSelectedCertIndex(idx)}
                  isSelected={selectedCertIndex === idx}
                  style={{ display: "inline-block", justifyContent: "center" }}
                />
                {isAdminPage && (
                  <div
                    style={{
                      display: "inline-block",
                      position: "absolute",
                      marginLeft: "-25px",
                      marginTop: "1px",
                      zIndex: "1000",
                    }}
                  >
                    <CloseButton
                      onClick={() => deleteItem(item.certId, item.photoUrl)}
                    />
                  </div>
                )}
              </div>
            ))}
        </Slider>
        <SamplePrevArrow
          onClick={() => {
            if (selectedCertIndex !== 0) {
              const newIndex = selectedCertIndex - 1
              setSelectedCertIndex(newIndex)
              if (newIndex % 5 === 0) {
                sliderRef.current.slickPrev()
              }
            }
          }}
        />
        <SampleNextArrow
          onClick={() => {
            if (selectedCertIndex !== certList.length - 1) {
              const newIndex = selectedCertIndex + 1
              setSelectedCertIndex(newIndex)
              if (newIndex % 5 === 0) {
                sliderRef.current.slickNext()
              }
            }
          }}
        />
      </div>
      {!isAdminPage && certList.length > 0 && selectedCertIndex !== null && (
        <div style={{ marginTop: "20px", position: "relative" }}>
          <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
            ■ {certList[selectedCertIndex]?.certId}
          </p>
          <p style={{ fontSize: "1rem" }}>
            - {certList[selectedCertIndex]?.text}
          </p>
          <img
            src={certList[selectedCertIndex]?.photoUrl}
            alt="증명서"
            style={{ width: "100%", cursor: "pointer" }}
          />
        </div>
      )}
    </div>
  )
}

export default CertAndQuality
