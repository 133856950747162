import { ShippingHeader } from "./ShippingHeader"
import { Button } from "react-bootstrap"
import { ShippingBody } from "./ShippingBody"
import { useState } from "react"
import { PlusLg } from "react-bootstrap-icons"

export const ShippingAddress = ({
  isPayMode,
  handleCloseModal,
  region,
  setRegion,
  setIsDefaultDeliverChecked,
}) => {
  const [isModified, setIsModified] = useState(false)
  const [selectedIndex, setSelectedIdx] = useState()

  const handleEditAdd = (type, index) => {
    if (type === "add") {
      setSelectedIdx(null)
    } else {
      setSelectedIdx(index)
    }
    setIsModified(true)
  }
  return (
    <div>
      <div style={{ borderBottom: "1px solid #d2d2d2" }}>
        <ShippingHeader
          isModified={isModified}
          setIsModified={setIsModified}
          isPayMode={isPayMode}
          handleCloseModal={handleCloseModal}
        />
      </div>
      <div
        className="container"
        style={{
          overflow: "auto",
          marginTop: "0px",
          maxHeight: "700px",
          minHeight: "500px",
        }}
      >
        <ShippingBody
          isModified={isModified}
          setIsModified={setIsModified}
          selectedIndex={selectedIndex}
          handleEditAdd={handleEditAdd}
          handleCloseModal={handleCloseModal}
          setRegion={setRegion}
          setIsDefaultDeliverChecked={setIsDefaultDeliverChecked}
        />
      </div>
      {!isModified && (
        <Button
          style={{ width: "100%", marginTop: "10px" }}
          variant={"outline-primary"}
          onClick={() => {
            handleEditAdd("add")
          }}
        >
          <p style={{ margin: "5px 0", fontWeight: "700" }}>
            <PlusLg /> 배송지 추가
          </p>
        </Button>
      )}
    </div>
  )
}
