import React, { useEffect, useState } from "react"
import { client } from "../../../../API/api"
import { GroupCard } from "./GroupCard"
import { GroupProductCard } from "./GroupProductCard"

export const ManageProductGroup = () => {
  const [groups, setGroups] = useState([]) // 그룹 리스트 상태
  const [selected, setSelected] = useState(null) // 선택된 그룹 ID
  const [products, setProducts] = useState([]) // 선택된 그룹의 상품 리스트 상태
  const [update, setUpdate] = useState(false)
  const handleUpdate = () => {
    setUpdate(!update)
  }
  console.log("selected:", selected)
  // 처음 로드될 때 그룹 목록 가져오기
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(`ProductGroup/findAllGroup`)
        console.log("group", response.data)
        setGroups(response.data)
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          alert(error.response.data.message)
        } else {
          alert("An error occurred while fetching groups")
        }
        console.log(error)
      }
    }

    fetchData()
  }, [update])

  // 선택된 그룹이 있을 때만 상품 목록 가져오기
  useEffect(() => {
    if (selected !== null) {
      const fetchData = async () => {
        try {
          if (selected.groupId !== undefined) {
            const response = await client.get(
              `ProductOption/findAllOptionsByGroupId?groupId=${selected.groupId}`
            )
            setProducts(response.data)
            console.log(response.data)
          }
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            alert(error.response.data.message)
          } else {
            alert("An error occurred while fetching product options")
          }
          console.log(error)
        }
      }
      if (groups.length !== 0) {
        fetchData()
      }
    }
  }, [selected, update]) // `selected`가 변경될 때만 실행
  console.log(selected)
  return (
    <div>
      <div className="row">
        <GroupCard
          groups={groups}
          setSelected={setSelected}
          handleUpdate={handleUpdate}
        />
        <GroupProductCard
          products={products}
          handleUpdate={handleUpdate}
          selected={selected}
        />
      </div>
    </div>
  )
}
