import React from "react"
import { Dropdown } from "react-bootstrap"

export default function StandardDropdown({ standardData, setStandardData }) {
  return (
    <Dropdown
      style={{
        textAlign: "start",
      }}
    >
      <Dropdown.Toggle variant="dropdown-basic-button" id="dropdown-basic">
        {standardData}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-2" onClick={() => setStandardData("월")}>
          월 기준
        </Dropdown.Item>
        <Dropdown.Item href="#/action-3" onClick={() => setStandardData("일")}>
          일 기준
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
