import React, { useEffect } from "react"
import * as S from "./Header/Header.style"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { selectCategory } from "../App"
import logo from "./Header/Img/원정푸드.jpg"

export default function ErrorPage() {
  const navi = useNavigate()
  const [selectCate, setSelectCate] = useRecoilState(selectCategory)

  useEffect(() => {
    localStorage.removeItem("role")
    localStorage.removeItem("nickname")
    localStorage.removeItem("refreshToken")
    localStorage.removeItem("userId")
    localStorage.removeItem("accessToken")
    localStorage.removeItem("profileUrl")
    localStorage.removeItem("type")
  }, [])

  return (
    <S.ErrorPageContainer>
      <S.HeaderContainer>
        <S.ImageContainer>
          <S.LogoImage
            src={logo}
            alt="원정푸드"
            onClick={() => {
              navi("/")
              setSelectCate("")
            }}
            style={{ cursor: "pointer" }}
          />
        </S.ImageContainer>
      </S.HeaderContainer>
      <S.MessageContainer>
        <S.ErrorMessage>
          서버 통신 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.
        </S.ErrorMessage>
        <S.RedirectButton onClick={() => navi("/")}>
          홈으로 돌아가기
        </S.RedirectButton>
      </S.MessageContainer>
    </S.ErrorPageContainer>
  )
}
