import {
  Button,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { client } from "../../API/api"
import styled from "styled-components"
import ModalComponent from "../../Common/ModalComponent"

const CustomTd = styled.td`
  text-align: center;
  vertical-align: middle;
`
const CustomTr = styled.tr`
  background-color: ${(props) =>
    props.selected ? "#f0f8ff" : "transparent"}; // 선택된 경우 배경색 변경
`

export const ShowItemData = ({
  item,
  mode,
  findCategoryName,
  handleEdit,
  handleShow,
  groupedProducts,
  newGroupItems,
  setNewGroupItems,
}) => {
  const [isSale, setIsSale] = useState(!item.soldOutFlag)
  const [showModal, setShowModal] = useState(false) // 모달 표시 여부
  const [modalMessage, setModalMessage] = useState("") // 모달 메시지
  const [pendingSaleStatus, setPendingSaleStatus] = useState(null) // 변경될 판매 상태 저장
  // 모달에서 확인을 누르면 판매 상태를 변경
  const handleConfirm = () => {
    setIsSale(pendingSaleStatus) // 실제로 판매 상태 변경
    const fetchData = async () => {
      try {
        const response = await client.put(
          `Product/updateProductSoldOut?id=${item.id}&flag=${!pendingSaleStatus}`
        )
        window.location.reload()
      } catch (error) {
        alert(error.response.data.message)
      }
    }
    fetchData()
    setShowModal(false) // 모달 닫기
  }

  const handleSale = (value) => {
    if (isSale !== value) {
      setPendingSaleStatus(value) // 변경될 상태 임시 저장
      setModalMessage(
        value
          ? "정말로 이 아이템을 '판매 중'으로 변경하시겠습니까?"
          : "정말로 이 아이템을 '판매 중지'로 변경하시겠습니까?"
      )
      setShowModal(true) // 모달 열기
    }
  }

  const imgClick = async (id, mode) => {
    if (mode === "thumbnail") {
      const features = "width=600,height=400,scrollbars=yes"
      window.open(id, "_blank", features)
    } else {
      try {
        const features = "width=600,height=800,scrollbars=yes"
        const response = await client.get(
          `/Product/findDetailUrlByProductId?productId=${id}`
        )
        const imageObjects = response.data

        const newWindow = window.open("", "_blank", features)
        newWindow.document.open()
        newWindow.document.write(`
          <html>
            <head>
              <title>Detail Images</title>
              <style>
                body {
                  margin: 0;
                  padding: 0;
                  font-family: Arial, sans-serif;
                }
                img {
                  display: block;
                  margin: 10px auto;
                  max-width: 100%;
                }
                .container {
                  text-align: center;
                }
              </style>
            </head>
            <body>
              <div class="container">
                ${imageObjects
                  .map(
                    (image) => `<img src="${image.url}" alt="Detail Image"/>`
                  )
                  .join("")}
              </div>
            </body>
          </html>
        `)
        newWindow.document.close()
      } catch (error) {
        console.error("Error fetching or displaying images:", error)
      }
    }
  }

  const truncateText = (text) => {
    const maxLength = 13
    return text.length <= maxLength
      ? text
      : `${text.substring(0, maxLength - 3)}...`
  }
  // 그룹화 용
  const [isChecked, setIsChecked] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  console.log("그룹화된거", newGroupItems)
  const isDefaultGroup = () => {
    // groupedProducts가 배열인지, item.id가 유효한 값인지 확인
    if (!Array.isArray(groupedProducts) || !item || !item.id) {
      return false // 조건이 만족되지 않으면 false 반환
    }

    return groupedProducts.some(
      (product) => String(product) === String(item.id)
    )
  }

  // 그룹화된 항목을 체크하고 비활성화하는 로직을 useEffect로 이동
  useEffect(() => {
    if (isDefaultGroup()) {
      setIsChecked(true) // 그룹화된 항목은 체크된 상태로 설정
      setIsDisabled(true) // 그룹화된 항목은 비활성화
    }
  }, [groupedProducts, item.id]) // groupedProducts나 item.id가 변경될 때만 실행

  const handleSelectCheckbox = () => {
    if (!isDisabled) {
      setIsChecked(!isChecked)
      // 체크된 항목을 newGroupItems 배열에 추가하거나 제거
      if (!isChecked) {
        // 체크된 경우 배열에 추가 (중복 방지)
        if (!newGroupItems.includes(item.id)) {
          setNewGroupItems((prevItems) => [...prevItems, item.id])
        }
      } else {
        // 체크 해제된 경우 배열에서 제거
        setNewGroupItems((prevItems) =>
          prevItems.filter((productId) => productId !== item.id)
        )
      }
    }
  }
  console.log(isChecked)
  return (
    <>
      <CustomTr
        key={item.id}
        selected={isChecked}
        style={{ backgroundColor: "purple" }}
      >
        {" "}
        {/* 선택된 항목의 배경색 변경 */}
        {mode && (
          <CustomTd onClick={handleSelectCheckbox}>
            <input
              type="checkbox"
              checked={isChecked} // 체크 상태에 따른 체크박스 설정
              disabled={isDisabled} // 비활성화 상태 설정
            />
          </CustomTd>
        )}
        <CustomTd>{findCategoryName(item.categoryId)}</CustomTd>
        <CustomTd>
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id={`tooltip-${item.id}`}>{item.name}</Tooltip>}
          >
            <div>{truncateText(item.name)}</div>
          </OverlayTrigger>
        </CustomTd>
        <CustomTd style={{ textAlign: "center", cursor: "pointer" }}>
          <img
            src={item.photoUrl}
            alt={item.name}
            style={{ width: "100px", height: "auto" }}
            onClick={() => imgClick(item.photoUrl, "thumbnail")}
          />
        </CustomTd>
        <CustomTd style={{ textAlign: "center", cursor: "pointer" }}>
          <div onClick={() => imgClick(item.id, "detail")}>상세보기></div>
        </CustomTd>
        <CustomTd>{item.price}</CustomTd>
        {!mode && (
          <>
            <CustomTd>{item.qnaCount}</CustomTd>
            <CustomTd>{item.rating}</CustomTd>
            <CustomTd>{item.reviewCount}</CustomTd>
            <CustomTd>{item.saleCount}</CustomTd>
            <CustomTd>
              <DropdownButton
                id="dropdown-basic-button"
                title={isSale ? "판매 중" : "판매 중지"}
                variant={isSale ? "success" : "warning"}
              >
                <Dropdown.Item onClick={() => handleSale(true)}>
                  판매 중
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleSale(false)}>
                  판매 중지
                </Dropdown.Item>
              </DropdownButton>
            </CustomTd>
            <CustomTd>
              <div style={{ textAlign: "center" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  onClick={() => handleEdit(item)}
                >
                  수정
                </Button>
                <Button variant="danger" onClick={() => handleShow(item)}>
                  삭제
                </Button>
              </div>
            </CustomTd>
          </>
        )}
      </CustomTr>

      {/* 판매 상태 변경 확인 모달 */}
      <ModalComponent
        title="판매 상태 변경"
        body={modalMessage}
        show={showModal}
        handleClose={() => setShowModal(false)}
        onConfirm={handleConfirm}
        cancelText="취소"
        confirmText="변경"
        confirmVariant="primary"
      />
    </>
  )
}
