import React from "react"
import RadialChartComponent from "./Analysis/RadialChartComponent"
import RaderChartComponent from "./Analysis/RaderChartComponent"

export default function SystemMonitor() {
  return (
    <div className="container">
      <div style={{ gridColumn: "span 2" }}>
        <p
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          시스템 모니터링
        </p>
        <div style={{ display: "flex" }}>
          <RaderChartComponent /> <RadialChartComponent />
        </div>
      </div>
    </div>
  )
}
