import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useNavigate, useParams } from "react-router-dom"
import { client } from "../../API/api"
import { useRecoilValue } from "recoil"
import { UId } from "../../../App"

export default function BoardForm() {
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  const userId = useRecoilValue(UId)
  const navi = useNavigate()
  const { id } = useParams()
  console.log(id)

  useEffect(() => {
    if (id) {
      const fetchNotice = async () => {
        try {
          const response = await client.get(`/Notice/findNoticeById?id=${id}`)

          setTitle(response.data.title)
          setContent(response.data.content)
        } catch (error) {
          console.error("공지사항을 가져오는데 실패했습니다.", error)
        }
      }
      fetchNotice()
    }
  }, [])
  const handleSubmit = async (event) => {
    event.preventDefault()
    const postData = {
      title,
      content,
      userId,
    }
    try {
      if (id == null) {
        const response = await client.post("/Notice/createNotice", postData)
        console.log(response.data)
        alert("공지사항이 성공적으로 추가되었습니다!")
        // 필요한 경우 폼 초기화 또는 다른 동작 수행
      } else {
        const response = await client.put(
          `/Notice/updateNotice?id=${id}`,
          postData
        )
      }
      navi(-1)
    } catch (error) {
      console.error("공지사항 추가에 실패했습니다.", error)
      alert("공지사항 추가에 실패했습니다.")
    }
  }

  const handleBack = () => {
    if (window.confirm("공지사항 작성을 취소하시겠습니까?")) {
      navi(-1)
    }
  }
  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Title>공지사항</Title>

      <FormGroup>
        <Label>제목</Label>
        <TextInput
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label>내용</Label>
        <TextArea
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </FormGroup>

      <ButtonGroup>
        <Button type="button" onClick={() => handleBack()}>
          취소
        </Button>
        <Button type="submit">등록</Button>
      </ButtonGroup>
    </FormWrapper>
  )
}

const FormWrapper = styled.form`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h1`
  text-align: center;
  color: #333;
`

const FormGroup = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
`

const TextInput = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
`

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:first-of-type {
    background-color: #28a745;
  }

  &:first-of-type:hover {
    background-color: #218838;
  }
`

const Notice = styled.div`
  margin-top: 20px;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
`

const NoticeTitle = styled.p`
  font-weight: bold;
`

const NoticeList = styled.ul`
  padding-left: 20px;
`

const NoticeItem = styled.li`
  margin-bottom: 5px;
  word-wrap: break-word;
  white-space: normal;
`
