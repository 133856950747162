import React from "react"
import styled from "styled-components"
import { Button, Card } from "react-bootstrap"
import { differenceInDays, format, parseISO } from "date-fns"
import { useNavigate } from "react-router-dom"
import { client } from "../../../API/api"

const ReviewCardWrapper = styled(Card)`
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`

const ReviewImage = styled(Card.Img)`
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 15px;
  margin-right: 10px;
`

const ProductImage = styled(Card.Img)`
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
`

const ReviewDetails = styled.div`
  display: flex;
  flex-direction: column;
`

const ReviewDate = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
`

const ProductName = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
`

const ReviewText = styled.div`
  font-size: 14px;
  white-space: pre-wrap;
  margin-bottom: 10px;
`

const ReviewRating = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;

  .star {
    color: orange;
    margin-right: 2px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`

const MyReviewCard = ({ review, isAdmin }) => {
  const formatDate = (dateString) => {
    const date = parseISO(dateString)
    return format(date, "yyyy-MM-dd")
  }

  const renderRating = (rating) => {
    let stars = []
    for (let i = 0; i < 5; i++) {
      stars.push(
        <span key={i} className="star">
          {i < rating ? "★" : "☆"}
        </span>
      )
    }
    return stars
  }

  const navigate = useNavigate()

  const handleDelete = async () => {
    try {
      await client.delete(`/Review/deleteReview?id=${review.id}`)
      alert("리뷰가 삭제 되었습니다.")
      window.location.reload() // Refresh the page after deletion
    } catch (error) {
      console.error(error)
    }
  }

  // Calculate if the review is within the 8-day editable period
  const isEditable = differenceInDays(new Date(), parseISO(review.date)) <= 8
  console.log("review id: ", review.id)
  console.log("review", review)
  return (
    <ReviewCardWrapper>
      <Card.Body>
        <ReviewDetails>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ProductImage
              src={review.productPhotoUrl}
              alt={review.productName}
            />
            <ProductName>{review.productName}</ProductName>
            {/*첫 리뷰 이후 7일 이내 수정 */}
            {isEditable && !isAdmin && (
              <ButtonContainer>
                <Button
                  variant="link"
                  size="sm"
                  style={{ marginRight: "5px" }}
                  onClick={() => navigate(`/ReviewForm/modify/${review.id}`)}
                >
                  수정
                </Button>
                <Button variant="link" size="sm" onClick={() => handleDelete()}>
                  삭제
                </Button>
              </ButtonContainer>
            )}
            {isAdmin && (
              <ButtonContainer>
                <Button variant="link" size="sm" onClick={() => handleDelete()}>
                  삭제
                </Button>
              </ButtonContainer>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ReviewRating>{renderRating(review.rating)}</ReviewRating>
            <ReviewDate>{formatDate(review.date)}</ReviewDate>
          </div>
          <div style={{ display: "flex" }}>
            {review.photoList.length !== 0 &&
              review.photoList.map((item, i) => (
                <ReviewImage src={item} alt={"리뷰사진"} />
              ))}
          </div>
          <ReviewText>{review.content}</ReviewText>
        </ReviewDetails>
      </Card.Body>
    </ReviewCardWrapper>
  )
}

export default MyReviewCard
