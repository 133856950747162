import React, { useEffect, useState } from "react"
import { Button, Card } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons"

// 스타일 정의
const FixedContainer = styled.div`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  right: 20px;
  z-index: 1;
`

export default function RecentlyViewedProducts() {
  const [recentProducts, setRecentProducts] = useState([])
  const location = useLocation()
  const navi = useNavigate()
  const [isWide, setIsWide] = useState(window.innerWidth >= 1600)

  useEffect(() => {
    const handleResize = () => {
      setIsWide(window.innerWidth >= 1600)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    // localStorage에서 recentViewedList 데이터를 가져옴
    const storedProducts = JSON.parse(localStorage.getItem("recentViewedList"))
    if (storedProducts && Array.isArray(storedProducts)) {
      setRecentProducts(storedProducts)
    }
  }, [location])

  const [fixedStyle, setFixedStyle] = useState({
    position: "relative",
    top: "0px",
  })

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY
      const topElements = document.querySelectorAll("#top-section")
      const bottomElement = document.querySelector("#bottom-section")
      // 첫 번째 페이지(홈 페이지)에서만 890px 적용
      let topLimit
      if (location.pathname === "/") {
        topLimit =
          topElements.length > 0
            ? topElements[1].getBoundingClientRect().bottom + window.scrollY
            : 870
      } else {
        // 그 외의 페이지에서 #top-section 요소를 기준으로 적용
        topLimit =
          topElements.length > 0
            ? topElements[0].getBoundingClientRect().bottom + window.scrollY
            : 870
      }

      // bottomLimit: #bottom-section의 상단 위치
      const bottomLimit = bottomElement
        ? bottomElement.getBoundingClientRect().top + window.scrollY
        : document.body.scrollHeight

      // RecentlyViewedProducts 컴포넌트의 높이
      const recentlyViewedElement = document.querySelector(
        ".recently-viewed-card"
      )
      if (!recentlyViewedElement) return

      const recentlyViewedHeight = recentlyViewedElement.offsetHeight

      if (scrollY >= topLimit && scrollY + recentlyViewedHeight < bottomLimit) {
        setFixedStyle({
          position: "fixed",
          top: "20px",
        })
      } else if (scrollY + recentlyViewedHeight >= bottomLimit) {
        setFixedStyle({
          position: "absolute",
          top: `${bottomLimit - recentlyViewedHeight}px`, // bottom-section의 상단 바로 위에 고정
        })
      } else {
        setFixedStyle({
          position: "absolute",
          top: `${topLimit}px`,
        })
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [location])

  // 맨 위로 스크롤하는 함수
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 부드러운 스크롤
    })
  }

  // 맨 아래로 스크롤하는 함수
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth", // 부드러운 스크롤
    })
  }

  return (
    <>
      {isWide ? (
        <FixedContainer position={fixedStyle.position} top={fixedStyle.top}>
          <Card
            className="recently-viewed-card"
            style={{ width: "120px", marginTop: "10px", minHeight: "300px" }}
          >
            <Card.Body style={{ padding: "10px 10px 0px 10px" }}>
              <div style={{ backgroundColor: "#edf5fb" }}>
                <h2 className="h6 mb-3 text-center">최근 본 상품</h2>
              </div>
              <div style={{ overflowY: "auto", minHeight: "200px" }}>
                {recentProducts.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <div>상품이</div>
                    <div>없습니다</div>
                  </div>
                ) : (
                  <ul className="list-unstyled">
                    {recentProducts.map((product) => (
                      <Card
                        key={product.itemId}
                        className="mb-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => navi(`/Item/${product.itemId}`)}
                      >
                        <Card.Img
                          variant="top"
                          src={product.photoUrl}
                          alt={product.name}
                          style={{
                            width: "100%",
                            height: "80px",
                            objectFit: "cover",
                          }}
                        />
                        <Card.Body
                          className="text-center"
                          style={{ padding: 0 }}
                        >
                          <Card.Title
                            style={{
                              fontSize: "1rem",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {product.name}
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    ))}
                  </ul>
                )}
              </div>
            </Card.Body>
          </Card>
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
              variant={"secondary"}
              onClick={scrollToTop} // 맨 위로 가기 버튼
            >
              <CaretUpFill />
            </Button>
            <Button
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
              }}
              variant={"secondary"}
              onClick={scrollToBottom} // 맨 아래로 가기 버튼
            >
              <CaretDownFill />
            </Button>
          </div>
        </FixedContainer>
      ) : null}
    </>
  )
}
