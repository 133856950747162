import React, { useState } from "react"
import { Route, Routes, useNavigate } from "react-router-dom"
import styled from "styled-components"

import CompanyManage from "../../Admin/CompanyManage/CompanyManage"
import { useRecoilValue } from "recoil"
import { adminPage, myPage } from "../../../App"
import {
  MyOrderPage,
  MyReview,
  ShoppingCartList,
  UserInfo,
} from "../../ConBox/MyPage"
import MyQna from "../../ConBox/MyPage/MyQna/MyQna"
import Withdraw from "../../ConBox/MyPage/Withdraw/Withdraw"
import Admin from "../../Admin/DashBoard/Admin"
import ProductionOrderManage from "../../Admin/ProductionOrderManage"
import Productiondefective from "../../Admin/Productiondefective"
import SystemMonitor from "../../Admin/SystemMonitor"
import QnaReviewManage from "../../Admin/QnaReviewManage"
import AnnouncementPopUp from "../../Admin/AnnouncementPopUp/AnnouncementPopUp"
import { AdminOrder } from "../../Admin/AdminOrder/AdminOrder"
import { ShippingAddressManage } from "../../ConBox/MyPage/MyOrder/ShippiongAdress/ShippingAddressManage"
import ManageItemMenu from "../../Admin/AddItem/ManageItemMenu"
import { MyOrderDetail } from "../../ConBox/MyPage/MyOrder/MyOrderDetail"

const Container = styled.div`
  margin: 0 auto;
  min-height: 100vh;
  width: 100%;
  max-width: 1800px; /* Adjust max-width as needed */
`

const Sidebar = styled.div`
  background-color: #498ddd;
  border-right: 1px solid lightgray;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`

const SidebarText = styled.span`
  font-weight: bold;
  font-size: 24px;
  color: white;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  min-height: calc(100vh - 60px); /* Adjust if needed */
  overflow: hidden;
`

const SidebarContainer = styled.div`
  width: 300px; /* Fixed width for sidebar */
  max-width: 20%; /* Responsive max width */
  min-width: 300px; /* Minimum width for sidebar */
  border-right: 1px solid lightgray;
  overflow: auto;
  flex-shrink: 0;
`

const MainContent = styled.div`
  flex-grow: 1;
  overflow: auto;
  padding: 20px;
`

const SidebarLink = styled.button`
  display: block;
  font-size: 18px;
  color: ${({ active }) => (active ? "#498ddd" : "black")};
  padding: 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: #498ddd;
  }
`

const TitleFont = styled.p`
  font-weight: 700;
`

const Mypage = () => {
  const isAdminPage = useRecoilValue(adminPage)
  const isMyPage = useRecoilValue(myPage)
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("Orders") // 기본값 설정

  const handleNavigation = (path, tab) => {
    setActiveTab(tab) // 탭 변경 시 활성화 상태 업데이트
    navigate(path)
  }

  return (
    <Container>
      <Content>
        <SidebarContainer>
          {isMyPage && (
            <>
              <Sidebar>
                <SidebarText>마이페이지</SidebarText>
              </Sidebar>
              <SidebarLink
                active={activeTab === "Orders"}
                onClick={() => handleNavigation("/MyPage/Orders", "Orders")}
              >
                <TitleFont>주문 조회</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "Cart"}
                onClick={() => handleNavigation("/MyPage/Cart", "Cart")}
              >
                <TitleFont>장바구니</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "Reviews"}
                onClick={() => handleNavigation("/MyPage/Reviews", "Reviews")}
              >
                <TitleFont>리뷰 관리</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "Qna"}
                onClick={() => handleNavigation("/MyPage/Qna", "Qna")}
              >
                <TitleFont>문의 내역</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "Userinfo"}
                onClick={() => handleNavigation("/MyPage/Userinfo", "Userinfo")}
              >
                <TitleFont>회원 정보 및 수정</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "ShippingAddress"}
                onClick={() =>
                  handleNavigation("/MyPage/ShippingAddress", "ShippingAddress")
                }
              >
                <TitleFont>배송지 관리</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "Withdraw"}
                onClick={() => handleNavigation("/MyPage/Withdraw", "Withdraw")}
              >
                <TitleFont>회원 탈퇴</TitleFont>
              </SidebarLink>
            </>
          )}
          {isAdminPage && (
            <>
              <Sidebar>
                <SidebarText>관리자 페이지</SidebarText>
              </Sidebar>
              <SidebarLink
                active={activeTab === "DashBoard"}
                onClick={() =>
                  handleNavigation("/Admin/DashBoard", "DashBoard")
                }
              >
                <TitleFont>관리자 대시보드</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "AdminOrder"}
                onClick={() =>
                  handleNavigation("/Admin/AdminOrder", "AdminOrder")
                }
              >
                <TitleFont>주문 관리</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "ProductionDefective"}
                onClick={() =>
                  handleNavigation(
                    "/Admin/ProductionDefective",
                    "ProductionDefective"
                  )
                }
              >
                <TitleFont>생산/불량 개수</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "ProductionOrderManage"}
                onClick={() =>
                  handleNavigation(
                    "/Admin/ProductionOrderManage",
                    "ProductionOrderManage"
                  )
                }
              >
                <TitleFont>생산/주문 관리</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "SystemMonitor"}
                onClick={() =>
                  handleNavigation("/Admin/SystemMonitor", "SystemMonitor")
                }
              >
                <TitleFont>시스템 모니터링</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "ShowItem"}
                onClick={() =>
                  handleNavigation("/Admin/ManageItemMenu/ShowItem", "ShowItem")
                }
              >
                <TitleFont>상품 관리</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "QnaReviewManage"}
                onClick={() =>
                  handleNavigation("/Admin/QnaReviewManage", "QnaReviewManage")
                }
              >
                <TitleFont>문의/리뷰</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "CompanyIntro"}
                onClick={() =>
                  handleNavigation(
                    "/Admin/CompanyManage/CompanyIntro",
                    "CompanyIntro"
                  )
                }
              >
                <TitleFont>회사 소개 관리</TitleFont>
              </SidebarLink>
              <SidebarLink
                active={activeTab === "AnnouncementPopUp"}
                onClick={() =>
                  handleNavigation(
                    "/Admin/AnnouncementPopUp",
                    "AnnouncementPopUp"
                  )
                }
              >
                <TitleFont>공지사항 팝업</TitleFont>
              </SidebarLink>
            </>
          )}
        </SidebarContainer>
        <MainContent>
          <Routes>
            {isMyPage && (
              <>
                <Route path="Orders" element={<MyOrderPage />} />
                <Route path="Orders/Detail" element={<MyOrderDetail />} />
                <Route path="Cart" element={<ShoppingCartList />} />
                <Route path="Reviews" element={<MyReview />} />
                <Route path="Qna" element={<MyQna />} />
                <Route path="Userinfo" element={<UserInfo />} />
                <Route
                  path="ShippingAddress"
                  element={<ShippingAddressManage />}
                />
                <Route path="Withdraw" element={<Withdraw />} />
              </>
            )}
            {isAdminPage && (
              <>
                <Route path="DashBoard" element={<Admin />} />
                <Route path="AdminOrder" element={<AdminOrder />} />
                <Route
                  path="ProductionDefective"
                  element={<Productiondefective />}
                />
                <Route
                  path="ProductionOrderManage"
                  element={<ProductionOrderManage />}
                />
                <Route path="SystemMonitor" element={<SystemMonitor />} />
                <Route
                  path="ManageItemMenu/:tab"
                  element={<ManageItemMenu />}
                />
                <Route path="QnaReviewManage" element={<QnaReviewManage />} />
                <Route path="CompanyManage/:tab" element={<CompanyManage />} />
                <Route
                  path="AnnouncementPopUp"
                  element={<AnnouncementPopUp />}
                />
              </>
            )}
          </Routes>
        </MainContent>
      </Content>
    </Container>
  )
}

export default Mypage
