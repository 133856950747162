import React from "react"
import ThumbnailAddComponent from "./ThumbnailAddComponent"
import DetailImagesAddComponent from "./DetailImagesAddComponent"

const ImageUploadSection = ({
  thumbnail,
  setThumbnail,
  detailImages,
  setDetailImages,
  mode,
}) => (
  <div>
    <p style={{ marginTop: "20px", marginBottom: "10px" }}>썸네일 이미지</p>
    <ThumbnailAddComponent image={thumbnail} setImage={setThumbnail} />
    <p style={{ marginTop: "20px", marginBottom: "10px" }}>
      상세 이미지 | {detailImages.length}개
    </p>
    <DetailImagesAddComponent
      images={detailImages}
      setImages={setDetailImages}
      mode={mode}
    />
  </div>
)

export default ImageUploadSection
