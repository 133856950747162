import React, { useEffect, useState } from "react"
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap"
import styled from "styled-components"
import { completeOrder, handlePayment, prepareOrder } from "./payapi"
import { deliveryFee } from "../../App"
import { useRecoilValue } from "recoil"
import { Pause, Plus } from "react-bootstrap-icons"
import { useLocation, useNavigate } from "react-router-dom"
import { DeliveryRegionCard } from "./DeliveryRegionCard"
import { client } from "../API/api"

const PaymentButton = styled(Button)`
  width: 100%;
  padding: 15px;
  background-color: #007aff;
  color: white;
  font-size: 18px;
  border-radius: 5px;
  &:hover {
    background-color: #005bb5;
  }
`

const FormContainer = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
`

const OrderCardWrapper = styled(Card)`
  margin-bottom: 20px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`

const OrderImage = styled(Card.Img)`
  width: 70px;
  height: 70px;
  object-fit: cover;
`

const OrderDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const OrderInfo = styled.div`
  flex: 1;
  margin-left: 20px;
`

const OrderStatus = styled.div`
  font-size: 14px;
  color: #757575;
`

const ItemAmount = styled.span`
  font-weight: bold;
`

const Payment = () => {
  const navi = useNavigate()

  const [payMethod, setPayMethod] = useState("CARD")
  const [cartItems, setCartItems] = useState([])
  const fee = useRecoilValue(deliveryFee)
  const location = useLocation()
  const [region, setRegion] = useState([])
  const userId = localStorage.getItem("userId")
  // 기본 배송지로 사용할게요
  const [isDefaultDeliverChecked, setIsDefaultDeliverChecked] = useState(false)
  const handleCheckboxChange = (event) => {
    setIsDefaultDeliverChecked(event.target.checked)
  }
  useEffect(() => {
    if (location.state && location.state.item) {
      setCartItems([location.state.item])
      console.log([location.state.item])
    } else {
      const storedCartItems = JSON.parse(localStorage.getItem("shoppingCart"))
      if (storedCartItems) {
        console.log(storedCartItems)
        setCartItems(storedCartItems)
      }
    }
  }, [location.state])
  console.log(region)
  const startOrderProcess = async () => {
    try {
      const tempOrder = await prepareOrder(cartItems, userId)
      const orderInfo = await completeOrder(tempOrder, {
        customerName: region.customerName,
        address: region.address,
        detailAddress: region.detailAddress,
        postCode: region.postCode,
        phoneNumber: region.phoneNumber,
      })

      await handlePayment({ ...tempOrder, ...orderInfo }, cartItems, payMethod)
      alert("Payment completed successfully!")
      localStorage.removeItem("shoppingCart")
      setCartItems([])
      //기본 배송지로 설정
      if (isDefaultDeliverChecked) {
        const fetchData = async () => {
          try {
            await client.put(`/Address/updateAddressDefault?id=${region.id}`)
          } catch (error) {
            alert("기본 배송지 설정이 실패했습니다.")
            console.log(error.response.data.message)
          }
        }
        fetchData()
      }

      navi("/MyPage/Orders")
    } catch (error) {
      console.error("Order process failed:", error)
      alert("Error during the order process.")
    }
  }

  const totalPrice = cartItems.reduce(
    (acc, item) => acc + item.price * item.quantity,
    0
  )

  return (
    <Container className="mt-4">
      <h1 className="text-center mb-4">주문/결제</h1>

      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <FormContainer>
            <DeliveryRegionCard
              region={region}
              setRegion={setRegion}
              isDefaultDeliverChecked={isDefaultDeliverChecked}
              setIsDefaultDeliverChecked={setIsDefaultDeliverChecked}
              handleCheckboxChange={handleCheckboxChange}
            />
            <h5 style={{ fontWeight: 700, marginTop: "40px" }}>주문상품</h5>

            {cartItems.length > 0 ? (
              <ListGroup className="mb-3">
                {cartItems.map((item, index) => (
                  <OrderCardWrapper key={index}>
                    <Card.Body>
                      <OrderDetails>
                        <OrderImage src={item.photoUrl} alt={item.item} />
                        <OrderInfo>
                          <Card.Title>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              {item.name}| {item.quantity}개
                            </p>
                          </Card.Title>
                          <Card.Text>
                            <ItemAmount>{item.price}원</ItemAmount>
                          </Card.Text>
                          <OrderStatus>{item.status}</OrderStatus>
                        </OrderInfo>
                      </OrderDetails>
                    </Card.Body>
                  </OrderCardWrapper>
                ))}
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Plus style={{ fontSize: "20px", fontWeight: 700 }} />
                </div>
                <Card
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "100%",
                    padding: "13px",
                    alignItems: "center",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    배송비: {fee.toLocaleString()}원
                  </span>
                </Card>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Pause style={{ fontSize: "22px", fontWeight: 700 }} />
                </div>
              </ListGroup>
            ) : (
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  padding: "13px",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                Your cart is empty.
              </Card>
            )}
            <Card
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "100%",
                padding: "13px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: "16px",
                  }}
                >
                  총 주문 금액
                </p>
                <p
                  style={{
                    display: "flex",
                    margin: 0,
                    marginLeft: "auto",
                    fontSize: "14px",
                  }}
                >
                  <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                    총 {(totalPrice + fee).toLocaleString()}원
                  </span>
                </p>
              </div>
            </Card>

            <PaymentButton
              onClick={startOrderProcess}
              style={{ marginTop: "20px", fontWeight: "bold" }}
            >
              {(totalPrice + fee).toLocaleString()}원 결제하기
            </PaymentButton>
          </FormContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default Payment
