import React, { useState } from "react"
import axios from "axios"
import KakaoLogin from "./KakaoLogin/KakaoLogin"
import NaverLogin from "./NaverLogin/NaverLogin"
import { useRecoilValue } from "recoil"
import { serverUrl } from "../../../App"

function LoginTest() {
  const baseUrl = useRecoilValue(serverUrl)
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("accessToken") || ""
  )
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem("refreshToken") || ""
  )

  const handleKakaoLogin = async () => {
    window.location.href = `${baseUrl}/api/v1/auth/oauth2/kakao`
  }

  const handleNaverLogin = () => {
    window.location.href = `${baseUrl}/api/v1/auth/oauth2/naver`
  }

  const reissueToken = () =>
    axios
      .get("https://wonjungfoodserver.site/Member/reissue", {
        withCredentials: true, // 쿠키를 포함시켜 요청
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Headers: ", response.headers) // 전체 헤더를 출력해보기
          let newAccessToken = response.headers["authorization"]
          if (newAccessToken && newAccessToken.startsWith("Bearer ")) {
            newAccessToken = newAccessToken.substring(7) // 'Bearer '를 제거
          }
          console.log("newAccessToken : ", newAccessToken)
          setAccessToken(newAccessToken) // 새로 받은 액세스 토큰 저장
          // fetchUserInfo(newAccessToken) // 새로운 토큰으로 사용자 정보 요청
          console.log("Token reissued successfully")
        } else {
          console.error("Failed to reissue token")
        }
      })
      .catch((error) => console.error("Error during token reissue:", error))

  return (
    <div className="App">
      <div>
        <div style={{ marginBottom: "30px" }}>
          <KakaoLogin handleOAuthLogin={handleKakaoLogin} />
        </div>
        <div style={{ marginBottom: "80px" }}>
          <NaverLogin handleOAuthLogin={handleNaverLogin} />
        </div>
      </div>
    </div>
  )
}

export default LoginTest
