import React, { useState } from "react"
import imageCompression from "browser-image-compression"
import { Button, FormGroup, Input, Label } from "reactstrap"
import { getDownloadURL, ref, uploadBytes } from "firebase/storage"
import { storage } from "../../../firebase"
import { client } from "../../API/api"
import { CompanyCert } from "../../ConBox/Company"
import LoadingPage from "../../LoadingPage"

const CompanyCertQualityManage = ({ mode }) => {
  const [previewUrl, setPreviewUrl] = useState()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState()
  const [certId, setCertId] = useState("")
  const [text, setText] = useState("")

  // Firebase 이미지 업로드
  const handleUpload = async (image) => {
    if (image) {
      const storageRef = ref(storage, `${mode}/${image.name}`)
      try {
        await uploadBytes(storageRef, image)
        const url = await getDownloadURL(storageRef)
        console.log("Uploaded image URL:", url)
        return url
      } catch (error) {
        console.error("Upload failed:", error)
        alert("Firebase 업로드 오류: " + error.message)
        throw error
      }
    } else {
      return ""
    }
  }

  // 서버 업로드
  const createCompanyCert = async (certId, imageUrl, text, mode) => {
    const postData = { certId, text, photoUrl: imageUrl, mode }
    console.log(postData)
    try {
      await client.post(`Cert/createCert`, postData)
    } catch (error) {
      console.error("Server upload failed:", error)
      alert("서버 업로드 오류: " + error.message)
      throw error
    }
  }

  // 버튼 클릭 시
  const handleSubmit = async (e) => {
    e.preventDefault() // 폼 제출 시 페이지 새로 고침 방지
    setLoading(true) // 로딩 시작

    try {
      // Firebase에 업로드
      const imageUrl = await handleUpload(image)
      // 서버에 업로드
      await createCompanyCert(certId, imageUrl, text, mode)
      window.location.reload() // 성공 시 리다이��트
    } catch (error) {
      console.error("Submission failed:", error)
    } finally {
      setLoading(false) // 로딩 종료
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0]

    if (file && file.name) {
      setLoading(true)

      const fileExtension = file.name.split(".").pop().toLowerCase()

      try {
        if (fileExtension === "webp") {
          setImage(file)
          setPreviewUrl(URL.createObjectURL(file))
        } else {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/webp",
          }
          const compressedFile = await imageCompression(file, options)

          const newFileName =
            file.name.split(".").slice(0, -1).join(".") + ".webp"
          const webpFile = new File([compressedFile], newFileName, {
            type: "image/webp",
          })

          setImage(webpFile)
          setPreviewUrl(URL.createObjectURL(webpFile))
        }
      } catch (error) {
        console.error("Image compression failed:", error)
      } finally {
        setLoading(false)
      }
    } else {
      console.error("No file selected or file name is undefined.")
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="imageUpload">이미지 업로드</Label>
        <Input
          type="file"
          id="imageUpload"
          onChange={handleImageChange}
          disabled={loading}
        />
      </FormGroup>

      <FormGroup>
        <Label for="certId">이미지 설명</Label>
        <Input
          type="text"
          id="certId"
          placeholder="이미지 설명"
          value={certId}
          onChange={(e) => setCertId(e.target.value)}
        />
      </FormGroup>

      <FormGroup>
        <Label for="text">상세설명</Label>
        <Input
          type="textarea"
          id="text"
          placeholder="상세 설명"
          value={text}
          onChange={(e) => setText(e.target.value)}
          style={{ minHeight: "100px", width: "100%" }}
        />
      </FormGroup>

      {loading ? (
        <LoadingPage />
      ) : (
        previewUrl && (
          <div style={{ marginBottom: "10px", marginTop: "10px" }}>
            <img src={previewUrl} width="200px" alt="Thumbnail" />
          </div>
        )
      )}

      <Button type="submit" color="primary" style={{ marginBottom: "10px" }}>
        업로드
      </Button>

      <CompanyCert mode={mode} />
    </form>
  )
}

export default CompanyCertQualityManage
