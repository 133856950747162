import MyOrderList from "./MyOrderList"
import { useLocation } from "react-router-dom"
import React from "react"
import styled from "styled-components"

const TitleFont = styled.h2`
  font-weight: 600;
`

const InfoSection = styled.div`
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.5;
  border-top: 1px solid #333;
  padding-top: 10px;
  margin-top: 100px;
`

const InfoRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

const Label = styled.span`
  font-weight: bold;
  width: 150px;
  margin-right: 10px;
`

const Value = styled.span`
  flex: 1;
`

const Divider = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: 10px 0;
`

export const MyOrderDetail = () => {
  const location = useLocation() // 현재 페이지의 location 객체 가져오기
  const { orders } = location.state || {} // state에서 orders 데이터 추출

  return (
    <div style={{ marginLeft: "5%", marginRight: "5%" }}>
      <TitleFont>주문 상세</TitleFont>
      <MyOrderList orders={orders} />

      {/* 받는 사람 정보 섹션 */}
      <InfoSection>
        <h4>받는 사람 정보</h4>
        <Divider />
        <InfoRow>
          <Label>받는 사람</Label>
          <Value>{orders.customerName || "정보 없음"}</Value>
        </InfoRow>
        <InfoRow>
          <Label>연락처</Label>
          <Value>{orders.phoneNumber || "정보 없음"}</Value>
        </InfoRow>
        <InfoRow>
          <Label>받는주소</Label>
          <Value>
            {orders.address
              ? `(${orders.postCode}) ${orders.address}, ${orders.detailAddress}`
              : "정보 없음"}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label>배송요청사항</Label>
          <Value>{orders.orderRequest || "문 앞"}</Value>
        </InfoRow>
      </InfoSection>

      {/* 결제 정보 섹션 */}
      <InfoSection>
        <h4>결제 정보</h4>
        <Divider />
        <InfoRow>
          <Label>결제 수단</Label>
          <Value>국민 카드 / 일시불</Value>
        </InfoRow>
        <InfoRow>
          <Label>총 상품 가격</Label>
          <Value>1,000</Value>
        </InfoRow>
        <InfoRow>
          <Label>배송비</Label>
          <Value>3,500원</Value>
        </InfoRow>
        <InfoRow>
          <Label>총 결제 금액</Label>
          <Value>4,500</Value>
        </InfoRow>
      </InfoSection>
    </div>
  )
}

export default MyOrderDetail
