import { Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import {
  admin,
  adminPage,
  loadingState,
  login,
  mobile,
  myPage,
  pay,
  serverUrl,
  UAccessToken,
  UAddress,
  UDetailAddress,
  UId,
  UNickName,
  UPhoneNumber,
  UPostCode,
  UProfileUrl,
  URole,
  UType,
} from "../App"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import Remote from "./Remote/Remote"
import { ItemList, ItemPage } from "./ConBox/Item"
import { Company } from "./ConBox/Company"
import { Login } from "./Header/Login"
import React, { Suspense, useEffect } from "react"
import {
  MyOrderPage,
  MyPage,
  MyReview,
  ShoppingCartList,
  UserInfo,
} from "./ConBox/MyPage"
import { FirstPage } from "./ConBox/FirstPage"
import { SearchPage } from "./ConBox/Search"
import { NotFound } from "./ConBox/Etc"
import { CallCenter } from "./ConBox/CallCenter"
import KakaoLoginCallback from "./Header/Login/KakaoLogin/KakaoLoginCallback"
import NaverLoginCallback from "./Header/Login/NaverLogin/NaverLoginCallback"
import BoardForm from "./ConBox/Board/BoardForm"
import QnaForm from "./ConBox/Item/ItemInfo/QnaForm"
import LoadingPage from "./LoadingPage"
import ReviewForm from "./ConBox/MyPage/MyOrder/ReviewForm"
import MyQna from "./ConBox/MyPage/MyQna/MyQna"
import Withdraw from "./ConBox/MyPage/Withdraw/Withdraw"
import Payment from "./Payment/Payment"
import axios from "axios"
import AdminPage from "./Admin/AdminPage"
import ErrorBoundary from "../ErrorBoundary"
import FloatCartBtn from "./FloatCart/FloatCartBtn"
import RecentlyViewedProducts from "./Common/RecentlyViewedProducts"
import { MyOrderDetail } from "./ConBox/MyPage/MyOrder/MyOrderDetail"

const MainForm = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const navigate = useNavigate()
  const access_token = urlParams.get("access_token")
  const loading = useRecoilValue(loadingState)
  const [isMobile, setIsMobile] = useRecoilState(mobile)
  const location = useLocation()
  const [isMyPage, setIsMyPage] = useRecoilState(myPage)
  const [isAdminPage, setIsAdminPage] = useRecoilState(adminPage)
  const [isPay, setIsPay] = useRecoilState(pay)
  const [accessToken, setAccessToken] = useRecoilState(UAccessToken)
  const [userNickname, setUserNickName] = useRecoilState(UNickName)
  const [userProfileUrl, setUserProfileUrl] = useRecoilState(UProfileUrl)
  const [userRole, setUserRole] = useRecoilState(URole)
  const [userType, setUserType] = useRecoilState(UType)
  const [userId, setUserId] = useRecoilState(UId)
  const [address, setAddress] = useRecoilState(UAddress)
  const [detailAddress, setDetailAddress] = useRecoilState(UDetailAddress)
  const [postCode, setPostCode] = useRecoilState(UPostCode)
  const [phoneNumber, setPhoneNumber] = useRecoilState(UPhoneNumber)

  const [isLogin, setIsLogin] = useRecoilState(login)
  const baseUrl = useRecoilValue(serverUrl)
  const [isAdmin, setIsAdmin] = useRecoilState(admin)
  console.log("access_token", accessToken)
  console.log("isMobile", isMobile)

  const getAccessToken = () => {
    const params = new URLSearchParams(location.search)
    const tokenFromUrl = params.get("access_token")

    if (tokenFromUrl) {
      setAccessToken(tokenFromUrl)
      return tokenFromUrl
    }
  }

  //테스트
  console.log("isAdmin", isAdmin)
  const role = useRecoilValue(URole)
  console.log(role)
  //나중에 삭제해야함
  if (localStorage.getItem("access_token")) {
    setIsLogin(true)
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      const accessToken = getAccessToken()
      console.log("accessToken", accessToken)
      if (accessToken) {
        try {
          console.log("accessToken =", accessToken)
          const response = await axios.get(`${baseUrl}/Member/user-info`, {
            params: {
              access_token: accessToken,
            },
            headers: {
              "Content-Type": "application/json",
              "ngrok-skip-browser-warning": "69420",
            },
          })
          console.log("response = {}", response.data)
          setUserNickName(response.data.nickname)
          setUserProfileUrl(response.data.profileUrl)
          setUserRole(response.data.role)
          setUserType(response.data.type)
          setUserId(response.data.userId)
          setAddress(response.data.address)
          setDetailAddress(response.data.detailAddress)
          setPostCode(response.data.postCode)
          setPhoneNumber(response.data.phoneNumber)
          setIsLogin(true)
          localStorage.setItem("access_token", accessToken)
          localStorage.setItem("role", response.data.role)
          localStorage.setItem("userId", response.data.userId)
          localStorage.setItem("nickname", response.data.nickname)
          localStorage.setItem("profileUrl", response.data.profileUrl)
          localStorage.setItem("address", response.data.address)
          localStorage.setItem("detailAddress", response.data.detailAddress)
          localStorage.setItem("postCode", response.data.postCode)
          localStorage.setItem("phoneNumber", response.data.phoneNumber)
          localStorage.setItem("type", response.data.type)
          console.log("role", response.data.role)
          if (response.data.role === "ROLE_ADMIN") {
            setIsAdmin(true)
          } else {
            setIsAdmin(false)
          }
        } catch (error) {
          console.error(
            "Error fetching user info:",
            error.response ? error.response.data : error.message
          )
        }
      } else {
        console.error("No access token found in URL or storage")
        // navigate("/login"); // 로그인 페이지로 리다이렉트
      }
    }

    fetchUserInfo() // 컴포넌트가 로드될 때 사용자 정보를 가져옴
    // if (response.data.role === "ROLE_ADMIN") {
    if (localStorage.getItem("role") === "ROLE_ADMIN") {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }
  }, [])

  useEffect(() => {
    const path = location.pathname

    // MyPage 여부 설정
    if (path.startsWith("/MyPage")) {
      setIsMyPage(true)
      setIsAdminPage(false)
    }
    // Admin 여부 설정
    else if (path.startsWith("/Admin")) {
      setIsMyPage(false)
      setIsAdminPage(true)
    }
    // 둘 다 아닌 경우
    else {
      setIsMyPage(false)
      setIsAdminPage(false)
    }

    //결제창
    if (path.startsWith("/pay") || path.startsWith("/Pay")) {
      setIsPay(true)
      setIsMyPage(false)
    } else {
      setIsPay(false)
    }

    // Mobile 여부 설정
    setIsMobile(window.innerWidth <= 480)
  }, [location])
  return (
    <div style={{ width: "100%" }}>
      <Suspense fallback={<LoadingPage />}>
        {/* 통신 오류 중 로딩일때 */}
        {loading && <LoadingPage />}
        <ErrorBoundary>
          <Header isMyPage={isMyPage} isAdmin={isAdminPage} />
          <hr style={{ zIndex: -10, marginBottom: "1px" }} />
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <FirstPage />
                </Suspense>
              }
            />
            <Route
              path={`/Search/:value`}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <SearchPage />
                </Suspense>
              }
            />

            <Route
              path="/Category/:id"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ItemList />
                </Suspense>
              }
            />
            <Route path="/Company/Intro" element={<Company mode={1} />} />
            <Route path="/Company/Cert" element={<Company mode={2} />} />
            <Route path="/Company/Quality" element={<Company mode={3} />} />
            <Route path="/Login" element={<Login />} />

            {/* 삭제해야함 */}
            <Route
              path="/kakaoLoginCallback"
              element={<KakaoLoginCallback />}
            />
            <Route
              path="/naverLoginCallback"
              element={<NaverLoginCallback />}
            />
            {/* 마이페이지 */}
            {/* 관리자 페이지 */}
            {isMobile ? (
              <>
                {isLogin && (
                  <>
                    <Route path="MyPage/Orders" element={<MyOrderPage />} />
                    <Route path="MyPage/Cart" element={<ShoppingCartList />} />
                    <Route path="MyPage/Reviews" element={<MyReview />} />
                    <Route path="MyPage/Qna" element={<MyQna />} />
                    <Route path="MyPage/Userinfo" element={<UserInfo />} />
                    <Route path="MyPage/Withdraw" element={<Withdraw />} />
                    <Route
                      path="Mypage/Orders/Detail"
                      element={<MyOrderDetail />}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {isLogin && <Route path="/MyPage/*" element={<MyPage />} />}
                {/* 로그인이 안되서 잠시 꺼둠 */}
                {isLogin && isAdmin && (
                  <Route path="/Admin/*" element={<AdminPage />} />
                )}
              </>
            )}

            <Route
              path="/QnaForm/:id"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <QnaForm />
                </Suspense>
              }
            />
            {/* 첫 리뷰 */}
            <Route
              path="/ReviewForm/:productId/:orderItemId"
              element={<ReviewForm />}
            />
            {/* 리뷰 수정 */}
            <Route
              path="/ReviewForm/modify/:reviewId"
              element={<ReviewForm />}
            />
            <Route path="/CallCenter" element={<CallCenter />} />
            <Route
              path={`/Item/:id`}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <ItemPage />
                </Suspense>
              }
            />
            <Route
              path={`/Board`}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <BoardForm />
                </Suspense>
              }
            />
            <Route
              path={`/Board/:id`}
              element={
                <Suspense fallback={<LoadingPage />}>
                  <BoardForm />
                </Suspense>
              }
            />
            <Route path="*" element={<NotFound />} />
            <Route
              path="/Error"
              element={
                <div
                  style={{
                    background: "blue",
                    height: "500px",
                    width: "100px",
                  }}
                >
                  Error
                </div>
              }
            />

            <Route
              path="/Pay"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <Payment />
                </Suspense>
              }
            />
          </Routes>
          {!isMobile && !isAdminPage && !isMyPage && <RecentlyViewedProducts />}
          <hr style={{ marginTop: "0px" }} />
          {!(isMobile && isPay) && <Footer />}
          {isMobile && <FloatCartBtn />}
          {!(isAdminPage || isPay || isMobile) && <Remote />}
        </ErrorBoundary>
      </Suspense>
    </div>
  )
}

export default MainForm
