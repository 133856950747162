import React, { useState } from "react"
import Board from "../Board/Board"

const CallCenter = () => {
  const [key, setKey] = useState("1")
  return (
    <div className="container">
      <Board cmd={1} />
    </div>
  )
}

export default CallCenter
