import React, { useState } from "react"
import { useRecoilValue } from "recoil"
import { category } from "../../../../App"
import ProductForm from "./ProductForm"
import ImageUploadSection from "./ImageUploadSection"
import SubmitButton from "./SubmitButton"
import { client } from "../../../API/api"
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage"
import { storage } from "../../../../firebase"

export default function AddItem({}) {
  const [categoryName, setCategoryName] = useState("카테고리를 선택하세요")
  const [productName, setProductName] = useState("")
  const [price, setPrice] = useState("")
  const [categoryId, setCategoryId] = useState("")
  const [thumbnail, setThumbnail] = useState(null)
  const [detailImages, setDetailImages] = useState([])
  const categoryList = useRecoilValue(category)

  const checkValid = () =>
    !(
      price > 0 &&
      productName.trim() !== "" &&
      categoryId.trim() !== "" &&
      thumbnail
    )

  const handleUpload = async (image) => {
    if (image) {
      const storageRef = ref(storage, `${categoryName}/${image.name}`)
      try {
        await uploadBytes(storageRef, image)
        const url = await getDownloadURL(storageRef)
        console.log("Uploaded image URL:", url)
        return url
      } catch (error) {
        console.error("Upload failed:", error)
        throw error
      }
    }
    return ""
  }

  const handleImagesUpload = async (images) => {
    const urls = []
    for (let i = 0; i < images.length; i++) {
      const url = await handleUpload(images[i])
      urls.push(url)
    }
    console.log("Uploaded image URLs:", urls)
    return urls
  }

  const deleteImage = async (url) => {
    const storageRef = ref(storage, url)
    try {
      await deleteObject(storageRef)
      console.log(`Deleted image URL: ${url}`)
    } catch (error) {
      console.error("Image deletion failed:", error)
    }
  }

  const deleteUploadedImages = async (thumbnailUrl, detailUrls) => {
    if (thumbnailUrl) {
      await deleteImage(thumbnailUrl)
    }
    if (detailUrls && detailUrls.length > 0) {
      for (const url of detailUrls) {
        await deleteImage(url)
      }
    }
  }

  const createProduct = async (
    productName,
    price,
    categoryId,
    thumbnailUrl,
    detailUrlList
  ) => {
    try {
      await client.post("/Product/createProduct", {
        name: productName,
        price: parseFloat(price),
        productCount: 0,
        categoryId,
        photoUrl: thumbnailUrl,
        detailUrlList,
      })
      alert("상품이 성공적으로 추가되었습니다!")
      window.location.reload()
    } catch (error) {
      alert("상품 추가에 실패 하였습니다.")
      await deleteUploadedImages(thumbnailUrl, detailUrlList)
      throw error
    }
  }

  const handleSubmit = async () => {
    if (checkValid()) {
      alert("정보를 입력해주세요")
    } else {
      try {
        const thumbnailUrl = await handleUpload(thumbnail)
        const detailUrlList = await handleImagesUpload(detailImages)
        await createProduct(
          productName,
          price,
          categoryId,
          thumbnailUrl,
          detailUrlList
        )
      } catch (error) {
        console.error("Error during product creation:", error)
      }
    }
  }

  return (
    <div
      className={"container-sm"}
      style={{ minWidth: "50%", maxWidth: "800px" }}
    >
      <h2>상품 추가</h2>
      <ProductForm
        productName={productName}
        setProductName={setProductName}
        price={price}
        setPrice={setPrice}
        categoryName={categoryName}
        setCategoryName={setCategoryName}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        categoryList={categoryList}
      />
      <ImageUploadSection
        thumbnail={thumbnail}
        setThumbnail={setThumbnail}
        detailImages={detailImages}
        setDetailImages={setDetailImages}
      />
      <SubmitButton onClick={handleSubmit} />
    </div>
  )
}
