import React from "react"
import { Container } from "react-bootstrap"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { DashboardForm } from "./DashboardForm"
import { CartFill, InfoLg, Receipt } from "react-bootstrap-icons"
import { DashboardBodyContainer, DashboardItem } from "./DashboardBodyItem"
import styled from "styled-components"

const SaleHeader = styled(Row)`
  justify-content: center;
  background-color: #5492ff;
  color: white;
  padding: 10px;
  border: 1px solid #cbc9c1;
  border-top-left-radius: 5px; /* 왼쪽 위 모서리 둥글게 */
  border-top-right-radius: 5px; /* 오른쪽 위 모서리 둥글게 */
`
const SaleColContainer = styled(Col)`
  margin-right: 10px;
`
const SaleMenu = styled(Row)`
  border: 1px solid #cbc9c1;
  background-color: #e3e4e6;
  justify-content: center;
  padding: 5px;
`
const SaleMenuItem = styled(Col)`
  border-right: 1px solid #ccc;
  padding: 10px;
  &:last-child {
    border-right: none; /* 마지막 열은 테두리 없음 */
  }
`
const SaleValue = styled(Row)`
  justify-content: center;
  padding: 5px;
  border: 1px solid #cbc9c1;
  border-bottom-left-radius: 5px; /* 왼쪽 위 모서리 둥글게 */
  border-bottom-right-radius: 5px; /* 오른쪽 위 모서리 둥글게 */
`

export default function Admin() {
  return (
    <div className="container">
      <div style={{ textAlign: "center" }}>
        <h3 style={{ fontWeight: "550" }}>관리자 대시보드</h3>
      </div>

      <Container>
        <Row style={{ gap: "10px" }}>
          <SaleColContainer
            style={{
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <SaleHeader>연도별 매출 현황</SaleHeader>
            <SaleMenu>
              <SaleMenuItem>금년매출이익</SaleMenuItem>
              <SaleMenuItem>전년실적</SaleMenuItem>
              <SaleMenuItem>전년매출이익</SaleMenuItem>
            </SaleMenu>
            <SaleValue>
              <SaleMenuItem>0</SaleMenuItem>
              <SaleMenuItem>35,000</SaleMenuItem>
              <SaleMenuItem style={{ color: "red", fontWeight: "bold" }}>
                5,000
              </SaleMenuItem>
            </SaleValue>
          </SaleColContainer>
          <Col
            style={{
              padding: "10px",
              borderRadius: "5px",
              margin: "0 10px",
            }}
          >
            <Row>
              <SaleHeader>월별 매출 현황</SaleHeader>
              <SaleMenu>
                <SaleMenuItem>금월매출이익</SaleMenuItem>
                <SaleMenuItem>전월실적</SaleMenuItem>
                <SaleMenuItem>전월매출이익</SaleMenuItem>
              </SaleMenu>
              <SaleValue>
                <SaleMenuItem>0</SaleMenuItem>
                <SaleMenuItem>1,295,000</SaleMenuItem>
                <SaleMenuItem style={{ color: "red", fontWeight: "bold" }}>
                  1,295,000
                </SaleMenuItem>
              </SaleValue>
            </Row>
          </Col>
          <Col
            style={{
              padding: "10px",
              borderRadius: "5px",
              margin: "0 10px",
            }}
          >
            <Row>
              <SaleHeader>일별 매출 현황</SaleHeader>
              <SaleMenu>
                <SaleMenuItem>금일매출이익</SaleMenuItem>
                <SaleMenuItem>전일실적</SaleMenuItem>
                <SaleMenuItem>전일매출이익</SaleMenuItem>
              </SaleMenu>
              <SaleValue>
                <SaleMenuItem>0</SaleMenuItem>
                <SaleMenuItem>1,295,000</SaleMenuItem>
                <SaleMenuItem style={{ color: "red", fontWeight: "bold" }}>
                  1,295,000
                </SaleMenuItem>
              </SaleValue>
            </Row>
          </Col>
        </Row>
        <div style={{ marginTop: "50px" }}>
          <Row>
            <Col>
              <DashboardForm
                title={"상품관리"}
                img={<CartFill color={"white"} />}
              >
                <DashboardBodyContainer title={"Total:4"}>
                  <DashboardItem text={"Today 상품 현황"} />
                  <DashboardItem text={"상품 1차 승인 대기:0"} />
                  <DashboardItem text={"상품 2차 승인 대기:0"} />
                  <DashboardItem text={"상품 반려:0"} />
                </DashboardBodyContainer>
              </DashboardForm>
            </Col>
            <Col>
              <DashboardForm
                title={"주문관리"}
                img={<Receipt color={"white"} />}
              >
                <DashboardBodyContainer title={"주문현황"}>
                  <DashboardItem text={"입금대기:0"} />
                  <DashboardItem text={"검색 완료:0"} />
                </DashboardBodyContainer>
                <DashboardBodyContainer title={"배송현황"}>
                  <DashboardItem text={"배송준비: 0"} />
                  <DashboardItem text={"배송중: 0"} />
                  <DashboardItem text={"배송완료: 0"} />
                  <DashboardItem text={"구매확정: 0"} />
                </DashboardBodyContainer>
              </DashboardForm>
            </Col>
            <Col>
              <DashboardForm
                title={"취소/반품/교환 관리"}
                img={<InfoLg color={"white"} />}
              >
                <DashboardBodyContainer title={"취소 현황"}>
                  <DashboardItem text={"취소신청:0"} />
                  <DashboardItem text={"취소완료:154"} />
                </DashboardBodyContainer>
                <DashboardBodyContainer title={"반품 현황"}>
                  <DashboardItem text={"반품신청: 0"} />
                  <DashboardItem text={"반품완료: 0"} />
                </DashboardBodyContainer>
                <DashboardBodyContainer title={"교환 현황"}>
                  <DashboardItem text={"교환신청: 0"} />
                  <DashboardItem text={"교환재배송: 0"} />

                  <DashboardItem text={"교환완료: 0"} />
                </DashboardBodyContainer>
              </DashboardForm>
            </Col>
            <Col>
              <DashboardForm title={"상품Q&A"} img={<InfoLg color={"white"} />}>
                <DashboardBodyContainer title={"취소 현황"}>
                  <DashboardItem text={"취소신청:0"} />
                  <DashboardItem text={"취소완료:154"} />
                </DashboardBodyContainer>
                <DashboardBodyContainer title={"반품 현황"}>
                  <DashboardItem text={"반품신청: 0"} />
                  <DashboardItem text={"반품완료: 0"} />
                </DashboardBodyContainer>
                <DashboardBodyContainer title={"교환 현황"}>
                  <DashboardItem text={"교환신청: 0"} />
                  <DashboardItem text={"교환재배송: 0"} />

                  <DashboardItem text={"교환완료: 0"} />
                </DashboardBodyContainer>
              </DashboardForm>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}
