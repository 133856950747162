import React, { useEffect, useState } from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import { client } from "../../../API/api"
import QnaForm from "../../Common/QnaForm"

const StyledContainer = styled(Container)`
  margin-top: 20px;
  min-height: 80vh; /* Set the height of the container */
  margin-bottom: 20px;
`

const FullHeightRow = styled(Row)`
  height: 100%;
`

const BackButton = styled(Button)`
  @media (min-width: 480px) {
    display: ${(props) =>
      props.show ? "block" : "none"}; /* Show based on state */
  }
`

const MobileOnlyContainer = styled.div`
  @media (max-width: 480px) {
    display: ${(props) =>
      props.show ? "block" : "none"}; /* Show based on state */
  }

  @media (min-width: 481px) {
    display: block; /* Always show on larger screens */
  }
`
const CustomCol = styled(Col)`
  @media (max-width: 480px) {
    display: ${(props) =>
      props.show ? "block" : "none"}; /* Show based on state */
  }
`

function MyQna({ Admin }) {
  const [qnaData, setQnaData] = useState()
  const userId = localStorage.getItem("userId")
  console.log("Admin", Admin)
  useEffect(() => {
    if (Admin === false || Admin === undefined) {
      const fetchQnaData = async () => {
        try {
          const response = await client.get(
            `/Qna/getAllQnaByUserId?page=0&size=10&userId=${userId}`
          )
          setQnaData(response.data)
        } catch (error) {
          console.error(error)
        }
      }
      fetchQnaData()
    } else if (Admin === true) {
      const fetchQnaData = async () => {
        try {
          const response = await client.get(`/Qna/getAllQna?page=0&size=10`)
          setQnaData(response.data)
        } catch (error) {
          console.error(error)
        }
      }
      fetchQnaData()
    }
  }, [userId])

  return (
    <StyledContainer>
      <div>
        <h2 style={{ fontWeight: 700 }}>문의 내역</h2>
      </div>
      <QnaForm />
    </StyledContainer>
  )
}

export default MyQna
