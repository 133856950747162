import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { useLocation } from "react-router-dom"

function ShareModal(props) {
  const location = "https://wonjeong.com" + useLocation().pathname
  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text)
      alert("클립보드에 링크가 복사되었어요.")
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">공유하기</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="text"
            value={location}
            readOnly
            style={{ width: "90%" }}
          />
          <button onClick={() => handleCopyClipBoard(location)}>복사</button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>닫기</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShareModal
