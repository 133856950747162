import React from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import dot from "../Img/dot.png"
import * as S from "../CategoryList.style"

const SearchMenu = ({
  input,
  setInput,
  activeEnter,
  handleSearchClick,
  setShowSearchModal,
  showSearchModal,
}) => {
  const navi = useNavigate()
  return (
    <>
      {/* 데스크톱일때 보이는 것 인풋도 같이 보임 */}
      <S.DesktopInputGroup>
        <Form.Control
          type="search"
          placeholder="Search"
          aria-label="Search"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => activeEnter(e)}
        />
        <Button
          variant="underline"
          size="sm"
          active
          onClick={handleSearchClick}
        >
          <img src={dot} alt="" width="20px" style={{ marginTop: "-7px" }} />
        </Button>
      </S.DesktopInputGroup>
      {/* 모바일용 검색 버튼 */}
      <S.SearchBtn
        variant="outline-light"
        onClick={() => setShowSearchModal(true)}
      >
        <img src={dot} alt="Search" />
      </S.SearchBtn>
      <Modal
        show={showSearchModal}
        onHide={() => setShowSearchModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>검색</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="search"
            placeholder="Search"
            aria-label="Search"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => activeEnter(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowSearchModal(false)}>
            닫기
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              navi(`/Search/${input}`)
              setShowSearchModal(false)
            }}
          >
            검색
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SearchMenu
