import { useEffect, useState } from "react"
import { client } from "../../../../API/api"
import { ShippingCard } from "./ShippingCard"
import { EditAddShippingAddress } from "./EditAddShippingAddress"

export const ShippingBody = ({
  isModified,
  setIsModified,
  selectedIndex,
  handleEditAdd,
  setRegion,
  handleCloseModal,
  setIsDefaultDeliverChecked,
}) => {
  const [shippingData, setShippingData] = useState([])
  const [isUpdate, setIsUpdate] = useState(false)

  const fetchData = async () => {
    try {
      const response = await client.get(
        `/Address/findAllAddress?memberId=${localStorage.getItem("userId")}`
      )
      setShippingData(response.data)
    } catch (error) {
      alert(error.response.data.message)
    }
  }
  useEffect(() => {
    fetchData()
  }, [isUpdate])

  const handleUpdate = () => {
    fetchData()
    setIsUpdate(!isUpdate)
  }

  return (
    <>
      {!isModified ? (
        <>
          {/* 배송지 목록 렌더링 */}
          {shippingData.length > 0 ? (
            shippingData.map((item, index) => (
              <ShippingCard
                key={index}
                index={index}
                item={item}
                handleEditAdd={handleEditAdd}
                setIsModified={setIsModified}
                handleUpdate={handleUpdate}
                handleCloseModal={handleCloseModal}
                setRegion={setRegion}
                setIsDefaultDeliverChecked={setIsDefaultDeliverChecked}
              />
            ))
          ) : (
            <p>배송지가 비어있습니다.</p>
          )}
        </>
      ) : (
        <EditAddShippingAddress
          item={shippingData[selectedIndex]}
          setIsModified={setIsModified}
          handleUpdate={handleUpdate}
        />
      )}
    </>
  )
}
