import React from "react"
import { Dropdown } from "react-bootstrap"

export default function OrderDropdown({ order, setOrder }) {
  return (
    <Dropdown style={{ alignContent: "center" }}>
      <Dropdown.Toggle variant="dropdown-basic-button" id="dropdown-basic">
        {order}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1" onClick={() => setOrder("이름순")}>
          이름순
        </Dropdown.Item>
        <Dropdown.Item href="#/action-3" onClick={() => setOrder("인기순")}>
          인기순
        </Dropdown.Item>
        <Dropdown.Item href="#/action-3" onClick={() => setOrder("평점순")}>
          평점순
        </Dropdown.Item>
        <Dropdown.Item href="#/action-3" onClick={() => setOrder("리뷰순")}>
          리뷰순
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}
