import React, { useEffect, useState } from "react"
import { Button, Form, InputGroup } from "react-bootstrap"
import dot from "../../Header/Img/dot.png"
import SearchList from "./SearchList"
import { useNavigate, useParams } from "react-router-dom"
import { client } from "../../API/api.js"

const SearchPage = () => {
  const navi = useNavigate()

  const { value: initialSearch } = useParams()
  const [list, setList] = useState([])
  const [input, setInput] = useState(initialSearch || "")

  const fetchAndSetData = async (query) => {
    const response = await client.get(
      `/Product/Search?name=${query}&page=0&size=20`
    )
    setList(response.data)
    console.log("Fetched data:", response.data)
  }

  useEffect(() => {
    if (initialSearch) {
      fetchAndSetData(initialSearch)
    }
  }, [initialSearch])

  const activeEnter = (e) => {
    if (e.key === "Enter" && input.length !== 0) {
      navi(`/search/${input}`)
      fetchAndSetData(input)
    }
  }

  const handleSearch = () => {
    if (input.length !== 0) {
      navi(`/search/${input}`)
      fetchAndSetData(input)
    }
  }

  return (
    <div style={{ marginLeft: "5%", marginRight: "5%", marginTop: "30px" }}>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <InputGroup className="mb-3" style={{ width: "50%" }}>
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-3"
            aria-label="Search"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => activeEnter(e)}
          />
          <Button variant="" size="sm" active onClick={handleSearch}>
            <img src={dot} alt="" width="20px" style={{ marginTop: "-5px" }} />
          </Button>
        </InputGroup>
      </div>

      <SearchList searchName={input} firstList={list} />
    </div>
  )
}

export default SearchPage
