import React, { useEffect, useState } from "react"
import ImageSlide from "./ImageSlide/ImageSlide"
import ItemList from "../Item/ItemList"
import FirstCategory from "./FirstCategory"
import * as S from "./FirstPage.style"
import ReviewSlide from "./ImageSlide/ReviewSlide"
import { client } from "../../API/api"
import MainPopup from "./MainPopup"
// top-section 이나 bottom-section을 가진 요소를 기준으로 넘어가지 않음
const FirstPage = () => {
  const [popupUrl, setPopupUrl] = useState("")
  const [showMainPop, setShowMainPop] = useState(false)

  useEffect(() => {
    const checkShowPopup = async () => {
      const today = new Date()
      const HOME_VISITED = localStorage.getItem("HOME_VISITED")

      // 서버에서 팝업 데이터를 가져오는 비동기 함수 호출
      const getPopupUrl = async () => {
        try {
          const response = await client.get("/Popup/findAllPopup")
          console.log("popupURL", response.data)
          if (response.data.length === 0) {
            return null // 데이터가 비어있으면 null 반환
          }
          return response.data[0].photoUrl // 데이터가 있으면 첫 번째 팝업 URL 반환
        } catch (error) {
          console.error("Error fetching popup URL:", error)
          return null // 오류 발생 시 null 반환
        }
      }

      // 팝업 URL 가져오기
      const url = await getPopupUrl()

      // URL이 없으면 팝업을 보여주지 않음
      if (!url) {
        setShowMainPop(false)
        return
      }

      // HOME_VISITED가 존재하고, 그 값이 현재 시간보다 나중이라면 모달을 보이지 않음
      if (HOME_VISITED && new Date(HOME_VISITED) > today) {
        setShowMainPop(false)
        return
      }

      // 조건에 맞으면 팝업 URL과 함께 모달을 보이게 함
      setPopupUrl(url)
      setShowMainPop(true)
    }

    // 1초 뒤에 팝업 여부를 확인
    window.setTimeout(checkShowPopup, 1000)
  }, [])

  return (
    <>
      <S.ImageSlideWrapper id={"top-section"}>
        <ImageSlide id="imageSlide" />
      </S.ImageSlideWrapper>
      <div className="container">
        <S.Box>
          <h2>
            <strong>카테고리</strong>
          </h2>
          <FirstCategory />
        </S.Box>
        <div className="centered-text">
          <h2>
            <strong>인기 상품</strong>
          </h2>
          <ItemList first={true} />
        </div>
        <S.Box>
          {/* 실시간 상품 후기 */}
          <ReviewSlide />
        </S.Box>
      </div>
      {showMainPop && (
        <MainPopup setShowMainPop={setShowMainPop} popupUrl={popupUrl} />
      )}
    </>
  )
}

export default FirstPage
