import React from "react"
import { Pagination } from "react-bootstrap"

/**
 * PaginationComponent는 페이지 네이션을 관리하는 컴포넌트입니다.
 *
 * @param {number} currentPage - 현재 페이지 번호입니다.
 * @param {number} totalPages - 총 페이지 수입니다.
 * @param {(page: number) => void} onPageChange - 페이지 변경 시 호출되는 함수입니다.
 */
export default function PaginationComponent({
  currentPage,
  totalPages,
  onPageChange,
}) {
  const handleFirstPage = () => onPageChange(0)
  const handlePrevPage = () =>
    onPageChange(currentPage > 0 ? currentPage - 1 : 0)
  const handleNextPage = () =>
    onPageChange(currentPage < totalPages - 1 ? currentPage + 1 : currentPage)
  const handleLastPage = () => onPageChange(totalPages - 1)

  // 페이지 네이션 아이템을 렌더링하는 함수
  const renderPaginationItems = () => {
    let items = []
    for (let number = 0; number < totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number + 1}
        </Pagination.Item>
      )
    }
    return items
  }

  return (
    <Pagination style={{ zIndex: 0 }}>
      <Pagination.First onClick={handleFirstPage} />
      <Pagination.Prev onClick={handlePrevPage} />
      {renderPaginationItems()}
      <Pagination.Next onClick={handleNextPage} />
      <Pagination.Last onClick={handleLastPage} />
    </Pagination>
  )
}
