import { Carousel, CloseButton, Modal } from "react-bootstrap"
import { useState } from "react"
import styled from "styled-components"

// 이미지 컴포넌트에서 동적으로 이미지를 받아 표시
const Image = ({ src }) => {
  return (
    <div style={{ height: "610px", width: "610px" }}>
      <img
        src={src}
        alt="review"
        style={{
          height: "100%",
          width: "100%",
          objectFit: "cover", // contain에서 cover로 변경
          display: "flex", // 이미지 중앙 정렬
          backgroundColor: "black",
        }}
      />
    </div>
  )
}

// Carousel 스타일 수정
const CustomCarousel = styled(Carousel)`
  .carousel-control-prev {
    margin-left: -120px;
    font-size: 50px;
  }

  .carousel-control-next {
    margin-right: -120px;
  }
  .carousel-control-prev-icon {
    width: 40px;
    height: 40px;
  }
  .carousel-control-next-icon {
    width: 40px;
    height: 40px;
  }
`

// Modal의 너비 및 배경을 커스터마이징
const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 640px; /* 모달의 최대 너비 설정 */
  }

  .modal-content {
    width: 610px; /* 모달 내부 컨텐츠의 고정 너비 설정 */
    background: rgba(0, 0, 0, 0);
    border: none;
  }
`

// 썸네일 스타일
const ThumbnailWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`

const Thumbnail = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  cursor: pointer;
  margin: 0 5px;
  border: ${(props) => (props.active ? "2px solid white" : "1px solid gray")};
  &:hover {
    border: 2px solid white;
  }
`

export const ReviewImgSlide = ({
  show,
  handleClose,
  reviewImgList = [], // 기본값으로 빈 배열을 설정
  selectedImgId,
}) => {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  const handleThumbnailClick = (selectedIndex) => {
    setIndex(selectedIndex)
  }

  return (
    <CustomModal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={true}
      centered
      style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
    >
      <CloseButton
        onClick={handleClose}
        style={{ fontSize: "25px", marginLeft: "auto", marginRight: "-120px" }}
        variant="white"
      />
      <CustomCarousel
        id={"ReviewSlide"}
        activeIndex={index}
        onSelect={handleSelect}
        interval={null}
      >
        {reviewImgList.length > 0 ? (
          reviewImgList.map((imgSrc, idx) => (
            <Carousel.Item key={idx}>
              <Image src={imgSrc} />
            </Carousel.Item>
          ))
        ) : (
          <Carousel.Item>
            <div
              style={{
                height: "610px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>No images to display</p>
            </div>
          </Carousel.Item>
        )}
      </CustomCarousel>

      {/* 썸네일을 나열하는 부분 */}
      <ThumbnailWrapper>
        {reviewImgList.length > 0 &&
          reviewImgList.map((imgSrc, idx) => (
            <Thumbnail
              key={idx}
              src={imgSrc}
              active={index === idx}
              onClick={() => handleThumbnailClick(idx)}
            />
          ))}
      </ThumbnailWrapper>
    </CustomModal>
  )
}
