import React from "react"
import { Dropdown } from "react-bootstrap"

export default function MonthDropdown({ month, setMonth }) {
  // 1월부터 12월까지의 배열 생성
  const months = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"]

  return (
    <Dropdown style={{ textAlign: "start" }}>
      <Dropdown.Toggle variant="dropdown-basic-button" id="dropdown-basic">
        {month}월
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {months.map((item, index) => (
          <Dropdown.Item
            key={index}
            href={`#/action-${index}`}
            onClick={() => setMonth(item)} // 선택한 월을 설정하는 함수 호출
          >
            {item}월
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
