import React, { useEffect, useRef } from "react"
import comImg from "./wonjeong_company.jpg"
import { useLocation } from "react-router-dom"
import a4 from "./IntroImg/a4.JPG"
import a5 from "./IntroImg/a5.JPG"
import d3 from "./IntroImg/d3.JPG"
import d4 from "./IntroImg/d4.JPG"
import e2 from "./IntroImg/e2.JPG"
import e3 from "./IntroImg/e3.JPG"
import g1 from "./IntroImg/g1.JPG"
import g4 from "./IntroImg/g4.JPG"
import gs3 from "./IntroImg/gs3.JPG"
import gs4 from "./IntroImg/gs4.JPG"
import m3 from "./IntroImg/m3.JPG"
import m5 from "./IntroImg/m5.JPG"

const CompanyIntro = () => {
  const cateList = [
    {
      title: "멸치명가 멸치",
      img1: m3,
      img2: m5,
      text:
        "삼천포 멸치 - 유속이 빠른 해역에서 자란 멸치를 어획하며 육질이 단단하고" +
        " 은백색의 맑은 빛깔을 띄며 영양이 우수합니다.." +
        "\n종류 = 세멸치, 소멸치, 중멸치, 대멸치 등",
      link: "/Company/Mualchi",
    },
    {
      title: "삼천포 어간장",
      img1: g1,
      img2: g4,
      text: "2년이상 발효 숙성시킨 멸치 숙성 원액에 5가지 천연재료(배, 양파, 무, 다시마, 매실)엑기스를 혼합하여 살균 여과시켜 액젓의 짠맛과 비린맛을 줄였으며, 감칠맛과 깊은 풍미를 살려 어떤 음식과도 잘 어우러지는 웰빙간장 입니다.",
      link: "/Company/GanJang",
    },
    {
      title: "멸치명가 眞액젓",
      img1: a4,
      img2: a5,
      text: "국내산 천일염을 사용한 2년이상 숙성시킨 남해안 멸치 100%신선한 원액을 그대로 담았습니다",
      link: "/Company/AcJut",
    },
    {
      title: "멸치명가 다시팩",
      img1: d3,
      img2: d4,
      text: "삼천포 신선한 멸치와 국내산 천연재료로 만들어 깊은 육수 맛을 만들어 줍니다.",
      link: "/Company/Dasi",
    },
    {
      title: "멸치명가 기타 건어물",
      img1: e2,
      img2: e3,
      text: "",
      link: "/Company/Etc",
    },
    {
      title: "원정푸드 선물세트",
      img1: gs3,
      img2: gs4,
      text: "",
      link: "/Company/GiftSet",
    },
  ]
  const location = useLocation()
  const itemRef = useRef([])

  // 버튼 클릭 시 스크롤 함수
  const scrollToItem = (scrollTo) => {
    if (itemRef.current[scrollTo]) {
      itemRef.current[scrollTo].scrollIntoView({ behavior: "smooth" })
    }
  }

  useEffect(() => {
    if (location.state && location.state.scrollTo !== undefined) {
      scrollToItem(location.state.scrollTo)
    }
  }, [location])

  return (
    <div>
      <div>
        <img
          src={comImg}
          alt="회사 이미지"
          style={{ width: "100%", textAlign: "center" }}
        />
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "2%",
          padding: "1%",
        }}
      >
        <div>
          <div style={{ backgroundColor: "white" }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "1.3rem",
                textAlign: "center",
                whiteSpace: "pre-wrap",
              }}
            >
              원정푸드(주) 홈페이지 방문을 진심으로 환영합니다.
            </p>
            <p
              style={{
                fontSize: "1.3rem",
                fontWeight: "bold",
                textAlign: "center",
                whiteSpace: "pre-wrap",
              }}
            >
              "원정푸드(주)는 삼천포수협건어물 99번중매인을 겸하고 있어 보다
              신선하고 믿을 수 있는 먹거리를 제공 합니다."
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: "2%",
          fontSize: "1.1rem",
          alignItems: "center",
          textAlign: "center",
          overflow: "hidden",
          whiteSpace: "pre-wrap",
        }}
      >
        <p>
          원정푸드(주)는 삼천포수협건어 99번 중매인을 22년간 겸하고 있어 신선한
          건어물을 공급하고 있습니다. 또한, 위생적인 제품 생산을 위해 전 직원이
          작업표준 및 위생관리 표준절차를 준수하고 있으며, 식품 안전성 문제에
          대응하기 위하여 HACCP시스템을 도입하여 적용하고 있습니다. 상품의 품질,
          안전한 먹거리를 저와 가족들이 먹는다는 마음으로 생산에 임하는 최고의
          기업이 되겠습니다.
        </p>
      </div>

      <div style={{ marginTop: "5%", padding: "1%", width: "100%" }}>
        {cateList.map((item, idx) => (
          <div
            ref={(el) => (itemRef.current[idx] = el)}
            key={idx}
            style={{ marginBottom: "100px" }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "-15px",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  borderBottom: "2px solid black",
                  paddingBottom: "10px",
                  fontSize: "20px",
                  // fontWeight: "bold",
                  fontWeight: "800",
                  fontFamily: `"Noto Sans","sans-serif"`,
                  color: "#474b53",
                }}
              >
                {item.title}
              </h3>
            </div>

            <hr
              style={{
                borderBottom: "2px solid black",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            />
            <div
              style={{
                padding: "1%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  marginTop: "1%",
                  marginRight: "1%",
                  width: "50%",
                }}
              >
                <img
                  src={item.img1}
                  alt="img1"
                  style={{ width: "100%", marginRight: "10px" }}
                />

                <img
                  src={item.img2}
                  alt="img2"
                  style={{ width: "100%", marginLeft: "10px" }}
                />
              </div>
            </div>
            <p style={{ padding: "1%", whiteSpace: "pre-wrap" }}>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CompanyIntro
