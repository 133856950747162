import React, { useState } from "react"
import styled from "styled-components"
import StarRating from "../StarRating"
import PaginationComponent from "../../../Common/PaginationComponent"
import { ReviewImgSlide } from "./ReviewImgSlide"

export default function ReviewInfoComponent({
  review,
  maskName,
  formatDate,
  reviewPage,
  setReviewPage,
  productObject,
}) {
  const [selectedReviewIdx, setSelectedReviewIdx] = useState(0)
  const [selectedImgIdx, setSelectedImgIdx] = useState(0)
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div>
      {review.size === 0 ? (
        <div>아직 구매평이 없습니다.</div>
      ) : (
        <>
          {review.list.map((item, idx) => (
            <ReviewContainer key={idx}>
              <UserId>{maskName(item.userName)}</UserId>
              <ReviewInfo>
                <StarRating rating={item.rating} size={"20px"} />
                <ReviewDate>{formatDate(item.date)}</ReviewDate>
              </ReviewInfo>

              {/* 고객이 리뷰 사진을 올리지 않아 기본 이미지가 리뷰 사진이 됐을 경우 숨김 처리 */}
              {productObject.photoUrl !== item.photoUrl &&
                item.photoList?.length > 0 && (
                  <div style={{ display: "flex" }}>
                    {item.photoList.map((photo, index) => (
                      <ImageContainer
                        key={index}
                        style={{ display: "flex", cursor: "pointer" }}
                        onClick={() => {
                          handleShow()
                          setSelectedImgIdx(index)
                          setSelectedReviewIdx(idx)
                        }}
                      >
                        <ReviewImage src={photo} alt={item.name} />
                      </ImageContainer>
                    ))}
                  </div>
                )}

              <ReviewText>{item.content}</ReviewText>
              <Divider />
            </ReviewContainer>
          ))}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <PaginationComponent
              currentPage={reviewPage}
              totalPages={review.totalPages}
              onPageChange={setReviewPage}
            />
          </div>
        </>
      )}

      {/* reviewImgList가 존재할 경우에만 ReviewImgSlide 컴포넌트를 렌더링 */}
      {review.list[selectedReviewIdx]?.photoList && (
        <ReviewImgSlide
          show={show}
          handleClose={handleClose}
          reviewImgList={review.list[selectedReviewIdx].photoList || []}
          selectedImgId={selectedImgIdx}
        />
      )}
    </div>
  )
}

// 전체 리뷰 컨테이너
const ReviewContainer = styled.div`
  text-align: left;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 16px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`

// 사용자 ID 스타일
const UserId = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
`

// 리뷰 정보 스타일
const ReviewInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

// 별점 및 날짜 스타일
const ReviewDate = styled.div`
  margin-left: auto;
  font-size: 14px;
  color: #666;
`

// 이미지 컨테이너 스타일
const ImageContainer = styled.div`
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 4px;
  margin: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

// 이미지 스타일
const ReviewImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover; /* 이미지가 컨테이너에 맞게 잘림 */
`

// 제목 스타일
const ReviewTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`

// 본문 스타일
const ReviewText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  color: #333;
`

// 구분선 스타일
const Divider = styled.hr`
  border: none;
  border-top: 1px solid #eee;
  margin: 16px 0;
`
