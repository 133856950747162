import React from "react"
import { useRecoilValue } from "recoil"
import LoginForm from "./LoginForm"
import AlreadyLogin from "./AlreadyLogin"
import { login } from "../../../App"

const Login = () => {
  const log = useRecoilValue(login)
  console.log(log)
  const isLogin = (lo) => {
    if (log) return <AlreadyLogin />
    else return <LoginForm />
  }
  return <div style={{ marginTop: "10px" }}>{isLogin(log)}</div>
}

export default Login

/*
  로그인 : 카카오, 네이버, 기존 회원
*/
