import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { client } from "../../API/api"
import styled from "styled-components"
import { Dash, PencilSquare, Plus } from "react-bootstrap-icons"
import { Button, Modal, Pagination } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import { admin } from "../../../App"

const Board = ({ cmd }) => {
  const [data, setData] = useState({ size: 0, list: [], totalPages: 1 })
  const [expandedIds, setExpandedIds] = useState([])
  const [page, setPage] = useState(0)
  const navigate = useNavigate()
  const [selectItem, setSelectedItem] = useState(null)
  const [show, setShow] = useState(false)
  const isAdmin = useRecoilValue(admin)
  // 데이터 받아오기
  useEffect(() => {
    const fetchAndSetData = async () => {
      const response = await client.get(
        `/Notice/findAllNotice?page=${page}&size=10`
      )
      setData(response.data)
    }
    fetchAndSetData()
  }, [page]) // page가 변경될 때마다 데이터를 가져오도록 설정

  // 클릭 시 확장됨
  const toggleExpand = (id) =>
    setExpandedIds((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    )

  // 날짜 형식 변경용
  const formatDate = (dateString) => {
    const [date] = dateString.split("T")
    return date
  }

  const renderPaginationItems = () => {
    const items = []
    for (let i = 0; i < (data ? data.totalPages : 1); i++) {
      items.push(
        <Pagination.Item key={i} active={i === page} onClick={() => setPage(i)}>
          {i + 1}
        </Pagination.Item>
      )
    }
    return items
  }

  const handleClose = () => setShow(false)

  const handleShow = (item) => {
    setSelectedItem(item)
    setShow(true)
  }

  const handleDelete = async () => {
    if (selectItem && selectItem.id) {
      try {
        await client.delete(`/Notice/deleteNotice?id=${selectItem.id}`)
        setData((prevData) => ({
          ...prevData,
          list: prevData.list.filter((item) => item.id !== selectItem.id),
        }))
        handleClose()
      } catch (error) {
        console.error("삭제 중 오류 발생:", error)
      }
    }
  }

  return (
    <div>
      <div style={{ fontSize: "20px", fontWeight: "bold" }}>공지사항</div>
      <span>현재 {data.size}개의 글이 있습니다.</span>
      <div style={{ display: "flex", float: "right" }}>
        {isAdmin && (
          <Button onClick={() => navigate("/Board")}>
            <PencilSquare style={{ marginRight: "5px" }} size="20px" />
            <span style={{ fontSize: "16px" }}>글쓰기</span>
          </Button>
        )}
      </div>
      <hr />
      {data.list.map((item) => (
        <NoticeBox
          key={item.id}
          onClick={() => toggleExpand(item.id)}
          expanded={expandedIds.includes(item.id)}
        >
          <NoticeHeader>
            <span style={{ color: "#5a6f85", fontWeight: "bold" }}>
              중요공지
            </span>
            <span style={{ fontWeight: "bold", fontSize: "16px" }}>
              {item.title}
            </span>
            <NoticeDate>{formatDate(item.date)}</NoticeDate>
            <button>
              {expandedIds.includes(item.id) ? (
                <Dash size="20px" />
              ) : (
                <Plus size="20px" />
              )}
            </button>
          </NoticeHeader>
          {expandedIds.includes(item.id) && (
            <div>
              <NoticeText>{item.content}</NoticeText>
              {isAdmin && (
                <ButtonWrapper>
                  <Button
                    variant="secondary"
                    onClick={() => navigate(`/Board/${item.id}`)}
                  >
                    수정하기
                  </Button>
                  <Button variant="primary" onClick={() => handleShow(item)}>
                    삭제하기
                  </Button>
                </ButtonWrapper>
              )}
            </div>
          )}
        </NoticeBox>
      ))}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination style={{ zIndex: 0 }}>
          <Pagination.First onClick={() => setPage(0)} />
          <Pagination.Prev onClick={() => setPage(page > 0 ? page - 1 : 0)} />
          {renderPaginationItems()}
          <Pagination.Next
            onClick={() =>
              setPage(page < data.totalPages - 1 ? page + 1 : page)
            }
          />
          <Pagination.Last onClick={() => setPage(data.totalPages - 1)} />
        </Pagination>
      </div>
      <Modal show={show} onHide={handleClose} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>삭제 확인</Modal.Title>
        </Modal.Header>
        <Modal.Body>정말로 {selectItem?.title}를 삭제하시겠습니까?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            취소
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            삭제
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
`

const NoticeBox = styled.div`
  border: 1px solid #dee2e6;
  padding: 10px;
  margin-bottom: 10px;
  min-height: 70px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition:
    max-height 0.3s ease-out,
    padding 0.3s ease-out;
  overflow: hidden;
  max-height: ${(props) => (props.expanded ? "500px" : "70px")};
  padding: ${(props) => (props.expanded ? "20px" : "10px")};
`

const NoticeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    flex: 1;
    margin: 0 5px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
  }
`

const NoticeDate = styled.span`
  margin-left: auto;
  font-size: 14px;
  color: #6c757d;
`

const NoticeText = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #dee2e6;
`

export default Board
