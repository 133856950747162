import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { client } from "../../API/api"
import { useNavigate } from "react-router-dom"

const CustomText = styled.span`
  font-size: 19px;
`

const PriceText = styled.span`
  font-size: 19px;
  margin-left: 10px;
  color: black;
  transition:
    color 0.3s,
    text-decoration 0.3s;
`

const OptionContainer = styled.div`
  margin-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 15px;
  cursor: pointer;
  &:hover {
    color: blue;
    text-decoration: underline;
  }
`

export const MoreOption = ({ item }) => {
  const [selectedOption, setSelectedOption] = useState(item.id) // 기본 선택을 item.id로 설정
  const [options, setOptions] = useState([])
  const [groupId, setGroupId] = useState(item.groupId)
  const navi = useNavigate()
  const [isGrouped, setIsGrouped] = useState(true)
  // item이 변경될 때 groupId와 productId를 업데이트합니다.
  useEffect(() => {
    setGroupId(item.groupId)
    setSelectedOption(item.id) // 기본 선택 업데이트
  }, [item])

  const handleChange = (productId) => {
    navi(`/item/${productId}`)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (groupId) {
        try {
          const response = await client.get(
            `ProductOption/findAllOptionsByGroupId?groupId=${groupId}`
          )
          setOptions(response.data)
          setIsGrouped(true)
        } catch (error) {
          console.error("Error fetching options:", error)
        }
      } else {
        setIsGrouped(false)
      }
    }
    fetchData()
  }, [groupId])
  console.log(options)
  return (
    <>
      {isGrouped && (
        <div style={{ marginTop: "30px" }}>
          <span style={{ fontWeight: "400", fontSize: "19px" }}>
            더 많은 옵션보기
          </span>
          <div>
            {options.length > 0 ? (
              options.map((option) => (
                <OptionContainer
                  key={option.productId}
                  onClick={() => handleChange(option.productId)}
                >
                  <input
                    type="radio"
                    name="option"
                    style={{ marginRight: "10px" }}
                    checked={selectedOption === option.productId} // 선택된 옵션과 비교
                    onChange={() => handleChange(option.productId)}
                  />
                  <CustomText style={{ marginRight: "20px" }}>
                    {`${option.optionName}`}
                  </CustomText>
                  <PriceText>
                    {option.productPrice
                      ? `${option.productPrice.toLocaleString()}원`
                      : "가격 정보 없음"}
                  </PriceText>
                </OptionContainer>
              ))
            ) : (
              <p>옵션을 불러오는 중입니다...</p>
            )}
          </div>
        </div>
      )}
    </>
  )
}
