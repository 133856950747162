import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import * as S from "./Header.style" // 스타일 컴포넌트
import { selectCategory } from "../../App"
import { useRecoilState } from "recoil"
import HeaderMenu from "./Menu/HeaderMenu"

const adminLinks = [
  {
    label: "Admin",
    link: "/Admin/DashBoard",
    fontColor: "black",
    text: "관리자 대시보드",
  },
  {
    label: "ProductionDefective",
    link: "/Admin/ProductionDefective",
    fontColor: "black",
    text: "생산/불량 개수",
  },
  {
    label: "ProductionOrderManage",
    link: "/Admin/ProductionOrderManage",
    fontColor: "black",
    text: "생산/주문 관리",
  },
  {
    label: "SystemMonitor",
    link: "/Admin/SystemMonitor",
    fontColor: "black",
    text: "시스템 모니터링",
  },
  {
    label: "AddItemPage",
    link: "/Admin/AddItemPage",
    fontColor: "black",
    text: "상품 추가",
  },
  {
    label: "QnaReviewManage",
    link: "/Admin/QnaReviewManage",
    fontColor: "black",
    text: "문의/리뷰",
  },
  {
    label: "AnnouncementPopUp",
    link: "/Admin/AnnouncementPopUp",
    fontColor: "black",
    text: "공지사항 팝업",
  },
]
const mypageLinks = [
  {
    label: "orders",
    link: "/MyPage/Orders",
    fontColor: "black",
    text: "주문조회",
  },
  {
    label: "cart",
    link: "/MyPage/Cart",
    fontColor: "black",
    text: "장바구니",
  },
  {
    label: "reviews",
    link: "/MyPage/Reviews",
    fontColor: "black",
    text: "리뷰관리",
  },
  {
    label: "qna",
    link: "/MyPage/Qna",
    fontColor: "black",
    text: "문의 내역",
  },
  {
    label: "userinfo",
    link: "/MyPage/Userinfo",
    fontColor: "black",
    text: "회원정보수정",
  },
  {
    label: "widthdraw",
    link: "/MyPage/Withdraw",
    fontColor: "black",
    text: "회원탈퇴",
  },
]
const AdminSideMenu = ({ isMyPage, isAdmin }) => {
  const navi = useNavigate()
  const [selectCate, setSelectCate] = useRecoilState(selectCategory)
  const [showOffcanvas, setShowOffcanvas] = useState(false)

  const [naviLinks, setNaviLinks] = useState([])
  const handleNavClick = (link, categoryName) => {
    navi(link)
    setSelectCate(categoryName)
    setShowOffcanvas(false)
  }
  useEffect(() => {
    if (isAdmin) {
      console.log("change to admin")
      setNaviLinks(adminLinks)
    }
    if (isMyPage) {
      console.log("change to mypage")

      setNaviLinks(mypageLinks)
    }
  }, [])
  return (
    <S.ResponseContainer>
      <HeaderMenu
        selectCate={selectCate}
        categoryMenu={[]}
        naviLinks={naviLinks}
        setSelectCate={setSelectCate}
        handleNavClick={handleNavClick}
      />
    </S.ResponseContainer>
  )
}

export default AdminSideMenu
