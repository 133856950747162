import React, { useEffect, useState } from "react"
import { Button, Dropdown, DropdownButton } from "react-bootstrap"
import { Input } from "reactstrap"
import { useRecoilValue } from "recoil"
import { category } from "../../../App"

export default function SearchItem({
  categoryName,
  setCategoryName,
  setCategoryId,
  setPage,
  search,
  setSearch,
}) {
  const categoryList = useRecoilValue(category)

  useEffect(() => {
    setPage(0) // 페이지를 첫 페이지로 리셋
  }, [categoryName, setPage])

  const [value, setValue] = useState("")

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        marginBottom: "10px",
      }}
    >
      <DropdownButton
        variant="outline-secondary"
        id="dropdown-basic-button"
        title={categoryName}
      >
        <Dropdown.Item
          onClick={() => {
            setCategoryName("전체")
            setCategoryId("")
          }}
        >
          전체
        </Dropdown.Item>
        {categoryList.map((item) => (
          <Dropdown.Item
            onClick={() => {
              setCategoryName(item.categoryName)
              setCategoryId(item.id)
            }}
            key={item.id}
          >
            {item.categoryName}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <Input
        placeholder="상품명을 검색하세요"
        value={value}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            // 엔터키가 눌렸을 때 실행할 로직
            setSearch(value)
          }
        }}
        onChange={(e) => setValue(e.target.value)}
        style={{ width: "200px" }}
      />

      <Button
        onClick={() => {
          setSearch(value)
          setValue("")
        }}
      >
        검색
      </Button>
    </div>
  )
}
