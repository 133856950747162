import React from "react"
import { useNavigate } from "react-router-dom"
import * as S from "./CategoryList.style" // 스타일 컴포넌트
import HeaderMenu from "./Menu/HeaderMenu"
import HeaderSideMenu from "./HeaderSideMenu"
import SearchMenu from "./Menu/SearchMenu"

/**
 *
 * @param {*}  selectCate HeaderMenu 용
 * @param {*}  setSelectCate HeaderMenu 용
 * @param {*}  categoryMenu HeaderMenu 용
 * @param {*}  naviLinks HeaderMenu 용
 * @param {*}  showOffcanvas HeaderMenu 용
 * @param {*} setShowOffcanvas HeaderMenu 용
 * @param {*}  showSideBtn HeaderMenu 용
 * @param {*}  handleNavClick HeaderMenu 용
 *
 * @param {*}  input SearchModal 용
 * @param {*}  setInput SearchModal 용
 * @param {*} activeEnter SearchModal 용
 * @param {*}  handleSearchClick SearchModal 용
 * @param {*}  setShowSearchModal SearchModal 용
 * @param {*}  showSearchModal SearchModal 용
 */
const CategoryList = ({
  selectCate,
  setSelectCate,
  categoryMenu,
  naviLinks,
  showOffcanvas,
  setShowOffcanvas,
  showSideBtn,
  handleNavClick,
  // SearchModal
  input,
  setInput,
  activeEnter,
  handleSearchClick,
  setShowSearchModal,
  showSearchModal,
}) => {
  const navi = useNavigate()

  console.log("showSideBtn", showSideBtn)
  console.log(categoryMenu)
  return (
    <S.CategoryContainer>
      <div>
        {/* 사이드 버튼 */}
        {!showSideBtn && (
          <HeaderSideMenu
            selectCate={selectCate}
            setSelectCate={setSelectCate}
            categoryMenu={categoryMenu}
            naviLinks={naviLinks}
            handleNavClick={handleNavClick}
            showOffcanvas={showOffcanvas}
            setShowOffcanvas={setShowOffcanvas}
          />
        )}
      </div>

      {/*본 메뉴 */}
      <HeaderMenu
        selectCate={selectCate}
        categoryMenu={categoryMenu}
        naviLinks={naviLinks}
        setSelectCate={setSelectCate}
        handleNavClick={handleNavClick}
      />
      {/* 검색 */}

      {!showSideBtn && (
        <SearchMenu
          input={input}
          setInput={setInput}
          activeEnter={activeEnter}
          handleSearchClick={handleSearchClick}
          setShowSearchModal={setShowSearchModal}
          showSearchModal={showSearchModal}
        />
      )}
    </S.CategoryContainer>
  )
}

export default CategoryList
