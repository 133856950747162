import React, { useEffect, useState } from "react"
import Node from "../Item/ItemNode"
import OrderDropdown from "../Item/OrderDropdown"
import { useParams } from "react-router-dom"

const SearchList = ({ firstList }) => {
  const [data, setData] = useState([])
  const [sortedData, setSortedData] = useState([])
  const [order, setOrder] = useState("이름순")
  const { value: searchName } = useParams() // useParams().value 구조 분해 할당

  // 데이터를 새로 설정하는 useEffect
  useEffect(() => {
    if (firstList && firstList.list) {
      setData(firstList.list)
    }
  }, [firstList, searchName])

  // 정렬을 새로 설정하는 useEffect

  useEffect(() => {
    const sortData = () => {
      let sorted = [...data]
      if (order === "이름순") {
        sorted.sort((a, b) => a.name.localeCompare(b.name))
      } else if (order === "평점순") {
        sorted.sort((a, b) => b.rating - a.rating)
      } else if (order === "리뷰순") {
        sorted.sort((a, b) => (b.reviewCount || 0) - (a.reviewCount || 0))
      } else if (order === "판매순") {
        sorted.sort((a, b) => (b.saleCount || 0) - (a.saleCount || 0))
      }
      setSortedData(sorted)
    }
    sortData()
  }, [data, order])

  const len = firstList.totalElements

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span style={{ fontSize: "13px" }}>
          {searchName}의 {data.length}개의 검색 결과
        </span>
        <OrderDropdown order={order} setOrder={setOrder} />
      </div>
      <hr />
      <div className="item-grid">
        {sortedData.map((item) => (
          <Node key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}

export default SearchList
