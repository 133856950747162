import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { itemId, shoppingCart } from "../../../App"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import cart from "./Img/addCart.png"
import "../../../CSS/ConBox/Item/ItemNode.css"
import StarRating from "./StarRating"

const ItemNode = ({ item }) => {
  const navi = useNavigate()
  const [it, setIt] = useRecoilState(itemId)
  const [shopping, setShopping] = useRecoilState(shoppingCart)
  const [showTooltip, setShowTooltip] = useState(false)

  const setLocalStorageItem = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value))

  const addShopping = (nowitem, event) => {
    // 이벤트 전파를 막아서 이미지 클릭 이벤트가 발생하지 않도록 합니다.
    event.stopPropagation()

    const itemIndex = shopping.findIndex((item) => item.id === nowitem.id)
    let newList

    if (itemIndex !== -1) {
      // Item already exists, increase quantity
      newList = shopping.map((item, index) => {
        if (index === itemIndex) {
          return { ...item, quantity: item.quantity + 1 }
        }
        return item
      })
    } else {
      // Item does not exist, add new item with quantity 1
      newList = [...shopping, { ...nowitem, quantity: 1 }]
    }

    setLocalStorageItem("shoppingCart", newList)
    setShopping(newList)

    // Show tooltip
    setShowTooltip(true)

    // Hide tooltip after 2 seconds
    setTimeout(() => setShowTooltip(false), 2000)
  }

  const additional = () => {
    const today = new Date()
    const itemUploadTime = new Date(item.date)
    let returnCompo = []
    if (today - itemUploadTime <= 7 * 24 * 60 * 60 * 1000)
      returnCompo.push(
        <div key="tag new-tag" className="tag new-tag">
          NEW
        </div>
      )
    if (item.saleCount >= 10000)
      returnCompo.push(
        <div key="tag hot-tag" className="tag hot-tag">
          HOT
        </div>
      )
    return <div className="tag-container">{returnCompo}</div>
  }

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {`${item.name}이(가) 장바구니에 추가되었습니다.`}
    </Tooltip>
  )

  return (
    <div
      className="item"
      onMouseEnter={() => setIt(item)}
      onClick={() => navi(`/Item/${item.id}`)} // 이미지 클릭 시 네비게이션 수행
    >
      <div className="image">
        <div className="img-container">
          <img src={item.photoUrl} alt="사진" className="item-img" />
          <div className="cart-button-container">
            <OverlayTrigger
              placement="top"
              show={showTooltip}
              overlay={renderTooltip}
            >
              <button
                className="nodeBtn"
                onClick={(event) => addShopping(item, event)}
              >
                <img
                  src={cart}
                  alt="장바구니"
                  className="icon-small"
                  style={{ width: "25px", height: "25px", zIndex: 10 }}
                />
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="info">
        <div className="content" onClick={() => navi(`/Item/${item.id}`)}>
          <p className="title" style={{ fontSize: "18px" }}>
            {item.name}
          </p>
          <div className="price" style={{ fontSize: "16px" }}>
            {item.price.toLocaleString()}원
          </div>
          {additional()}
          <div className="rating">
            <StarRating rating={parseFloat(item.rating)} />
            <span className="rating-text"> ({item.reviewCount})</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemNode
