import React, { useEffect, useState } from "react"
import imageCompression from "browser-image-compression"
import { Input, Spinner } from "reactstrap"

const ThumbnailAddComponent = ({ image, setImage }) => {
  const [previewUrl, setPreviewUrl] = useState(image || "") // 서버 URL 기본값 설정
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    // 서버에서 URL이 주어졌을 때는 createObjectURL을 사용하지 않음
    if (!image) {
      setPreviewUrl("")
    } else if (image && image instanceof File) {
      // 새로운 파일이 업로드될 경우 createObjectURL 사용
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl)
      }
      setPreviewUrl(URL.createObjectURL(image))
    } else {
      setPreviewUrl(image)
    }
  }, [image])

  const handleImageChange = async (e) => {
    const file = e.target.files[0]

    if (file && file.name) {
      setLoading(true) // 압축 시작 전에 로딩 상태로 설정

      const fileExtension = file.name.split(".").pop().toLowerCase()

      try {
        if (fileExtension === "webp") {
          setImage(file)
          setPreviewUrl(URL.createObjectURL(file))
        } else {
          const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            fileType: "image/webp",
          }
          const compressedFile = await imageCompression(file, options)

          const newFileName = `${file.name.split(".").slice(0, -1).join(".")}.webp`
          const webpFile = new File([compressedFile], newFileName, {
            type: "image/webp",
          })

          setImage(webpFile)
          setPreviewUrl(URL.createObjectURL(webpFile))
        }
      } catch (error) {
        console.error("Image compression failed:", error)
      } finally {
        setLoading(false) // 압축이 끝난 후 로딩 상태 해제
      }
    } else {
      console.error("No file selected or file name is undefined.")
    }
  }

  return (
    <div>
      <Input type="file" onChange={handleImageChange} disabled={loading} />
      {loading ? (
        <Spinner color="primary" />
      ) : (
        previewUrl && <img src={previewUrl} alt="Thumbnail" width="100%" />
      )}
    </div>
  )
}

export default ThumbnailAddComponent
