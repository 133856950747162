import React, { useMemo, useState } from "react"
import Button from "react-bootstrap/Button"
import RemoteNode from "./RemoteNode"
import left from "./Img/left.png"
import right from "./Img/right.png"
import * as S from "./RemoteCart.style"
import { useNavigate } from "react-router-dom"

const ITEMS_PER_PAGE = 4
const RemoteCart = ({ totalQuantity, list, setList }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const navi = useNavigate()

  // 그룹화된 항목
  const groupedItems = useMemo(() => {
    const groups = list.reduce((acc, item) => {
      if (acc[item.id]) {
        acc[item.id].count += 1
      } else {
        acc[item.id] = { ...item, count: 1 }
      }
      return acc
    }, {})
    return Object.values(groups)
  }, [list])

  const totalPages = useMemo(() => {
    if (groupedItems.length === 0) {
      return 1
    }
    return Math.ceil(groupedItems.length / ITEMS_PER_PAGE)
  }, [groupedItems])

  const handleLeft = () => setCurrentPage((prev) => Math.max(prev - 1, 1))
  const handleRight = () =>
    setCurrentPage((prev) => Math.min(prev + 1, totalPages))

  const deleteItem = (itemID) => {
    const updatedList = list.filter((item) => itemID !== item.id)
    setList(updatedList)
    localStorage.setItem("shoppingCart", JSON.stringify(updatedList))
  }

  const reduceQuantity = (itemID) => {
    const updatedList = list.map((item) => {
      if (itemID === item.id && item.quantity > 1) {
        return { ...item, quantity: item.quantity - 1, count: item.count - 1 }
      }
      return item
    })
    setList(updatedList)
    localStorage.setItem("shoppingCart", JSON.stringify(updatedList))
  }

  const addQuantity = (itemID) => {
    const updatedList = list.map((item) => {
      if (itemID === item.id) {
        return { ...item, quantity: item.quantity + 1, count: item.count + 1 }
      }
      return item
    })
    setList(updatedList)
    localStorage.setItem("shoppingCart", JSON.stringify(updatedList))
  }

  const clearCart = () => {
    setList([])
    localStorage.removeItem("shoppingCart")
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
  const visibleItems = groupedItems.slice(
    startIndex,
    startIndex + ITEMS_PER_PAGE
  )

  // 가격 합산 계산
  const totalPrice = groupedItems.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  )

  const cart = localStorage.getItem("shoppingCart")
  const totalShipping = 3500

  return (
    <S.RemoteCartContainer>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            margin: "0 5px",
          }}
        >
          <S.PaginationText> 총 {totalQuantity}개</S.PaginationText>
          <S.PaginationText>
            {currentPage} / {totalPages}
          </S.PaginationText>
        </div>
        <S.Group1>
          <S.RemoteCartButton
            src={left}
            alt="left"
            onClick={handleLeft}
            style={{ display: currentPage === 1 ? "none" : "block" }}
          />
          <S.RemoteCartItems>
            {groupedItems.length === 0 ? (
              <S.EmptyCartMessage
                style={{ display: "flex", verticalAlign: "center" }}
              >
                장바구니가 비었습니다.
              </S.EmptyCartMessage>
            ) : (
              visibleItems.map((item) => (
                <S.RemoteCartItem key={item.ID}>
                  <RemoteNode
                    item={item}
                    count={item.count}
                    onDelete={deleteItem}
                  />
                  <S.RemoteCartItemDetails>
                    <span>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ width: "26px", marginRight: "10px" }}
                        onClick={() => reduceQuantity(item.id)}
                      >
                        -
                      </Button>
                      {item.quantity}개
                      <Button
                        size="sm"
                        variant="outline-primary"
                        style={{ marginLeft: "10px" }}
                        onClick={() => addQuantity(item.id)}
                      >
                        +
                      </Button>
                    </span>
                  </S.RemoteCartItemDetails>
                </S.RemoteCartItem>
              ))
            )}
          </S.RemoteCartItems>
          <S.RemoteCartButton
            src={right}
            alt="right"
            onClick={handleRight}
            style={{
              display:
                currentPage === totalPages || totalPages <= 1
                  ? "none"
                  : "block",
            }}
          />
        </S.Group1>
      </div>
      <S.Group2>
        <S.RemoteCartSummary>
          <span className="remote-cart-total">장바구니 총 주문 금액</span>
          <br />
          <span className="remote-cart-subtotal">
            주문 금액 {totalPrice.toLocaleString()}원 + 배송비
            {totalShipping.toLocaleString()}원
          </span>
          <br />
          <S.RemoteCartGrandTotal>
            {(totalPrice + totalShipping).toLocaleString()}원
          </S.RemoteCartGrandTotal>
          <Button
            variant="primary"
            style={{ width: "100%" }}
            onClick={() => {
              if (cart.length > 2) {
                navi("/Pay")
              } else {
                alert("장바구니가 비어있습니다.")
              }
            }}
          >
            <strong>선택상품 주문</strong>
          </Button>
        </S.RemoteCartSummary>
      </S.Group2>

      <S.RemoteCartClear
        variant="outline-primary"
        className="remote-cart-clear"
        onClick={clearCart}
      >
        장바구니 비우기
      </S.RemoteCartClear>
    </S.RemoteCartContainer>
  )
}

export default RemoteCart
