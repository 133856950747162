import React from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import MainForm from "./Component/MainForm"
import { atom, selector, selectorFamily } from "recoil"
import { BrowserRouter as Router } from "react-router-dom"
import { client } from "./Component/API/api"

export const loadingState = atom({
  key: "loadingState",
  default: false,
})
export const errorState = selector({
  key: "errorState",
  get: async ({ get }) => {
    try {
      const response = await client.get("/Popup/findAllPopup")
      console.log(response.status)
      if (response.status === 200) {
        return false
      } else {
        return true
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      return true
    }
  },
})

const getLocalStorageItem = (key) => {
  const item = localStorage.getItem(key)
  return item ? JSON.parse(item) : []
}

export const login = atom({
  //로그인의 여부
  key: "login",
  default: false,
})

export const itemId = atom({
  //현재 지목 중인 상품
  key: "itemId",
  default: "0",
})

export const category = selector({
  key: "category",
  get: async () => {
    try {
      const response = await client.get("/Category/getAllCategory")
      return response.data
    } catch (error) {
      console.error("Error fetching data:", error)
      return []
    }
  },
})

export const selectCategory = atom({
  key: "selectCategory",
  default: "",
})

export const categoryData = atom({
  key: "categoryData",
  default: {},
})

export const categoryDataState = selectorFamily({
  key: "categoryDataState",
  get: (categoryId) => async () => {
    try {
      if (categoryId !== undefined) {
        const response = await client.get(
          `/Product/findProductsByCategoryId?categoryId=${categoryId}&page=0&size=20`
        )
        // const data = await response;
        //console.log("data", data);
        return response.data
      }
    } catch (error) {
      console.error("Error fetching data:", error)
      throw error
    }
  },
})

// export const categoryDataState = atom({
//   key: "categoryDataState",
//   default: {},
// });

export const UserInfo = atom({
  key: "UserInfo",
  default: {},
})

export const searchByName = selectorFamily({
  key: "searchByName",
  get:
    ({ name, page, size }) =>
    async () => {
      try {
        const response = await client.get(
          `/Product/Search?name=${name}&page=${page}&size=${size}`
        )
        // const data = await response;
        return response.data
      } catch (error) {
        console.error("Error fetching data:", error)
        return []
      }
    },
})

export const searchCategoryData = selectorFamily({
  key: "searchCategoryData",
  get:
    ({ name, page, size }) =>
    async () => {
      if (name !== "") {
        try {
          const response = await client.get(
            `/Product/findProductsByCategoryId?categoryId=${name}&page=${page}&size=${size}`
          )

          // const data = await response;
          //console.log("data", data);
          return response.data
        } catch (error) {
          console.error("Error fetching data:", error)
          return []
        }
      }
    },
})
//판매량 순
export const itemData = selectorFamily({
  key: "itemData",
  get:
    ({ page, size }) =>
    async () => {
      try {
        const response = await client.get(
          `/Product/GetProductBySaleCount?page=${page}&size=${size}`
        )
        return response.data
      } catch (error) {
        console.error("Error fetching data:", error)
        return []
      }
    },
})

export const productByDate = selectorFamily({
  key: "productByDate",
  get:
    ({ page, size }) =>
    async () => {
      try {
        const response = await client.get(
          `/Product/GetProductByDate?page=${page}&size=${size}`
        )
        return response.data
      } catch (error) {
        console.error("Error fetching data:", error)
        return []
      }
    },
})

export const firstReview = selector({
  key: "firstReview",
  get: async () => {
    try {
      const response = await client.get("/Review/getAllReview")
      return response.data
    } catch (error) {
      console.error("Error fetching data:", error)
      return []
    }
  },
})

export const shoppingCart = atom({
  //장바구니 리스트
  key: "shoppingCart",
  default: getLocalStorageItem("shoppingCart"),
})
export const deliveryFee = atom({
  //택배비
  key: "deliveryFee",
  default: 3500,
})

export const wishList = atom({
  //찜 리스트
  key: "wishList",
  default: [],
})

export const boardData = atom({
  //게시글 리스트
  key: "boardData",
  default: [],
})

export const boardId = atom({
  //지목 중인 게시글 아이디
  key: "boardId",
  default: "0",
})
export const QnaComment = selectorFamily({
  key: "QnaComment",
  get: (qnaId) => async () => {
    try {
      const response = await client.get(
        `/Comment/findCommentsByQnaId?qnaId=${qnaId}`
      )
      return response.data
    } catch (error) {
      console.error("Error fetching data:", error)
      return []
    }
  },
})

export const footerPositionState = atom({
  key: "footerPosition",
  default: { top: 0, left: 0 },
})

export const imageSlidePositionState = atom({
  key: "imageSlidePosition",
  default: { top: 0, left: 0 },
})
export const UAccessToken = atom({
  key: "accessToken",
  default: "",
})

export const serverUrl = atom({
  key: "serverUrl",
  default: process.env.REACT_APP_SERVER_URL,
})
export const userName = atom({
  key: "userName",
  default: "",
})
export const adminPage = atom({
  key: "adminPage",
  default: false,
})
export const mobile = atom({
  key: "mobile",
  default: false,
})
export const myPage = atom({
  key: "myPage",
  default: false,
})
export const pay = atom({
  key: "pay",
  default: false,
})

// userInfo
export const UNickName = atom({
  key: "UNickName",
  default: "",
})
export const UAddress = atom({
  key: "UAddress",
  default: "",
})
export const UDetailAddress = atom({
  key: "UEmail",
  default: "",
})
export const UPostCode = atom({
  key: "UPostCode",
  default: "",
})
export const UPhoneNumber = atom({
  key: "UPhoneNumber",
  default: "",
})

export const UProfileUrl = atom({
  key: "UprofileUrl",
  default: "",
})
export const URole = atom({
  key: "URole",
  default: "",
})
//type은 필요한가?
export const UType = atom({
  key: "UType",
  default: "",
})
export const UId = atom({
  key: "UId",
  default: "",
})
// role이 admin 인지
export const admin = atom({
  key: "admin",
  default: false,
})

function App() {
  return (
    <div className="App" style={{ overflow: "auto", whiteSpace: "nowrap" }}>
      <Router>
        <MainForm />
      </Router>
    </div>
  )
}

export default App
