import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap"
import DaumPostcode from "react-daum-postcode"
import { Input } from "reactstrap"
import { client } from "../../../API/api"

const Section = styled.div`
  padding: 20px;
  border-bottom: 1px solid #ddd;
`

const Title = styled.h2`
  margin-bottom: 20px;
`

const Label = styled(Form.Label)`
  font-weight: bold;
`

const Value = styled.span`
  display: block;
  padding: 5px 0;
`

const EditButton = styled(Button)`
  margin-left: 10px;
  font-size: 0.9rem;
`

const SaveButton = styled(Button)`
  margin-right: 10px;
`

const CancelButton = styled(Button)`
  background-color: #ccc;
  border-color: #ccc;
`

const EditContainer = styled.div`
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const UserInfo = () => {
  const [user, setUser] = useState({})
  const userId = localStorage.getItem("userId")
  const accessToken = localStorage.getItem("access_token")
  const [show, setShow] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [formData, setFormData] = useState({})
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(
          `/Member/user-info?access_token=${accessToken}`
        )
        const { nickname, phoneNumber, postCode, address, detailAddress } =
          response.data
        console.log("response:data", response.data)
        setUser({
          name: nickname,
          phoneNumber,
          postCode,
          address,
          detailAddress,
        })
        setFormData({
          name: nickname,
          phoneNumber,
          postCode,
          address,
          detailAddress,
        })
      } catch (error) {
        console.error("Error fetching user info:", error)
      }
    }

    if (accessToken) {
      fetchData()
    }
  }, [])

  console.log(formData)
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    })
  }
  console.log(userId)
  console.log("폰넘버", formData.phoneNumber)
  console.log("formData.address", formData.address)
  console.log("detailAddress", formData.detailAddress)
  console.log("postCode", formData.postCode)

  console.log("formData", formData)
  const handleEdit = async () => {
    try {
      await client.put(`/Member/detail?id=${userId}`, {
        phoneNumber: formData.phoneNumber,
        address: formData.address,
        detailAddress: formData.detailAddress,
        postCode: formData.postCode,
      })
      alert("개인정보가 수정되었습니다.")
      window.location.reload()
    } catch (error) {
      console.error("Error during update: ", error)
      alert("에러가 발생했습니다. 다시 시도해 주세요.")
    }
  }

  const handleSave = () => {
    handleEdit()
    setEditMode(false)
  }

  const handleCancel = () => {
    setFormData({ ...user })
    setEditMode(false)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const handleAddressComplete = (data) => {
    let fullAddress = data.address
    let extraAddress = ""

    if (data.addressType === "R") {
      if (data.bname !== "") extraAddress += data.bname
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName
      }
      fullAddress += extraAddress !== "" ? ` (${extraAddress})` : ""
    }

    setFormData({
      ...formData,
      address: fullAddress,
      postCode: data.zonecode, // Corrected to 'zonecode'
    })

    handleClose()
  }

  return (
    <Container>
      <Title>회원정보 수정</Title>
      {editMode ? (
        <EditContainer>
          <Section>
            <Row>
              <Col md={4}>
                <Label>이름:</Label>
              </Col>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </Section>

          <Section>
            <Row>
              <Col md={4}>
                <Label>휴대폰 번호:</Label>
              </Col>
              <Col md={8}>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  value={
                    formData.phoneNumber === "null" ? "" : formData.phoneNumber
                  }
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </Section>

          <Section>
            <Row>
              <Col md={4}>
                <div style={{ display: "flex" }}>
                  <Label>주소:</Label>
                  <EditButton
                    variant="secondary"
                    onClick={handleShow}
                    style={{ display: "flex", marginLeft: "auto" }}
                  >
                    배송지 관리
                  </EditButton>
                </div>
              </Col>
              <Col md={8}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label
                    style={{
                      marginRight: "10px",
                      width: "80px",
                      textAlign: "right",
                    }}
                  >
                    우편번호:
                  </Label>
                  <Input
                    value={
                      formData.postCode === "null" ? "" : formData.postCode
                    }
                    onClick={handleShow}
                    style={{ marginBottom: "10px", width: "100px" }}
                    readOnly
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label
                    style={{
                      marginRight: "10px",
                      width: "80px",
                      textAlign: "right",
                    }}
                  >
                    주소:
                  </Label>
                  <Input
                    name="address"
                    value={formData.address === "null" ? "" : formData.address}
                    onClick={handleShow}
                    style={{ marginBottom: "10px", flex: 1 }}
                    readOnly
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Label
                    style={{
                      marginRight: "10px",
                      width: "80px",
                      textAlign: "right",
                    }}
                  >
                    상세주소:
                  </Label>
                  <Input
                    name="detailAddress"
                    value={
                      formData.detailAddress === "null"
                        ? ""
                        : formData.detailAddress
                    }
                    style={{ marginBottom: "10px", flex: 1 }}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
          </Section>

          <div style={{ marginTop: "20px" }}>
            <SaveButton variant="primary" onClick={() => handleSave()}>
              저장
            </SaveButton>
            <CancelButton variant="secondary" onClick={handleCancel}>
              취소
            </CancelButton>
          </div>
        </EditContainer>
      ) : (
        <EditContainer>
          <Section>
            <Row>
              <Col md={4}>
                <Label>이름:</Label>
              </Col>
              <Col md={8}>
                <Value>{user.name}</Value>
              </Col>
            </Row>
          </Section>

          <Section>
            <Row>
              <Col md={4}>
                <Label>휴대폰 번호:</Label>
              </Col>
              <Col md={8}>
                <Value>
                  {user.phoneNumber === "null"
                    ? "휴대폰 번호를 입력해 주세요"
                    : user.phoneNumber}
                </Value>
              </Col>
            </Row>
          </Section>

          <Section>
            <Row>
              <Col md={4}>
                <Label>주소:</Label>
              </Col>
              <Col md={8}>
                <Value>
                  배송지는{" "}
                  <span style={{ color: "blue", fontWeight: "700" }}>
                    [배송지 관리]
                  </span>{" "}
                  탭에서 추가,수정할 수 있습니다.
                </Value>
              </Col>
            </Row>
          </Section>

          <Button
            variant="primary"
            onClick={() => setEditMode(true)}
            style={{ marginTop: "10px", marginBottom: "10px" }}
          >
            수정
          </Button>
        </EditContainer>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ padding: "8px 16px" }}>
          <Modal.Title>
            <div
              style={{
                fontSize: "20px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                height: "100%",
                margin: 0,
              }}
            >
              배송지 설정
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "500px", padding: "0px" }}>
          <DaumPostcode
            onComplete={handleAddressComplete}
            style={{ height: "500px" }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default UserInfo
