import axios from "axios"

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(";").shift()
}
// axios 인스턴스 생성
export const client = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
  headers: {
    "ngrok-skip-browser-warning": true,
  },
})

// // 요청 인터셉터 추가
// client.interceptors.request.use(
//   function (config) {
//     // `HttpOnly` 쿠키가 설정되어 있으므로 Authorization 헤더 설정은 필요하지 않습니다.
//     return config;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// fetchData 함수 수정
const fetchData = async (url, method = "GET") => {
  try {
    const response = await client({ url, method })
    console.log(response.data)
    return response.data
  } catch (error) {
    console.log("Network Error")
    console.error(error)
    throw error
  }
}

export default fetchData
