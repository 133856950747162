import React, { useEffect, useRef, useState } from "react"
import { Table } from "react-bootstrap"
import { useRecoilValue } from "recoil"
import {
  category,
  productByDate,
  searchByName,
  searchCategoryData,
} from "../../../App"
import styled from "styled-components"
import { deleteObject, getStorage, ref } from "firebase/storage"
import SearchItem from "./SearchItem"
import ModalComponent from "../../Common/ModalComponent"
import PaginationComponent from "../../Common/PaginationComponent"
import { client } from "../../API/api"
import EditItem from "./AddItem/EditItem/EditItem"
import { ShowItemData } from "./ShowItemData"

const CustomHead = styled.th`
  vertical-align: middle;
`

const CustomTd = styled.td`
  text-align: center;
  vertical-align: middle;
`
//상품 관리와 그룹관리 용
const ShowItem = ({
  mode,
  groupedProducts,
  setNewGroupItems,
  newGroupItems,
}) => {
  const [page, setPage] = useState(0)
  const [categoryName, setCategoryName] = useState("전체")
  const [categoryId, setCategoryId] = useState("")

  const [search, setSearch] = useState("")
  const [selectItem, setSelectedItem] = useState([])
  const [isDelShow, setIsDelShow] = useState(false)
  const [isEditShow, SetIsEditShow] = useState(false)
  const firstData = useRecoilValue(productByDate({ page, size: 10 })) //전체
  const categoryNames = useRecoilValue(category)
  const searchCategory = useRecoilValue(
    searchCategoryData({ name: categoryId, page, size: 10 }) //  카테고리로 찾기
  )
  const searchName = useRecoilValue(
    searchByName({ name: search, page, size: 10 }) //이름으로 찾기
  )
  const [data, setData] = useState(firstData)

  const isFirstRender = useRef(true) // 첫 렌더링 여부를 추적하는 useRef
  console.log("data", data)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false // 첫 렌더링 후 false로 설정
      return
    }
    if (search) {
      setData(searchName)
    } else if (categoryName === "전체") {
      setData(firstData)
    } else {
      setData(searchCategory)
    }
  }, [search, categoryName, page, firstData, searchCategory, searchName])

  // 카테고리 변경 시 검색어 초기화
  useEffect(() => {
    setSearch("")
    setPage(0)
  }, [categoryName])

  const handleDelClose = () => setIsDelShow(false)
  const handleShow = (item) => {
    setSelectedItem(item)
    setIsDelShow(true)
  }
  const handleEditClose = () => SetIsEditShow(false)
  const handleEditShow = (item) => {
    setSelectedItem(item)
    SetIsEditShow(true)
  }

  const findCategoryName = (categoryId) => {
    const category = categoryNames.find(
      (category) => category.id === categoryId
    )
    return category ? category.categoryName : "Category not found"
  }

  const extractPath = (url) => {
    const regex = /\/o\/(.*?)\?/
    const match = url.match(regex)
    if (match && match[1]) {
      return decodeURIComponent(match[1])
    }
    return null
  }

  const imgClick = async (id, mode) => {
    if (mode === "thumbnail") {
      const features = "width=600,height=400,scrollbars=yes"
      window.open(id, "_blank", features)
    } else {
      try {
        const features = "width=600,height=800,scrollbars=yes"
        // 이미지 URL 리스트 가져오기
        const response = await client.get(
          `/Product/findDetailUrlByProductId?productId=${id}`
        )
        const imageObjects = response.data // [{ id: '...', url: '...' }, { id: '...', url: '...' }]

        // 새 HTML 문서 생성
        const newWindow = window.open("", "_blank", features)

        // HTML 문서에 이미지들을 배치
        newWindow.document.open()
        newWindow.document.write(`
        <html>
          <head>
            <title>Detail Images</title>
            <style>
              body {
                margin: 0;
                padding: 0;
                font-family: Arial, sans-serif;
              }
              img {
                display: block;
                margin: 10px auto;
                max-width: 100%;
              }
              .container {
                text-align: center;
              }
            </style>
          </head>
          <body>
            <div class="container">
              ${imageObjects.map((image) => `<img src="${image.url}" alt="Detail Image"/>`).join("")}
            </div>
          </body>
        </html>
      `)
        newWindow.document.close()
      } catch (error) {
        console.error("Error fetching or displaying images:", error)
      }
    }
  }

  const handleDelete = async () => {
    try {
      const storage = getStorage()
      const photoPath = extractPath(selectItem.photoUrl)
      const imageRef = ref(storage, photoPath)
      await deleteObject(imageRef)
    } catch (error) {
      if (error.code === "storage/invalid-root-operation") {
      } else {
        console.error("아이템 삭제 중 오류 발생:", error)
        console.error(error.code)
        alert("아이템 삭제 중 오류가 발생했습니다. 다시 시도해 주세요.")
      }
    }
    try {
      await client.delete(`/Product/deleteProduct?id=${selectItem.id}`)
      window.location.reload()
      alert("아이템이 성공적으로 삭제되었습니다.")
    } catch (error) {
      console.error(`아이템 제거 실패  ${error}`)
    }
  }

  const handleEdit = (id) => {
    setSelectedItem(id)
    SetIsEditShow(true)
  }

  return (
    <div>
      <SearchItem
        categoryName={categoryName}
        setCategoryName={setCategoryName}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
        page={page}
        setPage={setPage}
        setData={setData}
        search={search}
        setSearch={setSearch}
      />
      <Table striped bordered hover size="sm" responsive="sm">
        <thead>
          <tr style={{ height: "50px", textAlign: "center" }}>
            {mode && <CustomHead>선택</CustomHead>}
            <CustomHead>카테고리</CustomHead>
            <CustomHead>상품명</CustomHead>
            <CustomHead>사진</CustomHead>
            <CustomHead>상세 사진</CustomHead>
            <CustomHead>가격</CustomHead>
            {!mode && (
              <>
                <CustomHead>문의 수</CustomHead>
                <CustomHead>평점</CustomHead>
                <CustomHead>리뷰 수</CustomHead>
                <CustomHead>판매 수</CustomHead>
                <CustomHead>판매 상태</CustomHead>
                <CustomHead>수정/삭제</CustomHead>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {/* 데이터*/}
          {data && data.list.length > 0 ? (
            data.list.map((item, index) => (
              <ShowItemData
                key={item.id}
                groupedProducts={groupedProducts}
                item={item}
                mode={mode}
                findCategoryName={findCategoryName}
                handleShow={handleShow}
                handleEdit={handleEdit}
                newGroupItems={newGroupItems}
                setNewGroupItems={setNewGroupItems}
              />
            ))
          ) : (
            <tr>
              <CustomTd colSpan={12}>No data available</CustomTd>
            </tr>
          )}
        </tbody>
      </Table>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <PaginationComponent
          currentPage={page}
          totalPages={data.totalPages}
          onPageChange={setPage}
        />
      </div>
      <ModalComponent
        title={`${selectItem.name}  삭제`}
        body="해당 제품을 삭제 하시겠습니까?"
        show={isDelShow}
        handleClose={handleDelClose}
        onConfirm={handleDelete}
        cancelText={"취소"}
        confirmText="삭제"
        confirmVariant={"danger"}
      />
      <ModalComponent
        title={`상품 수정`}
        show={isEditShow}
        body={
          <EditItem
            productId={selectItem.id}
            handleEditClose={handleEditClose}
          />
        }
        backdrop="static"
      />
    </div>
  )
}
export default ShowItem
