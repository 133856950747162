import React, { useEffect, useState } from "react"
import "bootstrap/dist/css/bootstrap.min.css"
import TopNav from "./TopNav"
import logo from "./Img/원정푸드.jpg"
import CategoryList from "./CategoryList"
import { useLocation, useNavigate } from "react-router-dom"

import * as S from "./Header.style"
import { useRecoilState, useRecoilValue } from "recoil"
import { adminPage, category, mobile, myPage, selectCategory } from "../../App"
import AdminSideMenu from "./AdminSideMenu"
import HeaderSideMenu from "./HeaderSideMenu"
import SearchMenu from "./Menu/SearchMenu"

const Header = () => {
  const naviLinks = [
    {
      label: "Company",
      link: "/Company/Intro",
      fontColor: "#498DDD",
      text: "회사 소개",
    },
    // {
    //   label: "Notice",
    //   link: "/CallCenter",
    //   fontColor: "red",
    //   text: "공지 사항",
    // },
  ]
  const navi = useNavigate()
  const location = useLocation()
  const [selectMenu, setSelectMenu] = useState("")
  const categoryMenu = useRecoilValue(category)
  const [showOffcanvas, setShowOffcanvas] = useState(false)
  const [selectCate, setSelectCate] = useRecoilState(selectCategory)
  const [showSideBtn, setShowSideBtn] = useState(false)
  const isMobile = useRecoilValue(mobile)
  const [isMyPage, setIsMyPage] = useRecoilState(myPage)
  const [isAdminPage, setIsAdminPage] = useRecoilState(adminPage)
  // SearchMenu
  const [input, setInput] = useState("")
  const [showSearchModal, setShowSearchModal] = useState(false)
  const activeEnter = (e) => {
    if (e.key === "Enter" && input.length !== 0) {
      navi(`/search/${input}`)
      setShowSearchModal(false)
    }
  }

  useEffect(() => {}, [location.pathname])
  useEffect(() => {
    if (isMobile) {
      setShowSideBtn(true)
    } else {
      setShowSideBtn(false)
    }
  }, [])

  const handleNavClick = (link, categoryName) => {
    navi(link)
    setSelectCate(categoryName)
    setShowOffcanvas(false) // Close the Offcanvas menu
  }
  const handleSearchClick = () => {
    if (isMobile) {
      setShowSearchModal(true)
    } else {
      navi(`/Search/${input}`)
    }
  }

  console.log("isMyPage: ", isMyPage, " isAdminPage", isAdminPage)
  return (
    <div id={"top-section"}>
      <S.HeaderContainer>
        <TopNav />
        <S.ResponseContainer>
          {/*모바일 사이드버튼 시작 */}
          {showSideBtn && (
            <HeaderSideMenu
              selectCate={selectCate}
              setSelectCate={setSelectCate}
              categoryMenu={categoryMenu}
              naviLinks={naviLinks}
              showOffcanvas={showOffcanvas}
              setShowOffcanvas={setShowOffcanvas}
              handleNavClick={handleNavClick}
            />
          )}
          <S.ImageContainer>
            <S.LogoImage
              src={logo}
              alt="원정푸드"
              onClick={() => {
                navi("/")
                setSelectCate("")
              }}
              style={{ cursor: "pointer" }}
            />
          </S.ImageContainer>
          {/* 검색 버튼 */}
          {showSideBtn && (
            <SearchMenu
              input={input}
              setInput={setInput}
              activeEnter={activeEnter}
              handleSearchClick={handleSearchClick}
              setShowSearchModal={setShowSearchModal}
              showSearchModal={showSearchModal}
            />
          )}
        </S.ResponseContainer>
        {isMobile ? (
          <>
            {/* 마이페이지용 */}
            {isMyPage && <AdminSideMenu isMyPage={isMyPage} isAdmin={false} />}
            {/* 관리자페이지용 */}
            {isAdminPage && (
              <AdminSideMenu isMyPage={false} isAdmin={isAdminPage} />
            )}
            {/* 모바일용 */}
            {!isMyPage && !isAdminPage && (
              <CategoryList
                selectCate={selectCate}
                setSelectCate={setSelectCate}
                categoryMenu={categoryMenu}
                naviLinks={naviLinks}
                showOffcanvas={showOffcanvas}
                setShowOffcanvas={setShowOffcanvas}
                showSideBtn={showSideBtn}
                input={input}
                setInput={setInput}
                activeEnter={activeEnter}
                handleSearchClick={handleSearchClick}
                setShowSearchModal={setShowSearchModal}
                showSearchModal={showSearchModal}
                handleNavClick={handleNavClick}
              />
            )}
          </>
        ) : (
          <CategoryList
            selectCate={selectCate}
            setSelectCate={setSelectCate}
            categoryMenu={categoryMenu}
            naviLinks={naviLinks}
            showOffcanvas={showOffcanvas}
            setShowOffcanvas={setShowOffcanvas}
            showSideBtn={showSideBtn}
            input={input}
            setInput={setInput}
            activeEnter={activeEnter}
            handleSearchClick={handleSearchClick}
            setShowSearchModal={setShowSearchModal}
            showSearchModal={showSearchModal}
            handleNavClick={handleNavClick}
          />
        )}
      </S.HeaderContainer>
    </div>
  )
}

export default Header
