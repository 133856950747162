import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import CompanyCertQualityManage from "./CompanyCertQualityManage"
import CompanyIntroManage from "./CompanyIntroManage"

const TabContainer = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
`

const TabButton = styled.button`
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 16px;
  color: ${({ active }) => (active ? "#fff" : "#555")};
  background-color: ${({ active }) => (active ? "#007bff" : "none")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};

  &:hover {
    color: ${({ active }) => (active ? "#fff" : "#007bff")};
  }
`

const ContentContainer = styled.div`
  padding: 20px;
`
export default function CompanyManage() {
  const location = useLocation()
  const navigate = useNavigate()

  const activeTab = location.pathname.split("/").pop() || "CompanyIntro"

  const handleTabChange = (tab) => navigate(`/Admin/CompanyManage/${tab}`)

  const renderContent = () => {
    if (activeTab === "CompanyIntro") {
      return <CompanyIntroManage />
    } else if (activeTab === "CertManage") {
      return <CompanyCertQualityManage mode="cert" />
    } else if (activeTab === "QualityManage") {
      return <CompanyCertQualityManage mode="quan" />
    }
  }

  return (
    <div className="container" style={{ minHeight: "100vh" }}>
      <TabContainer>
        <TabButton
          active={activeTab === "CompanyIntro"}
          onClick={() => handleTabChange("CompanyIntro")}
        >
          회사 소개 관리
        </TabButton>
        <TabButton
          active={activeTab === "CertManage"}
          onClick={() => handleTabChange("CertManage")}
        >
          인증 목록 관리
        </TabButton>
        <TabButton
          active={activeTab === "QualityManage"}
          onClick={() => handleTabChange("QualityManage")}
        >
          품질 관리
        </TabButton>
      </TabContainer>
      <ContentContainer>{renderContent()}</ContentContainer>
    </div>
  )
}
