import { Button } from "react-bootstrap"
import styled from "styled-components"

export const RemoteCartContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 1150px) {
    flex-direction: column;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }
`

export const Group1 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 770px;
  margin: 5px 0;

  @media (max-width: 1150px) {
    flex-direction: row;
    margin-top: 20px;
  }

  @media (max-width: 760px) {
    width: 400px;
  }

  @media (max-width: 375px) {
    width: 340px;
  }
`

export const Group2 = styled.div`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  @media (max-width: 1150px) {
    flex-direction: row;
  }

  @media (max-width: 480px) {
    flex-direction: column;
  }

  @media (max-width: 760px) {
    width: 770px;
  }
`

export const RemoteCartButton = styled.img`
  width: 7%;
  cursor: pointer;

  @media (max-width: 1150px) {
    width: 8%;
  }

  @media (max-width: 480px) {
    width: 12%;
  }
`

export const RemoteCartItems = styled.div`
  border-right: 1px solid #498ddd;
  border-left: 1px solid #498ddd;
  padding: 3px;
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
`

export const RemoteCartItem = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`
export const RemoteCartItemDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const RemoteCartSummary = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  width: 100%;
  max-width: 300px;

  .remote-cart-total {
    font-size: 18px;
    font-weight: bold;
    color: #004393;
  }

  .remote-cart-subtotal {
    font-size: 15px;
    color: #666;
  }

  @media (max-width: 1150px) {
    font-size: 14px;
    width: 35%;
    max-width: none;
    margin-bottom: 10px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    width: 35%;
    max-width: none;
    margin-bottom: 0px;
  }
`

export const RemoteCartGrandTotal = styled.p`
  font-size: 20px;
  color: #004393;
  font-weight: bold;
  margin: 10px;

  @media (max-width: 480px) {
    margin: 3px;
  }
`

export const RemoteCartClear = styled(Button)`
  margin-left: 10px;

  @media (max-width: 1150px) {
    margin-top: 10px;
    width: 20%;
  }

  @media (max-width: 480px) {
    margin-top: 10px;
    width: 20%;
  }
`

export const PaginationText = styled.div`
  /* margin-top: 10px; */
  font-size: 16px;
  color: #004393;
  font-weight: bold;
`
export const EmptyCartMessage = styled.div`
  display: flex;
  justify-content: center; /* 수평 중앙 정렬 */
  align-items: center; /* 수직 중앙 정렬 */
  height: 200px;
  text-align: center;
  font-size: 18px;
  color: #555;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`
