import React, { memo, useCallback, useEffect, useState } from "react"
import { useRecoilValue, useRecoilValueLoadable } from "recoil"
import { categoryDataState, itemData } from "../../../App"
import Node from "./ItemNode"
import "../../../CSS/ConBox/Item/ItemList.css"
import { Button } from "react-bootstrap"
import { useParams } from "react-router-dom"
import OrderDropdown from "./OrderDropdown"
import styled from "styled-components"

export const Box = styled.div`
  padding-top: 2%;
  padding-bottom: 3%;
  margin-bottom: 5%;
`
const MemoizedNode = memo(({ item }) => <Node item={item} />)

const ItemList = (props) => {
  const { id: categoryId } = useParams() // useParams에서 id 가져오기
  const categoryDataLoadable = useRecoilValueLoadable(
    categoryDataState(categoryId)
  ) // categoryDataState 사용
  const firstData = useRecoilValue(itemData({ page: 0, size: 20 })) // itemData Recoil 상태 사용
  const [data, setData] = useState([])
  const [order, setOrder] = useState("이름순")
  const [sortedData, setSortedData] = useState([])
  useEffect(() => {
    if (props.first) {
      setData(firstData.list || []) // Fallback to empty array
    } else {
      const fetchDataAndUpdateState = async () => {
        try {
          if (categoryId && categoryDataLoadable.state === "hasValue") {
            setData(categoryDataLoadable.contents.list || []) // Fallback to empty array
          }
        } catch (error) {
          console.error("Error fetching data:", error)
          setData([]) // Fallback to empty array
        }
      }

      fetchDataAndUpdateState()
    }
  }, [categoryDataLoadable, props.first, firstData, categoryId])

  useEffect(() => {
    const sortData = () => {
      if (Array.isArray(data)) {
        // Ensure `data` is an array
        let sorted = [...data]
        if (order === "이름순") {
          sorted.sort((a, b) => a.name.localeCompare(b.name))
        } else if (order === "평점순") {
          sorted.sort((a, b) => b.rating - a.rating)
        } else if (order === "리뷰순") {
          sorted.sort((a, b) => b.reviewCount - a.reviewCount)
        } else if (order === "판매순") {
          sorted.sort((a, b) => b.saleCount - a.saleCount)
        }
        setSortedData(sorted)
      } else {
        console.error("Data is not an array:", data) // Log error for debugging
      }
    }

    sortData()
  }, [data, order])

  const [visibleCount, setVisibleCount] = useState(0)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  const updateVisibleCount = useCallback(() => {
    setWindowWidth(window.innerWidth)
    setVisibleCount(window.innerWidth > 1000 ? 20 : 5)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", updateVisibleCount)
    updateVisibleCount()
    return () => window.removeEventListener("resize", updateVisibleCount)
  }, [updateVisibleCount])

  const loadMore = useCallback(
    () =>
      setVisibleCount((prevCount) => prevCount + (windowWidth > 1000 ? 20 : 5)),
    [windowWidth]
  )

  return (
    <div className="container">
      <div className="item-list-container">
        {!props.first && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ alignContent: "center" }}>
              총 {data.length || 0}개
            </span>{" "}
            {/* Fallback to 0 */}
            <OrderDropdown order={order} setOrder={setOrder} />
          </div>
        )}
        <hr />
        <div className="item-grid">
          {sortedData.length > 0 &&
            sortedData
              .slice(0, visibleCount)
              .map((i, idx) => (
                <MemoizedNode key={idx} item={i} cmd={props.cmd} />
              ))}
        </div>
        {visibleCount < (data.length || 0) && ( // Fallback to 0
          <div className="load-more-button-container">
            <Button
              className="load-more-button"
              variant="primary"
              onClick={loadMore}
            >
              상품 더보기
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ItemList
