import { Button, Card, Dropdown, Form } from "react-bootstrap"
import React, { useEffect, useState } from "react"
import { client } from "../API/api"
import styled from "styled-components"
import ModalComponent from "../Common/ModalComponent"
import { ShippingAddress } from "../ConBox/MyPage/MyOrder/ShippiongAdress/ShippingAddress"

const CustomDropdownItem = styled(Dropdown.Item)`
  background-color: ${(props) =>
    props.isHovered ? "#1d50ff" : props.isSelected ? "#1d50ff" : "transparent"};
  color: ${(props) =>
    props.isHovered || props.isSelected ? "white" : "black"};

  &:hover {
    background-color: #1d50ff;
    color: white;
  }
`

export const DeliveryRegionCard = ({
  region,
  setRegion,
  isDefaultDeliverChecked,
  setIsDefaultDeliverChecked,
  handleCheckboxChange,
}) => {
  const [selectedOption, setSelectedOption] =
    useState("배송시 요청사항을 입력해주세요.")
  const [hoveredItem, setHoveredItem] = useState(null) // 현재 호버 중인 항목
  const [deliveryRequest, setDeliveryRequest] = useState("")
  const [show, setShow] = useState(false)

  // 배송지 선택에서 payMode를 true로 myPage와 구별
  const isPayMode = true

  const handleShowModal = () => {
    setShow(true)
  }
  const handleCloseModal = () => {
    setShow(false)
  }
  console.log("regint", region.address)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await client.get(
          `/Address/findAddressDefault?memberId=${localStorage.getItem("userId")}`
        )
        setRegion(response.data)
        setIsDefaultDeliverChecked(response.data)
      } catch (error) {
        alert(`${error.response.data.message}배송지를 설정해주세요`)
      }
    }
    fetchData()
  }, [])
  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey) // 선택한 항목 업데이트
  }

  // 번호 포맷 함수
  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) {
      return "" // phoneNumber가 없을 경우 빈 문자열 반환
    }
    return phoneNumber
      .replace(/[^0-9]/g, "")
      .replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3")
  }

  return (
    <>
      <div style={{ display: "flex" }}>
        <h5 className="mb-3" style={{ fontWeight: 700 }}>
          배송지
        </h5>
        <div style={{ marginLeft: "auto" }}>
          <Button size="sm" onClick={handleShowModal}>
            변경
          </Button>
        </div>
      </div>
      <Card className="mb-3" style={{ padding: "10px", display: "flex" }}>
        <div style={{ marginRight: "10px" }}>
          <div style={{ fontSize: "16px", fontWeight: 700 }}>
            {region.alias}
          </div>
          <div style={{ fontSize: "16px", fontWeight: 700, marginTop: "5px" }}>
            {region.customerName} {formatPhoneNumber(region.phoneNumber)}
          </div>
          <div style={{ marginBottom: "10px", marginTop: "5px" }}>
            {region.address === undefined ? (
              "배송지를 설정해주세요"
            ) : (
              <>
                {region.address}, {region.detailAddress}
              </>
            )}
          </div>
        </div>
        <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle
            variant="outline"
            id="dropdown-basic"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              border: "1px solid #d2d2d2",
            }}
          >
            <span>{selectedOption}</span>
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ width: "100%" }}>
            {[
              "배송시 요청사항을 입력해주세요.",
              "직접 수령하겠습니다.",
              "배송 전 연락바랍니다.",
              "부재 시 경비실에 맡겨주세요.",
              "부재 시 문 앞에 놓아주세요.",
              "부재 시 택배함에 넣어주세요.",
              "직접 입력",
            ].map((option) => (
              <CustomDropdownItem
                key={option}
                eventKey={option}
                isSelected={selectedOption === option && hoveredItem === null} // 선택된 항목 기본 파란색
                isHovered={hoveredItem === option} // 호버된 항목에만 파란색 배경
                onMouseEnter={() => setHoveredItem(option)} // 마우스 호버 시작
                onMouseLeave={() => setHoveredItem(null)} // 마우스 호버 종료
              >
                {option}
              </CustomDropdownItem>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {selectedOption === "직접 입력" && (
          <Form style={{ marginTop: "5px" }}>
            <Form.Control
              type="text"
              aria-describedby="passwordHelpBlock"
              placeholder={"최대 50자 선택가능합니다."}
              value={deliveryRequest}
              onChange={(e) => setDeliveryRequest(e.target.value)}
            />
          </Form>
        )}
        <div
          style={{
            marginTop: "10px",
            alignSelf: "flex-start",
            fontSize: "14px",
            color: "#888",
            display: "flex",
            alignContent: "center",
          }}
        >
          <input
            type="checkbox"
            id="useAgain"
            style={{ marginRight: "5px" }}
            checked={isDefaultDeliverChecked}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="useAgain">기본배송지로 사용할게요</label>
        </div>
      </Card>
      <ModalComponent
        body={
          <ShippingAddress
            isPayMode={isPayMode}
            handleCloseModal={handleCloseModal}
            region={region}
            setRegion={setRegion}
            setIsDefaultDeliverChecked={setIsDefaultDeliverChecked}
          />
        }
        show={show}
        centered={true}
      />
    </>
  )
}
