import React, { useState } from "react"
import { Badge, Button, Pagination, Table } from "react-bootstrap"
import styled from "styled-components"
import { client } from "../../../API/api"
import { useNavigate, useParams } from "react-router-dom"
import PaginationComponent from "../../../Common/PaginationComponent"
import { PersonFillLock } from "react-bootstrap-icons"

// 비밀글 표시 컴포넌트
const SecretText = () => {
  return (
    <div>
      <PersonFillLock />
      비밀글입니다.
    </div>
  )
}

export default function QnaInfoComponent({
  qna,
  maskName,
  formatDate,
  qnaPage,
  setQnaPage,
}) {
  const navi = useNavigate()
  const { id } = useParams()
  const [expandedRow, setExpandedRow] = useState(null)
  const [comment, setComment] = useState([])
  const [qnaId, setQnaId] = useState(null)

  const handleRowClick = async (index, item) => {
    if (expandedRow === index) {
      setExpandedRow(null)
      setComment([]) // Clear comments when collapsing
      return
    }

    setExpandedRow(index)
    setQnaId(item.id)

    try {
      const response = await client.get(
        `/Comment/findCommentsByQnaId?qnaId=${item.id}`
      )
      setComment(response.data)
    } catch (error) {
      console.error("Error fetching comments:", error)
      setComment([]) // Handle error by setting comment to empty array
    }
  }

  const truncateText = (text, maxLength) =>
    text.length <= maxLength ? text : text.slice(0, maxLength) + "..."

  const renderQnaPaginationItems = () => {
    const items = []
    for (let i = 0; i < (qna ? qna.totalPages : 1); i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === qnaPage}
          onClick={() => setQnaPage(i)}
        >
          {i + 1}
        </Pagination.Item>
      )
    }
    return items
  }

  return (
    <>
      {qna.size === 0 ? (
        <>
          <div>아직 QNA가 없습니다.</div>
          <Button onClick={() => navi(`/QnaForm/${id}`)}>상품 문의하기</Button>
        </>
      ) : (
        <>
          <Table responsive>
            <thead>
              <tr>
                <th style={{ textAlign: "center", width: "10%" }}>상태</th>
                <th style={{ textAlign: "center", width: "70%" }}>문의/답변</th>
                <th style={{ textAlign: "center", width: "10%" }}>작성자</th>
                <th style={{ textAlign: "center", width: "10%" }}>작성일자</th>
              </tr>
            </thead>
            <tbody>
              {qna.list.map((item, index) => (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => handleRowClick(index, item)}
                    style={{ cursor: "pointer" }}
                  >
                    <td style={{ textAlign: "left", width: "10%" }}>
                      {item.solvedFlag ? (
                        <Badge style={{ fontSize: "14px" }}>답변 완료</Badge>
                      ) : (
                        <Badge
                          bg="warning"
                          text="dark"
                          style={{ fontSize: "14px" }}
                        >
                          답변 대기
                        </Badge>
                      )}
                    </td>
                    <td style={{ textAlign: "center", width: "70%" }}>
                      {item.privateFlag ? (
                        <SecretText /> // 컴포넌트로 변경하여 호출
                      ) : (
                        truncateText(item.title, 35)
                      )}
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      {item.privateFlag ? (
                        <SecretText /> // 컴포넌트로 변경하여 호출
                      ) : (
                        maskName(item.nickname)
                      )}
                    </td>
                    <td style={{ textAlign: "center", width: "10%" }}>
                      {formatDate(item.date)}
                    </td>
                  </tr>
                  {expandedRow === index && (
                    <tr>
                      <td colSpan="4">
                        <ExpandedContent>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              marginBottom: "10px",
                            }}
                          >
                            <span
                              style={{
                                color: "#0b83e6",
                                fontSize: "38px",
                                fontWeight: "bold",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                marginRight: "20px",
                                lineHeight: "1",
                              }}
                            >
                              Q
                            </span>

                            <div
                              style={{
                                whiteSpace: "pre-line",
                                overflowWrap: "break-word",
                                wordBreak: "break-word",
                                padding: "10px",
                                backgroundColor: "#f9f9f9",
                                borderRadius: "5px",
                              }}
                            >
                              {item.privateFlag ? "비밀글입니다." : item.title}
                            </div>
                          </div>

                          <hr style={{ margin: "10px 0" }} />

                          {comment.length > 0 && (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  marginBottom: "10px",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#f44453",
                                    fontSize: "38px",
                                    fontWeight: "bold",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    marginRight: "20px",
                                    lineHeight: "1",
                                  }}
                                >
                                  A
                                </span>

                                <div
                                  style={{
                                    whiteSpace: "pre-line",
                                    overflowWrap: "break-word",
                                    wordBreak: "break-word",
                                    padding: "10px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "5px",
                                  }}
                                >
                                  {item.privateFlag ? (
                                    <SecretText /> // 컴포넌트로 변경하여 호출
                                  ) : (
                                    comment[0].content
                                  )}
                                </div>
                              </div>
                              <div style={{ color: "gray", marginTop: "10px" }}>
                                관리자 {formatDate(comment[0].date)}
                              </div>
                            </>
                          )}
                          {comment.length === 0 && <div>답변이 없습니다.</div>}
                        </ExpandedContent>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
          <div>
            <Button
              style={{ display: "flex", float: "right" }}
              onClick={() => navi(`/QnaForm/${id}`)}
            >
              상품 문의하기
            </Button>
            <div style={{ clear: "both" }}></div>{" "}
            {/* This ensures the float is cleared */}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PaginationComponent
                currentPage={qnaPage}
                totalPages={qna.totalPages}
                onPageChange={setQnaPage}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

// QnA 스타일
const ExpandedContent = styled.div`
  text-align: left;
  padding: 10px;
  background-color: #f9f9f9; /* Light background for expanded content */
  border-top: 1px solid #dee2e6; /* Border to separate from the row above */
`
